import React, { useCallback, useEffect, useState, useMemo } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import TabsOrderManager from "./includes/TabsOrderManager";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { ExportXLSX } from '../../utils/ExportXLSX';
import { NavLink } from 'react-router-dom';
import style from "./OrderSummary.module.css";
import OrderHelper from '../../services/OrderService';
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import { useSelector } from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';

const OrderSummary = () => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [orderedData, setOrderedData] = useState([]);
	const [orderedDownloadData, setOrderedDownloadData] = useState([]);
	//const [days, setDays] = useState(90);
	let today = new Date();
	let todayTimestamp = new Date().setDate(today.getDate());
	let formatToday = new Date(todayTimestamp).toISOString().substring(0, 10)
	let timestamp = new Date().setDate(today.getDate() - 90);
	let past90Days = new Date(timestamp).toISOString().substring(0, 10)
	//const formatToday = today.toLocaleDateString();
	//const formattedDate = past90Days.toLocaleDateString(); // format the date as a string
	const [dateFrom, setDateFrom] = useState(past90Days);
	const [dateTo, setDateTo] = useState(formatToday);
	const [limit, setLimit] = useState(100);
	const [product, setProduct] = useState('all');
	const [activityType, setActivityType] = useState('all');
	const [reportOptionOpened, setReportOptionOpened] = useState(true);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const [errorMSG, setErrorMSG] = useState('');

	const columns = [
		{
			dataField: 'Company',
			text: '',
			filter: textFilter({
				placeholder: 'Company',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Product',
			text: '',
			classes: "",
			filter: textFilter({
				placeholder: 'Product',
				className: 'placeHolderInput'
			}),
			formatter: (cell, row) => quoteFormater(cell, row)
		},
		{
			dataField: 'Status',
			text: '',
			filter: textFilter({
				placeholder: 'Status',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'PON',
			text: '',
			filter: textFilter({
				placeholder: 'Customer PON',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'OrderName',
			text: '',
			filter: textFilter({
				placeholder: 'Order Name',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'orderType',
			text: '',
			filter: textFilter({
				placeholder: 'Order Type',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Requested',
			text: '',
			filter: textFilter({
				placeholder: 'Requested Date',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Ordered',
			text: '',
			filter: textFilter({
				placeholder: 'Ordered Date',
				className: 'placeHolderInput'
			}),
		}];

	/*
	{
		dataField: 'isTick',
		text: '', //removed Manage
		formatter: (cell, row, rowIndex) => manageFormater(cell, row, rowIndex)
	}
	*/

	function quoteFormater(props, row) {
		console.log(row);
		let URL = "";
		if (row.Status === "Saved") {
			URL = `/Order/Create/${row.RefId}`;
		} else {
			URL = `/Order/View/${row.RefId}`;
		}
		return (
			<>
				<NavLink className={style.quoteLink} to={URL} activeClassName="active">{row.Product}</NavLink>
				{/* <a href className={style.quoteLink}>{row.quoteName}</a> */}
				<div>
					{
						row.filterList.map((i, e) =>
							<span className={style.filterList} key={i}>{i}</span>
						)
					}
				</div>
			</>
		)
	}

	/* we currently don't use any of these functions
	function manageFormater(props, row, rowIndex) {
		return '';
		return (
			<>
				<button className={`add-toggle ${style.addToggleAlt} ${row.isTick ? 'on' : ''}`} id="tog1" onClick={() => { manageAction(row, rowIndex) }}>
					{
						row.isTick ?
							<svg className="ico-check"><path d="M24,1,9,17H7L0,10,2,9l6,6L22,0Z"></path></svg>
							:
							<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
					}
				</button>
			</>
		)
	}

	const manageAction = (row, rowIndex) => {
		if ((selectedQuote >= 5) && !row.isTick) {
			return
		}
		// let findOD = orderedData.map( OD =>  OD.id === row.id  ? { ...OD, isTick: !row.isTick } : OD);
		// setOrderedData(findOD);

		let copyOD = [...orderedData];
		copyOD[rowIndex].isTick = !row.isTick;
		setOrderedData(copyOD);

		let findSelectedOrderLength = orderedData.filter(OL => OL.isTick === true).length;
		setSelectedQuote(findSelectedOrderLength);
	}


	function advancedSearch() {
		setAdvancedSearchActive(!advancedSearchActive);
	}
	*/

	function reportOption() {
		setReportOptionOpened(!reportOptionOpened);
	}

	/*
	const handleDaysUpdate = async (ev) => {
		let num_days = ev.target.value;
		setDays(num_days);
	}
	*/

	const handleDateUpdate = (event) => {
		let id = event.target.id;
		let value = event.target.value;
		switch (id) {
			case "dateFrom":
				setDateFrom(value);
				break;
			case "dateTo":
				setDateTo(value);
				break;
			default:
				break;
		}
	}

	function handleActivityUpdate(event) {
		let activity_type = event.target.value;
		setActivityType(activity_type);
	}

	function handleLimitUpdate(ev) {
		let limit = ev.target.value;
		setLimit(limit);
	}

	function handleProductUpdate(ev) {
		let product = ev.target.value;
		setProduct(product);
	}

	const reloadSummary = () => {
		fetchOrderSummary(dateFrom, dateTo, limit, product, activityType);
	}


	const fetchOrderSummary = useCallback((dateFrom, dateTo, limit, product, activityType) => {
		setErrorMSG('');
		setLoader(true);
		setOrderedData([]);
		setOrderedDownloadData([]);
		OrderHelper.getOrderData(dateFrom, dateTo, 'submitted', limit, product, activityType).then((response) => {
			if (response.data.result === "SUCCESS") {
				setOrderedData(response?.data.submitted);
				setOrderedDownloadData(response?.data.download);
			} else {
				logger.error('getOrderData Error: ' + response.data.error);
				setErrorMSG(response.data.error);
			}
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error('getOrderData Error: ' + error);
			setHasError(error);
		});
	}, [logger])

	useEffect(() => {
		//Internal users need to select a mpid before fetching the order summary data
		if (isEmployee) {
			if (selectedInternalMpid) {
				fetchOrderSummary(dateFrom, dateTo, limit, product, activityType);
			}
		} else {
			fetchOrderSummary(dateFrom, dateTo, limit, product, activityType);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInternalMpid]);

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			<div className="midsection order-summary light-theme abs z1">
				{loader ? <Loader /> : null}
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Order Manager" : "Order Manager"}</h1>
						{isEmployee ? <CompanyDropdown area={"Summary"} /> : null}
						{!hasError && (<div className="section orderSummaryPage">
							<TabsOrderManager />
							<div className={`content ${style.contentOrderPage} `}>
								<div className={`sub-hdr alt1 ${style.subHdrAlter}`}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Submitted Requests</h2>
								</div>
								<p className={`text-center my-4 text-danger`}>{errorMSG}</p>
								{/* <GridFilter /> */}
								<div>
									{/* <GridFilter /> */}
									<div className={style.filterWrap}>
										<div>
											<button className={`ico-button light-button `}>
												<ExportXLSX label="Download Summary" csvData={orderedDownloadData} fileName={`Order_Summary_${Date.now()}`} />
											</button>
										</div>
										<div className="expander" collapsed-id="icon-content">
											<svg className="ico-link light-ico ico-add" onClick={reportOption}>
												<path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
											</svg>
										</div>
									</div>
								</div>

								{reportOptionOpened ?
									<div className={`row ${style.reportOptionWrap} `}>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Results</h4>
											<label className={style.labelText}>Date From</label>
											<input type='date' min="2015-01-01" className={style.dateFld} id='dateFrom' name='dateFrom' onChange={handleDateUpdate} value={dateFrom}></input>
											<label className={style.labelText}>Date To</label>
											<input type='date' min="2015-01-01" className={style.dateFld} id='dateTo' name='dateTo' onChange={handleDateUpdate} value={dateTo}></input>
										</div>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Product(s)</h4>
											<div className={style.paramsList}>
												<select className={`form-control  ${style.selectFld}`} name="product" value={product} onChange={handleProductUpdate}>
													<option value='all'>All Products</option>
													<option value='1'>Wave</option>
													<option value='10'>DIA POP</option>
													<option value='11'>DIA Prem</option>
													<option value='23'>Wholesale Broadband</option>
													<option value='6'>MPLS AGG Tail</option>
													<option value='13'>MPLS + Internet</option>
													<option value='7'>MPLS NNI</option>
													<option value='19'>E-Line</option>
													<option value='20'>E-Access</option>
													<option value='22'>E-Access NNI</option>
													<option value='15'>Colocation</option>
												</select>
											</div>
											<h4>Limit</h4>
											<div className={style.paramsList}>
												<select className={`form-control  ${style.selectFld}`} name="limit" value={limit} onChange={handleLimitUpdate}>
													<option value='100'>100 Orders</option>
													<option value='500'>500 Orders</option>
													<option value='1000'>1000 Orders</option>
													<option value='5000'>5000 Orders</option>
													<option value='all'>All Orders</option>
												</select>
											</div>
										</div>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Activity Requested(s)</h4>
											<div className={style.paramsList}>
												<select className={`form-control  ${style.selectFld}`} name="activity_type" value={activityType} onChange={handleActivityUpdate}>
													<option value='all'>All Activity Types</option>
													<option value='N'>New</option>
													<option value='A'>Add</option>
													<option value='C'>Change</option>
													<option value='D'>Disconnect</option>
													<option value='R'>Renewals</option>
												</select>
											</div>
											<div className={style.paramsList}>
												<button className={`light-button ${style.applyBtn}`} onClick={reloadSummary}>
													Apply
												</button>
											</div>
										</div>
									</div>
									: null}

								<div className="rel b mt-3">
								</div>

								<div className="quoteSummaryTableWrap">
									<div className="rel pagination-table page-list my-3">
										<BootstrapTable id="quoteSummaryTable"
											keyField='id'
											data={orderedData}
											columns={columns}
											striped
											hover
											condensed
											bordered={false}
											pagination={paginationFactory({
												sizePerPage: 10
											})}
											filter={filterFactory()}
											loading={true}
											className="mb-0"
										/>
									</div>
								</div>
							</div>
						</div>)}
					</div>
				</div>
			</div>
		</>);
}

export default OrderSummary;
