import { useEffect, useState, useMemo } from "react";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import { LineChart } from "@mui/x-charts/LineChart";
import style from "./CoherentProbes.module.css";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import XLSXExport from "../../utils/XLSXExport";
import { useSelector } from "react-redux";

const CoherentProbes = (props) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
    const [displayFormat, setDisplayFormat] = useState([{ type: '30', text: 'Display 30 Days', title: 'Past 30 Days' }, { type: '24', text: 'Display 24 HRS', title: 'Past 24 Hrs' }]);
    const [clliA, setClliA] = useState("");
    const [clliZ, setClliZ] = useState("");
    const [latestADate, setLatestADate] = useState("");
    const [latestZDate, setLatestZDate] = useState("");

    //CD Variables
    const [cdDataA24H, setCdDataA24H] = useState([]);
    const [cdDataZ24H, setCdDataZ24H] = useState([]);
    const [cdDataMaxA24H, setCdDataMaxA24H] = useState(0);
    const [cdDataMinA24H, setCdDataMinA24H] = useState(0);
    const [cdDataMaxZ24H, setCdDataMaxZ24H] = useState(0);
    const [cdDataMinZ24H, setCdDataMinZ24H] = useState(0);
    const [cdDataA30D, setCdDataA30D] = useState([]);
    const [cdDataZ30D, setCdDataZ30D] = useState([]);
    const [cdDataMaxA30D, setCdDataMaxA30D] = useState(0);
    const [cdDataMinA30D, setCdDataMinA30D] = useState(0);
    const [cdDataMaxZ30D, setCdDataMaxZ30D] = useState(0);
    const [cdDataMinZ30D, setCdDataMinZ30D] = useState(0);

    //DGD Variables
    const [dgdDataA24H, setDgdDataA24H] = useState([]);
    const [dgdDataZ24H, setDgdDataZ24H] = useState([]);
    const [dgdDataMaxA24H, setDgdDataMaxA24H] = useState(0);
    const [dgdDataMinA24H, setDgdDataMinA24H] = useState(0);
    const [dgdDataMaxZ24H, setDgdDataMaxZ24H] = useState(0);
    const [dgdDataMinZ24H, setDgdDataMinZ24H] = useState(0);
    const [dgdDataA30D, setDgdDataA30D] = useState([]);
    const [dgdDataZ30D, setDgdDataZ30D] = useState([]);
    const [dgdDataMaxA30D, setDgdDataMaxA30D] = useState(0);
    const [dgdDataMinA30D, setDgdDataMinA30D] = useState(0);
    const [dgdDataMaxZ30D, setDgdDataMaxZ30D] = useState(0);
    const [dgdDataMinZ30D, setDgdDataMinZ30D] = useState(0);

    //OSNR Variables
    const [osnrDataA24H, setOsnrDataA24H] = useState([]);
    const [osnrDataZ24H, setOsnrDataZ24H] = useState([]);
    const [osnrDataMaxA24H, setOsnrDataMaxA24H] = useState(0);
    const [osnrDataMinA24H, setOsnrDataMinA24H] = useState(0);
    const [osnrDataMaxZ24H, setOsnrDataMaxZ24H] = useState(0);
    const [osnrDataMinZ24H, setOsnrDataMinZ24H] = useState(0);
    const [osnrDataA30D, setOsnrDataA30D] = useState([]);
    const [osnrDataZ30D, setOsnrDataZ30D] = useState([]);
    const [osnrDataMaxA30D, setOsnrDataMaxA30D] = useState(0);
    const [osnrDataMinA30D, setOsnrDataMinA30D] = useState(0);
    const [osnrDataMaxZ30D, setOsnrDataMaxZ30D] = useState(0);
    const [osnrDataMinZ30D, setOsnrDataMinZ30D] = useState(0);

    const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const user = localStorage.getItem("user");

    //Use the static coherentProbeDemo.json file for the demo
    useEffect(() => {
        if(user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683){
            let cdAData24HArray = [];
            let cdZData24HArray = [];
            let cdAData30DArray = [];
            let cdZData30DArray = [];
            let dgdAData24HArray = [];
            let dgdZData24HArray = [];
            let dgdAData30DArray = [];
            let dgdZData30DArray = [];
            let osnrAData24HArray = [];
            let osnrZData24HArray = [];
            let osnrAData30DArray = [];
            let osnrZData30DArray = [];
            let data24H = props.coherentProbeData.data['24H'];
            let data30D = props.coherentProbeData.data['30D'];
            let a = 0;
            let z = 0;

            const today = new Date();

            //This subtracts days from the current time for the demo experience
            const subtractDays = (today, days) => {
                let daysToSubtract = 30;
                daysToSubtract = daysToSubtract - days;
                logger.info("subtracting " + daysToSubtract + " days from " + today);
                const twoDaysFromToday = new Date(today.getTime() - daysToSubtract * 24 * 60 * 60 * 1000);

                return twoDaysFromToday;
            }

            //This subtracts hours from the current time for the demo experience
            const subtractHours = (currentTime, hours) => {
                let hoursToSubtract = 24;
                hoursToSubtract = hoursToSubtract - hours;
                logger.info("subtracting " + hoursToSubtract + " hours from " + currentTime);
                const millisecondsToSubtract = hoursToSubtract * 3600000;
                const newTime = new Date(currentTime.getTime() - millisecondsToSubtract);
                return newTime;
            }

            data24H.forEach((obj, i) => {
                if (obj.A?.dateTime) {
                    setClliA(obj.A.aenddevice);
                    setLatestADate(subtractHours(today, a));
                    cdAData24HArray.push({ x: subtractHours(today, a), y: obj.A?.CD?.AVG_CD });
                    dgdAData24HArray.push({ x: subtractHours(today, a), y: obj.A?.DGD?.AVG_DGD });
                    osnrAData24HArray.push({ x: subtractHours(today, a), y: obj.A?.OSNR?.AVG_OSNR });
                    setCdDataMaxA24H(obj.A?.CD?.MAX_CD);
                    setCdDataMinA24H(obj.A?.CD?.MIN_CD);
                    setDgdDataMaxA24H(obj.A?.DGD?.MAX_DGD);
                    setDgdDataMinA24H(obj.A?.DGD?.MIN_DGD);
                    setOsnrDataMaxA24H(obj.A?.OSNR?.MAX_OSNR);
                    setOsnrDataMinA24H(obj.A?.OSNR?.MIN_OSNR);
                }
                a++;
                if (obj.Z?.dateTime) {
                    setClliZ(obj.Z.zenddevice);
                    setLatestZDate(subtractHours(today, z));
                    cdZData24HArray.push({ x: subtractHours(today, z), y: obj.Z?.CD?.AVG_CD });
                    dgdZData24HArray.push({ x: subtractHours(today, z), y: obj.Z?.DGD?.AVG_DGD });
                    osnrZData24HArray.push({ x: subtractHours(today, z), y: obj.Z?.OSNR?.AVG_OSNR });
                    setCdDataMaxZ24H(obj.Z?.CD?.MAX_CD);
                    setCdDataMinZ24H(obj.Z?.CD?.MIN_CD);
                    setDgdDataMaxZ24H(obj.Z?.DGD?.MAX_DGD);
                    setDgdDataMinZ24H(obj.Z?.DGD?.MIN_DGD);
                    setOsnrDataMaxZ24H(obj.Z?.OSNR?.MAX_OSNR);
                    setOsnrDataMinZ24H(obj.Z?.OSNR?.MIN_OSNR);
                    z++;
                }

            })

            a = 0;
            z = 0;
            data30D.forEach((obj, i) => {
                if (obj.A?.dateTime) {
                    cdAData30DArray.push({ x: subtractDays(today, a), y: obj.A?.CD?.AVG_CD });
                    dgdAData30DArray.push({ x: subtractDays(today, a), y: obj.A?.DGD?.AVG_DGD });
                    osnrAData30DArray.push({ x: subtractDays(today, a), y: obj.A?.OSNR?.AVG_OSNR });
                    setCdDataMaxA30D(obj.A?.CD?.MAX_CD);
                    setCdDataMinA30D(obj.A?.CD?.MIN_CD);
                    setDgdDataMaxA30D(obj.A?.DGD?.MAX_DGD);
                    setDgdDataMinA30D(obj.A?.DGD?.MIN_DGD);
                    setOsnrDataMaxA30D(obj.A?.OSNR?.MAX_OSNR);
                    setOsnrDataMinA30D(obj.A?.OSNR?.MIN_OSNR);
                    a++;
                }

                if (obj.Z?.dateTime) {
                    cdZData30DArray.push({ x: subtractDays(today, z), y: obj.Z?.CD?.AVG_CD });
                    dgdZData30DArray.push({ x: subtractDays(today, z), y: obj.Z?.DGD?.AVG_DGD });
                    osnrZData30DArray.push({ x: subtractDays(today, z), y: obj.Z?.OSNR?.AVG_OSNR });
                    setCdDataMaxZ30D(obj.Z?.CD?.MAX_CD);
                    setCdDataMinZ30D(obj.Z?.CD?.MIN_CD);
                    setDgdDataMaxZ30D(obj.Z?.DGD?.MAX_DGD);
                    setDgdDataMinZ30D(obj.Z?.DGD?.MIN_DGD);
                    setOsnrDataMaxZ30D(obj.Z?.OSNR?.MAX_OSNR);
                    setOsnrDataMinZ30D(obj.Z?.OSNR?.MIN_OSNR);
                    z++;
                }

            })

            setCdDataA24H(cdAData24HArray);
            setCdDataZ24H(cdZData24HArray);
            setCdDataA30D(cdAData30DArray);
            setCdDataZ30D(cdZData30DArray);

            setDgdDataA24H(dgdAData24HArray);
            setDgdDataZ24H(dgdZData24HArray);
            setDgdDataA30D(dgdAData30DArray);
            setDgdDataZ30D(dgdZData30DArray);

            setOsnrDataA24H(osnrAData24HArray);
            setOsnrDataZ24H(osnrZData24HArray);
            setOsnrDataA30D(osnrAData30DArray);
            setOsnrDataZ30D(osnrZData30DArray);

            //Calculate max and min values based on the averages
            setCdDataMaxA24H(Math.max(...cdAData24HArray.map(o => o.y)));
            setCdDataMinA24H(Math.min(...cdAData24HArray.map(o => o.y)));
            setCdDataMaxZ24H(Math.max(...cdZData24HArray.map(o => o.y)));
            setCdDataMinZ24H(Math.min(...cdZData24HArray.map(o => o.y)));
            setCdDataMaxA30D(Math.max(...cdAData30DArray.map(o => o.y)));
            setCdDataMinA30D(Math.min(...cdAData30DArray.map(o => o.y)));
            setCdDataMaxZ30D(Math.max(...cdZData30DArray.map(o => o.y)));
            setCdDataMinZ30D(Math.min(...cdZData30DArray.map(o => o.y)));

            setDgdDataMaxA24H(Math.max(...dgdAData24HArray.map(o => o.y)));
            setDgdDataMinA24H(Math.min(...dgdAData24HArray.map(o => o.y)));
            setDgdDataMaxZ24H(Math.max(...dgdZData24HArray.map(o => o.y)));
            setDgdDataMinZ24H(Math.min(...dgdZData24HArray.map(o => o.y)));
            setDgdDataMaxA30D(Math.max(...dgdAData30DArray.map(o => o.y)));
            setDgdDataMinA30D(Math.min(...dgdAData30DArray.map(o => o.y)));
            setDgdDataMaxZ30D(Math.max(...dgdZData30DArray.map(o => o.y)));
            setDgdDataMinZ30D(Math.min(...dgdZData30DArray.map(o => o.y)));

            setOsnrDataMaxA24H(Math.max(...osnrAData24HArray.map(o => o.y)));
            setOsnrDataMinA24H(Math.min(...osnrAData24HArray.map(o => o.y)));
            setOsnrDataMaxZ24H(Math.max(...osnrZData24HArray.map(o => o.y)));
            setOsnrDataMinZ24H(Math.min(...osnrZData24HArray.map(o => o.y)));
            setOsnrDataMaxA30D(Math.max(...osnrAData30DArray.map(o => o.y)));
            setOsnrDataMinA30D(Math.min(...osnrAData30DArray.map(o => o.y)));
            setOsnrDataMaxZ30D(Math.max(...osnrZData30DArray.map(o => o.y)));
            setOsnrDataMinZ30D(Math.min(...osnrZData30DArray.map(o => o.y)));
        }
    }, [logger, props.coherentProbeData.data, selectedInternalMpid, user])


    //Call the Coherent Probe API if the user is not the Demo Account
    useEffect(() => {
        if (user !== "Wholesale_Demo" && Number(selectedInternalMpid) !== 8683) {
            logger.info(JSON.stringify(props.coherentProbeData));
            let cdA24HArrayLength = props.coherentProbeData?.cdA24HArray?.length;
            let cdZ24HArrayLength = props.coherentProbeData?.cdZ24HArray?.length;

            setClliA(props.coherentProbeData?.clliA);
            setClliZ(props.coherentProbeData?.clliZ);

            setLatestADate(props.coherentProbeData?.cdA24HArray?.[cdA24HArrayLength-1]?.["x"]);
            setLatestZDate(props.coherentProbeData?.cdZ24HArray?.[cdZ24HArrayLength-1]?.["x"]);

            setCdDataA24H(props.coherentProbeData?.cdA24HArray);
            setCdDataZ24H(props.coherentProbeData?.cdZ24HArray);
            setCdDataA30D(props.coherentProbeData?.cdA30DArray);
            setCdDataZ30D(props.coherentProbeData?.cdZ30DArray);

            setDgdDataA24H(props.coherentProbeData?.dgdA24HArray);
            setDgdDataZ24H(props.coherentProbeData?.dgdZ24HArray);
            setDgdDataA30D(props.coherentProbeData?.dgdA30DArray);
            setDgdDataZ30D(props.coherentProbeData?.dgdZ30DArray);

            setOsnrDataA24H(props.coherentProbeData?.osnrA24HArray);
            setOsnrDataZ24H(props.coherentProbeData?.osnrZ24HArray);
            setOsnrDataA30D(props.coherentProbeData?.osnrA30DArray);
            setOsnrDataZ30D(props.coherentProbeData?.osnrZ30DArray);

            setCdDataMaxA24H(Math.max(...props.coherentProbeData?.cdA24HArray.map(o => o.y)));
            setCdDataMinA24H(Math.min(...props.coherentProbeData?.cdA24HArray.map(o => o.y)));
            setCdDataMaxZ24H(Math.max(...props.coherentProbeData?.cdZ24HArray.map(o => o.y)));
            setCdDataMinZ24H(Math.min(...props.coherentProbeData?.cdZ24HArray.map(o => o.y)));

            setCdDataMaxA30D(Math.max(...props.coherentProbeData?.cdA30DArray.map(o => o.y)));
            setCdDataMinA30D(Math.min(...props.coherentProbeData?.cdA30DArray.map(o => o.y)));
            setCdDataMaxZ30D(Math.max(...props.coherentProbeData?.cdZ30DArray.map(o => o.y)));
            setCdDataMinZ30D(Math.min(...props.coherentProbeData?.cdZ30DArray.map(o => o.y)));

            setDgdDataMaxA24H(Math.max(...props.coherentProbeData?.dgdA24HArray.map(o => o.y)));
            setDgdDataMinA24H(Math.min(...props.coherentProbeData?.dgdA24HArray.map(o => o.y)));
            setDgdDataMaxZ24H(Math.max(...props.coherentProbeData?.dgdZ24HArray.map(o => o.y)));
            setDgdDataMinZ24H(Math.min(...props.coherentProbeData?.dgdZ24HArray.map(o => o.y)));

            setDgdDataMaxA30D(Math.max(...props.coherentProbeData?.dgdA30DArray.map(o => o.y)));
            setDgdDataMinA30D(Math.min(...props.coherentProbeData?.dgdA30DArray.map(o => o.y)));
            setDgdDataMaxZ30D(Math.max(...props.coherentProbeData?.dgdZ30DArray.map(o => o.y)));
            setDgdDataMinZ30D(Math.min(...props.coherentProbeData?.dgdZ30DArray.map(o => o.y)));

            setOsnrDataMaxA24H(Math.max(...props.coherentProbeData?.osnrA24HArray.map(o => o.y)));
            setOsnrDataMinA24H(Math.min(...props.coherentProbeData?.osnrA24HArray.map(o => o.y)));
            setOsnrDataMaxZ24H(Math.max(...props.coherentProbeData?.osnrZ24HArray.map(o => o.y)));
            setOsnrDataMinZ24H(Math.min(...props.coherentProbeData?.osnrZ24HArray.map(o => o.y)));

            setOsnrDataMaxA30D(Math.max(...props.coherentProbeData?.osnrA30DArray.map(o => o.y)));
            setOsnrDataMinA30D(Math.min(...props.coherentProbeData?.osnrA30DArray.map(o => o.y)));
            setOsnrDataMaxZ30D(Math.max(...props.coherentProbeData?.osnrZ30DArray.map(o => o.y)));
            setOsnrDataMinZ30D(Math.min(...props.coherentProbeData?.osnrZ30DArray.map(o => o.y)));

        }
    }, [logger, props.coherentProbeData, selectedInternalMpid, user])


    //This formats the time for the x axis
    const formatTime = (value) => {
        if(displayFormat[1].type === '24') {
            return value.toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true })
        } else {
            return value.toLocaleString('en-US', { month: '2-digit', day: '2-digit'})
        }
    }

    //Changes the display format from 24 hours to 30 days
    const displayAction = () => {
        let changePos = [displayFormat[0], displayFormat[1]] = [displayFormat[1], displayFormat[0]];
        setDisplayFormat(changePos);
    }

    //Exports data to excel
    const exportData = () => {
        logger.info("Exporting data to excel");
        let exportArrayA = [];
        let exportArrayZ = [];
        const excelHeaders = ["Date", "CLLI", "OSNR (dB)", "DGD (ps)", "CD (ps/nm)"];
        if (displayFormat[1].type === '24') {
            exportArrayA = cdDataA24H.map((item, index) => {
                return {
                    "Date": item.x?.toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', hour12: true }),
                    "CLLI": clliA,
                    "OSNR (dB)": osnrDataA24H[index].y,
                    "DGD (ps)": dgdDataA24H[index].y,
                    "CD (ps/nm)": item.y,
                };
            });
            exportArrayZ = cdDataZ24H.map((item, index) => {
                return {
                    "Date": item.x?.toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', hour12: true }),
                    "CLLI": clliZ,
                    "OSNR (dB)": osnrDataZ24H[index].y,
                    "DGD (ps)": dgdDataZ24H[index].y,
                    "CD (ps/nm)": item.y,
                };
            });
        } else {
            exportArrayA = cdDataA30D.map((item, index) => {
                return {
                    "Date": item.x?.toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric'}),
                    "CLLI": clliA,
                    "OSNR (dB)": osnrDataA30D[index].y,
                    "DGD (ps)": dgdDataA30D[index].y,
                    "CD (ps/nm)": item.y,
                };
            });
            exportArrayZ = cdDataZ30D.map((item, index) => {
                return {
                    "Date": item.x?.toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric'}),
                    "CLLI": clliZ,
                    "OSNR (dB)": osnrDataZ30D[index].y,
                    "DGD (ps)": dgdDataZ30D[index].y,
                    "CD (ps/nm)": item.y,
                };
            });
        }

        //Combining arrays to export the Location A and Location Z data into one excel sheet
        const finalExportArray = [...exportArrayA, ...exportArrayZ];

        logger.info(JSON.stringify(finalExportArray));
        XLSXExport.exportToExcel(excelHeaders, finalExportArray, `LAYER_ZERO_EXPORT_${Date.now()}`)

    }

    return (
        <>
            <div className="sub-hdr">
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path
                                    d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2>Layer Zero</h2>

            </div>
            <div className="section">
                <div className="content">
                    <table className={`table table-bordered table-striped ${style.coherentProbeTable}`}>
                        <thead>
                            <th colspan="4">Windstream Layer 0 Analytics</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="4" className={style.coherentProbeDesc}>
                                    Metrics displayed are for the Windstream infrastructure provided through the Windstream supplied Coherent Transceiver.
                                    Slight variances may exist from the Customer provided Coherent Transceiver. <br></br><span className="text-center">Metrics displayed in table below represent most recent reading</span>
                                </td>
                            </tr>
                            <tr className={style.coherentProbeClliHeader}>
                                <td colspan="2" style={{ borderRight: '2px solid black', color: 'black' }}>LOCATION A</td>
                                <td colspan="2">LOCATION Z</td>
                            </tr>
                            <tr>
                                <td>CLLI</td>
                                <td>{clliA}</td>
                                <td>CLLI</td>
                                <td>{clliZ}</td>
                            </tr>
                            <tr>
                                <td>DATE TIME</td>
                                <td>{latestADate?.toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true })}</td>
                                <td>DATE TIME</td>
                                <td>{latestZDate?.toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true })}</td>
                            </tr>
                            <tr>
                                <td>OSNR (dB)</td>
                                <td>{osnrDataA24H?.[osnrDataA24H?.length - 1]?.["y"] ? osnrDataA24H?.[osnrDataA24H.length - 1]?.["y"]?.toFixed(1) : 0}</td>
                                <td>OSNR (dB)</td>
                                <td>{osnrDataZ24H?.[osnrDataZ24H?.length - 1]?.["y"] ? osnrDataZ24H?.[osnrDataZ24H.length - 1]?.["y"]?.toFixed(1) : 0}</td>
                            </tr>
                            <tr>
                                <td>DGD (ps)</td>
                                <td>{dgdDataA24H?.[dgdDataA24H?.length - 1]?.["y"] ? Math.round(dgdDataA24H?.[dgdDataA24H.length - 1]?.["y"]) : 0}</td>
                                <td>DGD (ps)</td>
                                <td>{dgdDataZ24H?.[dgdDataZ24H?.length - 1]?.["y"] ? Math.round(dgdDataZ24H?.[dgdDataZ24H.length - 1]?.["y"]) : 0}</td>
                            </tr>
                            <tr>
                                <td>CD (ps/nm)</td>
                                <td>{cdDataA24H?.[cdDataA24H?.length - 1]?.["y"] ? Math.round(cdDataA24H?.[cdDataA24H.length - 1]?.["y"]) : 0}</td>
                                <td>CD (ps/nm)</td>
                                <td>{cdDataZ24H?.[cdDataZ24H?.length - 1]?.["y"] ? Math.round(cdDataZ24H?.[cdDataZ24H.length - 1]?.["y"]) : 0}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="m-5 d-flex justify-content-between">
                        <button className="light-button text-uppercase" onClick={() => { displayAction() }}>{displayFormat[0].text}</button>
                        <button className="light-button text-uppercase" onClick={() => {exportData()}}>Save Report to File</button>
                    </div>
                    <div className="row d-flex">
                        <div className="col-6">
                            <h6 className="text-dark text-center mb-3">{clliA} - <span className="text-secondary">{displayFormat[1].title}</span></h6>
                        </div>
                        <div className="col-6">
                            <h6 className="text-dark text-center mb-3">{clliZ} - <span className="text-secondary">{displayFormat[1].title}</span></h6>
                        </div>
                    </div>
                    {/* OSNR Graphs */}
                    <div className='row d-flex'>
                        <div className="col-12">
                            <h2 className="text-center text-dark mb-3"><span>OSNR (db)</span></h2>
                        </div>
                    </div>
                    <div className='row d-flex'>
                        <div className="col-6">
                            <h6 className="text-center text-dark"><span>Max: {displayFormat[1].type === '24' ? osnrDataMaxA24H?.toFixed(2) : osnrDataMaxA30D?.toFixed(2)}</span><span> Min: {displayFormat[1].type === '24' ? osnrDataMinA24H?.toFixed(2) : osnrDataMinA30D?.toFixed(2)}</span></h6>
                        </div>
                        <div className="col-6">
                            <h6 className="text-center text-dark"><span>Max: {displayFormat[1].type === '24' ? osnrDataMaxZ24H?.toFixed(2) : osnrDataMaxZ30D?.toFixed(2)}</span><span> Min: {displayFormat[1].type === '24' ? osnrDataMinZ24H?.toFixed(2) : osnrDataMinZ30D?.toFixed(2)}</span></h6>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-6">
                            <LineChart
                                dataset={displayFormat[1].type === '24' ? osnrDataA24H : osnrDataA30D}
                                xAxis={[{
                                    dataKey: 'x',
                                    scaleType: 'point',
                                    label: displayFormat[1].title,
                                    labelStyle: {
                                        transform: 'translateY(35px)'
                                    },
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                series={[{ dataKey: 'y', area: true, color: '#753BBD', showMark: false}]}
                                yAxis={[{
                                    label: "OSNR (dB)",
                                    max: displayFormat[1].type === '24' ? osnrDataMaxA24H : osnrDataMaxA30D,
                                    min: displayFormat[1].type === '24' ? osnrDataMinA24H : osnrDataMinA30D
                                }]}
                                margin={{ top: 5, right: -10, bottom: 120, left: 100 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-35px)',
                                    }
                                }}
                            />
                        </div>
                        <div className="col-6">
                            <LineChart
                                dataset={displayFormat[1].type === '24' ? osnrDataZ24H : osnrDataZ30D}
                                xAxis={[{
                                    dataKey: 'x',
                                    scaleType: 'point',
                                    label: displayFormat[1].title,
                                    labelStyle: {
                                        transform: 'translateY(35px)'
                                    },
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                series={[{ dataKey: 'y', area: true, color: '#753BBD', showMark: false }]}
                                yAxis={[{
                                    label: "OSNR (dB)",
                                    max: displayFormat[1].type === '24' ? osnrDataMaxZ24H : osnrDataMaxZ30D,
                                    min: displayFormat[1].type === '24' ? osnrDataMinZ24H : osnrDataMinZ30D
                                }]}
                                margin={{ top: 5, right: -10, bottom: 120, left: 100 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-35px)',
                                    }
                                  }}
                            />
                        </div>
                    </div>
                    {/* Comparing OSNR from CLLI A and Z */}
                    <div className="row d-flex">
                        <div className="col-12">
                            <LineChart
                                xAxis={[{
                                    id: 'dateTime',
                                    scaleType: 'point',
                                    data: displayFormat[1].type === '24' ? osnrDataA24H.map(obj => obj.x) : osnrDataA30D.map(obj => obj.x),
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                yAxis={[{ label: 'CLLI A OSNR (dB) vs CLLI Z OSNR (dB)'}]}
                                series={[
                                    { id: 'osnrA', label: `CLLI A OSNR (dB)`, data: displayFormat[1].type === '24' ? osnrDataA24H.map(obj => obj.y) : osnrDataA30D.map(obj => obj.y), color: 'orange'},
                                    { id: 'osnrZ', label: `CLLI Z OSNR (dB)`, data: displayFormat[1].type === '24' ? osnrDataZ24H.map(obj => obj.y) : osnrDataZ30D.map(obj => obj.y), color: '#59cbe8'}
                                ]}
                                margin={{ top: 45, right: -10, bottom: 120, left: 120 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-45px)',
                                    }
                                }}
                                slotProps={{
                                    legend: {
                                        itemGap: 25
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={style.dividers}></div>
                    <div className='row d-flex mt-3'>
                        <div className="col-12">
                            <h2 className="text-dark text-center mb-3"><span>DGD (ps)</span></h2>
                        </div>
                    </div>
                    <div className='row d-flex'>
                        <div className="col-6">
                            <h6 className="text-center text-dark"><span>Max: {displayFormat[1].type === '24' ? dgdDataMaxA24H?.toFixed(2) : dgdDataMaxA30D?.toFixed(2)}</span><span> Min: {displayFormat[1].type === '24' ? dgdDataMinA24H?.toFixed(2) : dgdDataMinA30D?.toFixed(2)}</span></h6>
                        </div>
                        <div className="col-6">
                            <h6 className="text-center text-dark"><span>Max: {displayFormat[1].type === '24' ? dgdDataMaxZ24H?.toFixed(2) : dgdDataMaxZ30D?.toFixed(2)}</span><span> Min: {displayFormat[1].type === '24' ? dgdDataMinZ24H?.toFixed(2) : dgdDataMinZ30D?.toFixed(2)}</span></h6>
                        </div>
                    </div>
                    {/*DGD Graphs*/}
                    <div className="row d-flex">
                        <div className="col-6">
                            <LineChart
                                dataset={displayFormat[1].type === '24' ? dgdDataA24H : dgdDataA30D}
                                xAxis={[{
                                    dataKey: 'x',
                                    scaleType: 'point',
                                    label: displayFormat[1].title,
                                    labelStyle: {
                                        transform: 'translateY(35px)'
                                    },
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                series={[{ dataKey: 'y', area: true, color: '#753BBD', showMark: false }]}
                                yAxis={[{
                                    label: "DGD (ps)",
                                    max: displayFormat[1].type === '24' ? dgdDataMaxA24H : dgdDataMaxA30D,
                                    min: displayFormat[1].type === '24' ? dgdDataMinA24H : dgdDataMinA30D
                                }]}
                                margin={{ top: 5, right: -10, bottom: 120, left: 100 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-35px)',
                                    }
                                  }}
                            />
                        </div>
                        <div className="col-6">
                            <LineChart
                                dataset={displayFormat[1].type === '24' ? dgdDataZ24H : dgdDataZ30D}
                                xAxis={[{
                                    dataKey: 'x',
                                    scaleType: 'point',
                                    label: displayFormat[1].title,
                                    labelStyle: {
                                        transform: 'translateY(35px)'
                                    },
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                series={[{ dataKey: 'y', area: true, color: '#753BBD', showMark: false}]}
                                yAxis={[{
                                    label: "DGD (ps)",
                                    max: displayFormat[1].type === '24' ? dgdDataMaxZ24H : dgdDataMaxZ30D,
                                    min: displayFormat[1].type === '24' ? dgdDataMinZ24H : dgdDataMinZ30D
                                }]}
                                margin={{ top: 5, right: -10, bottom: 120, left: 100 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-35px)',
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {/* Comparing DGD from CLLI A and Z */}
                    <div className="row d-flex">
                        <div className="col-12">
                            <LineChart
                                xAxis={[{
                                    id: 'dateTime',
                                    data: displayFormat[1].type === '24' ? dgdDataA24H.map(obj => obj.x) : dgdDataA30D.map(obj => obj.x),
                                    scaleType: 'point',
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                yAxis={[{ label: "CLLI A DGD (ps) vs CLLI Z DGD (ps)"}]}
                                series={[
                                    { id: 'dgdA', label: 'CLLI A DGD (ps)', data: displayFormat[1].type === '24' ? dgdDataA24H.map(obj => obj.y) : dgdDataA30D.map(obj => obj.y), color: 'orange'},
                                    { id: 'dgdZ', label: 'CLLI Z DGD (ps)', data: displayFormat[1].type === '24' ? dgdDataZ24H.map(obj => obj.y) : dgdDataZ30D.map(obj => obj.y), color: '#59cbe8'}]}
                                margin={{ top: 45, right: -10, bottom: 120, left: 120 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-45px)',
                                    }
                                }}
                                slotProps={{
                                    legend: {
                                        itemGap: 25
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={style.dividers}></div>
                    <div className='row d-flex mt-3'>
                        <div className="col-12">
                            <h2 className="text-dark text-center"><span>CD (ps/nm)</span></h2>
                        </div>
                    </div>
                    <div className='row d-flex'>
                        <div className="col-6">
                            <h6 className="text-center text-dark"><span>Max: {displayFormat[1].type === '24' ? cdDataMaxA24H?.toFixed(2) : cdDataMaxA30D?.toFixed(2)}</span><span> Min: {displayFormat[1].type === '24' ? cdDataMinA24H?.toFixed(2) : cdDataMinA30D?.toFixed(2)}</span></h6>
                        </div>
                        <div className="col-6">
                            <h6 className="text-center text-dark"><span>Max: {displayFormat[1].type === '24' ? cdDataMaxZ24H?.toFixed(2) : cdDataMaxZ30D?.toFixed(2)}</span><span> Min: {displayFormat[1].type === '24' ? cdDataMinZ24H?.toFixed(2) : cdDataMinZ30D?.toFixed(2)}</span></h6>
                        </div>
                    </div>
                    {/*CD Graphs*/}
                    <div className="row d-flex">
                        <div className="col-6">
                            <LineChart
                                dataset={displayFormat[1].type === '24' ? cdDataA24H : cdDataA30D}
                                xAxis={[{
                                    dataKey: 'x',
                                    scaleType: 'point',
                                    label: displayFormat[1].title,
                                    labelStyle: {
                                        transform: 'translateY(35px)'
                                    },
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                series={[{ dataKey: 'y', area: true, color: '#753BBD', showMark: false }]}
                                yAxis={[{
                                    label: "CD (ps/nm)",
                                    max: displayFormat[1].type === '24' ? cdDataMaxA24H : cdDataMaxA30D,
                                    min: displayFormat[1].type === '24' ? cdDataMinA24H : cdDataMinA30D
                                }]}
                                margin={{ top: 5, right: -10, bottom: 120, left: 100 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-45px)',
                                    }
                                }}
                            />
                        </div>
                        <div className="col-6">
                            <LineChart
                                dataset={displayFormat[1].type === '24' ? cdDataZ24H : cdDataZ30D}
                                xAxis={[{
                                    dataKey: 'x',
                                    scaleType: 'point',
                                    label: displayFormat[1].title,
                                    labelStyle: {
                                        transform: 'translateY(35px)'
                                    },
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                series={[{ dataKey: 'y', area: true, color: '#753BBD', showMark: false }]}
                                yAxis={[{
                                    label: "CD (ps/nm)",
                                    max: displayFormat[1].type === '24' ? cdDataMaxZ24H : cdDataMaxZ30D,
                                    min: displayFormat[1].type === '24' ? cdDataMinZ24H : cdDataMinZ30D
                                }]}
                                margin={{ top: 5, right: -10, bottom: 120, left: 100 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-45px)',
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {/* Comparing CD from CLLI A and Z */}
                    <div className="row d-flex">
                        <div className="col-12">
                            <LineChart
                                xAxis={[{
                                    id: 'dateTime',
                                    data: displayFormat[1].type === '24' ? cdDataA24H.map(obj => obj.x) : cdDataA30D.map(obj => obj.x),
                                    scaleType: 'point',
                                    tickLabelStyle: {
                                        transform: 'rotate(35deg)',
                                        textAnchor: 'start'
                                    },
                                    valueFormatter: (value) => formatTime(value)
                                }]}
                                yAxis={[{ label: `CLLI A CD (ps/nm) vs CLLI Z CD (ps/nm)`}]}
                                series={[
                                    { id: 'cdA', label: `CLLI A CD (ps/nm)`, data: displayFormat[1].type === '24' ? cdDataA24H.map(obj => obj.y) : cdDataA30D.map(obj => obj.y), color: 'orange'},
                                    { id: 'cdZ', label: `CLLI Z CD (ps/nm)`, data: displayFormat[1].type === '24' ? cdDataZ24H.map(obj => obj.y) : cdDataZ30D.map(obj => obj.y), color: '#59cbe8'}
                                ]}
                                margin={{ top: 45, right: -10, bottom: 120, left: 120 }}
                                height={400}
                                grid={{ vertical: false, horizontal: true }}
                                sx={{
                                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                      transform: 'translateX(-45px)',
                                    }
                                }}
                                slotProps={{
                                    legend: {
                                        itemGap: 25
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default CoherentProbes;
