/********************************************************************************
File Upload Component
props:  refId
Purpose: Upoading or viewing uploaded files on the Quote View or Order View page
*********************************************************************************/
import { useCallback, useEffect, useMemo, useState } from 'react';
import style from './FileUpload.module.css';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import FileUploadHelper from "../../services/FileUploadService";
import Loader from './Loader';
import ReactTooltip from 'react-tooltip';
import { useSelector } from "react-redux";

const FileUpload = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [fileTypesArray, setFileTypesArray] = useState([]);
	const [allFileTypesArray, setAllFileTypesArray] = useState([]);
	const [fileType, setFileType] = useState('');
	const [fileInternalFlag, setFileInternalFlag] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
	const [uploadFilesExist, setUploadFilesExist] = useState(0);
	const [newFileUploaded, setNewFileUploaded] = useState(0);
	const [uploadResponse, setUploadResponse] = useState("");
	const [uploadResponseColor, setUploadResponseColor] = useState("");
	const [uploadFiles, setUploadFiles] = useState([]);
	const [loader, setLoader] = useState(false);
	const serviceOrderFlag = useSelector(state => state.orderCreate?.orderReview?.generateServiceOrder);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const isCustomer = localStorage.getItem('isLoggedIn');
	const pdfGenerated = localStorage.getItem('getUploadedFiles');

	/*Get the list of all the file types for file upload*/
	const getFileTypesArray = useCallback(() => {
		setLoader(true);
		FileUploadHelper.getFileTypes().then(resp => {
			setFileTypesArray(resp.data);
			setLoader(false);
		}).catch((error) => {
			logger.error("getFilesTypes ERROR: " + error)
		})
	}, [logger])

	/*Get the list of all the file types for file display menu*/
	const getDisplayFileTypesArray = useCallback(() => {
		setLoader(true);
		FileUploadHelper.getAllFileTypes().then(resp => {
			setAllFileTypesArray(resp.data);
			setLoader(false);
		}).catch((error) => {
			logger.error("getFilesTypes ERROR: " + error)
		})
	}, [logger])

	function handleUpload(event) {
		setLoader(true);
		event.preventDefault()
		const formData = new FormData();
		formData.append("userfile", selectedFile);
		if (isEmployee && !fileInternalFlag) {
			setUploadResponse("Please select Internal or External file attachment permissions");
			setLoader(false);
		} else {
			FileUploadHelper.uploadFile(formData, fileType, props.refId, '', fileInternalFlag).then(response => {
				if (response.data.result === "SUCCESS") {
					logger.info("Upload RESPONSE: " + response.data);
					setUploadResponse("The file was uploaded successfully.");
					setUploadResponseColor("text-success");
					setFileType('');
					setFileInternalFlag('');
					setSelectedFile([]);
					getUploadedFiles(props.refId);
					setNewFileUploaded(Date.now());
				} else {
					setUploadResponse(response.data.error);
					setUploadResponseColor("text-danger");
				}
				setLoader(false);
			}).catch((error) => {
				logger.error(`Upload ERROR: ${error}`);
				setLoader(false);
			})
		}
	}

	const getUploadedFiles = useCallback((refId) => {
		if (refId) {
			setLoader(true);
			FileUploadHelper.getUploadedFiles(refId).then(response => {
				logger.info("getUploadedFiles triggered");
				if (response.data !== null) {
					setUploadFilesExist(Date.now());
					setUploadFiles(response.data);
					props.onUploadSuccess(response.data);
				}
				setLoader(false);
			}).catch((error) => {
				logger.error(`setUploadFiles ERROR: ${error}`);
				setLoader(false);

			})
		}
	}, [logger])

	const handleFileTypeSelect = (event) => {
		setFileType(event.target.value);
	}

	const handleFileSelect = (event) => {
		const file = event.target.files[0];
		if (file) {
			const fileName = file.name;
			const fileExtension = fileName.split('.').pop().toLowerCase();

			if (fileExtension === 'msg') { /* TASK 960563 */
				setUploadResponse("Uploading .msg files is not allowed. Please save as a different file type and try to upload again. ");
				setUploadResponseColor("text-danger");
				setSelectedFile(null);
				return;
			}

			setSelectedFile(file);
		}
	}
	const radioBtnChange = (event) => {
		console.log("FileInternalFlag: " + event.target.value);
		setFileInternalFlag(event.target.value);
	}

	const handleNewFileTypeSelect = (ev) => {
		setLoader(true);
		logger.info(ev.target.id + ' : ' + ev.target.value);
		FileUploadHelper.updateFileType(props.refId, ev.target.id, ev.target.value).then(response => {
			if (response.data.result === "SUCCESS") {
				setUploadResponse("The file type was updated successfully.");
				setUploadResponseColor("text-success");
				getUploadedFiles(props.refId);
				setLoader(false);
			} else {
				setUploadResponse("The file type failed to updated. ");
				setUploadResponseColor("text-danger");
				getUploadedFiles(props.refId);
				setLoader(false);
			}
		}).catch((error) => {
			logger.error(`handleNewFileTypeSelect ERROR: ${error}`);
			setLoader(false);

		})
	}

	const deleteFileHandler = (token) => {
		setLoader(true);
		FileUploadHelper.deleteFile(token).then(response => {
			logger.info(response.data);
			if (response.data.result === "SUCCESS") {
				setUploadResponse("The file was deleted successfully.");
				setUploadResponseColor("text-success");
				getUploadedFiles(props.refId);
				setLoader(false);
			} else {
				setUploadResponse("The file failed to delete");
				setUploadResponseColor("text-danger");
				getUploadedFiles(props.refId);
				setLoader(false);
			}
		})
	}

	useEffect(() => {
		getDisplayFileTypesArray();
	}, [getDisplayFileTypesArray]);

	useEffect(() => {
		getFileTypesArray();
		getUploadedFiles(props.refId);
		if (pdfGenerated) { localStorage.removeItem('getUploadedFiles'); }
		logger.info("newFileUploaded: " + newFileUploaded);
	}, [newFileUploaded, getFileTypesArray, getUploadedFiles, props.refId, serviceOrderFlag, pdfGenerated]);

	return (
		<>
			{loader ? <Loader /> : null}
			<div className="sub-section">
				<div className="row">
					<form onSubmit={handleUpload}>
						<label className={style.uploadText}> File Type </label>
						<select className={` form-control d-inline-block  ${style.selectFld}`} id="fileType" value={fileType} onChange={handleFileTypeSelect}>
							{fileTypesArray.map((obj) => {
								return <option value={obj.id}>{obj.name}</option>
							})}
						</select>
						<label htmlFor="uploadFile" className={style.uploadLabel}>
							<div className={style.uploadLabelText}>Browse...</div>
							<input type="file" id="uploadFile" className={style.uploadFile} onChange={handleFileSelect} />
						</label>
						<button type="submit" className={style.uploadBtn}>Upload</button>
					</form>
				</div>
				{isEmployee ?
					<div className="row">
						<div className="col-1">
							<input type="radio" value='1' name='internal' onChange={(e) => { radioBtnChange(e) }} checked={fileInternalFlag === '1'} /> Internal
						</div>
						<div className="col-1">
							<input type="radio" value='0' name='internal' onChange={(e) => { radioBtnChange(e) }} checked={fileInternalFlag === '0'} /> External
						</div>
					</div>
					: null}
				<div className="row">
					<div className="col-8">
						<span className={`text-center ${uploadResponseColor}`}>{uploadResponse}</span>
					</div>
				</div>
				<div className="col-8">
					{uploadFilesExist && Array.isArray(uploadFiles) ?
						<div className={style.uplodadFileList}>
							{uploadFiles.map((up, i) =>
								<div key={i}
									className="f_saveFile d-flex space-between">
									<div className="f_fileName">
										<a href={`${process.env.REACT_APP_API_URL}/SaveUploadedFile.php?tok=${encodeURIComponent(up.token)}`}
											className={style.lableTextS}>{up.filename}</a>
									</div>

									<p className={"f_filterDate"}>{up.date}</p>

									{isEmployee ?
										<>
											{up.flag === 'External' ?
												<p className={"f_externalFlag"}>{up.flag}</p>
												:
												<p className={"f_internalFlag"}>{up.flag}</p>
											}
										</>
										: null}

									<select className={` form-control d-inline-block  ${style.selectFld}`} value={up.type} id={up.token} onChange={handleNewFileTypeSelect}>
										{allFileTypesArray.map((obj) => {
											return <option value={obj.id} disabled={isCustomer && obj.internal ? "disabled" : ""}>{obj.name}</option>
										})}
									</select>
									{/*
										<button type="button" name="updateFileType" id={up.token} onClick={updateFileType} className={style.uploadBtn}>Update</button>
										*/}
									<ReactTooltip id='deleteFile' aria-haspopup='true' className='tootipCustom'>
										<p className='m-0'>
											Delete File
										</p>
									</ReactTooltip>

									<button className="action ico-link light-ico tipped" data-tip={"deleteFile"} data-for='deleteFile' onClick={() => deleteFileHandler(up.token)}>
										<svg className="ico-x"><path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path></svg>
									</button>
								</div>
							)}
						</div>
						: null}
				</div>
			</div>
		</>
	)

}
export default FileUpload;
