import React, { useEffect, useState } from 'react';
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import style from "./Profile.module.css";
import { connect, useSelector } from 'react-redux';
import UserManagementService from "../../services/UserManagementService";
import ProfileService from "../../services/ProfileServices";
import Loader from "../shared/Loader";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import XLSXExport from "../../utils/XLSXExport";
import { resetCompanyMpid, setCompanyMenu, setCompanyMpid } from "../../redux/actions/internalActions";
import axios from 'axios';
import {
	account_vertical,
	acna,
	acnas,
	ae,
	agent_flag,
	api_address_flag,
	api_email,
	api_enabled,
	ban,
	billingProfileGlobalID,
	Broadband,
	BroadbandDiscount,
	cam,
	camp_accounts,
	camp_accounts_download,
	Colocation,
	ColocationDiscount,
	commsAccount,
	company,
	wf_company,
	company_rollup,
	DarkFiber,
	DarkFiberDiscount,
	DIAPOP,
	DIAPOPDiscount,
	DIAPrem,
	DIAPremDiscount,
	EAccess,
	EAccessDiscount,
	EAccessNNI,
	EAccessNNIDiscount,
	eis,
	ELine,
	ELineDiscount,
	email,
	ext,
	fixedWirelessEAccess,
	fixedWirelessLayer3,
	fName,
	jurisdiction,
	lName,
	ManagedSpectrum,
	ManagedSpectrumDiscount,
	MPLSAggNNI,
	MPLSAggNNIDiscount,
	MPLSAggTail,
	MPLSAggTailDiscount,
	MPLSInternet,
	MPLSInternetDiscount,
	nctc,
	newProfile,
	nnis,
	offnetVendorVisible,
	onNetOnly,
	phone,
	PrivateLine,
	PrivateLineDiscount,
	profileChange,
	profileUserTable,
	resetProfile,
	se,
	selectedProfile,
	unique_id,
	profileHistory
} from '../../redux';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import { Typography } from "@mui/material";

const Profile = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [profileSelected, setProfileSelected] = useState(false);
	const [isNewProfile, setIsNewProfile] = useState(false);
	const [aeOptions, setAeOptions] = useState({});
	const [camOptions, setCamOptions] = useState({});
	const [seOptions, setSeOptions] = useState({});
	const [userTableLoader, setUserTableLoader] = useState(false);
	const [MSG, setMSG] = useState('');
	const profileFromStore = useSelector(state => state.employeeProfile.profile);
	const selectedMpidFromStore = useSelector(state => state.internal.company);
	const [popupShow, setPopupShow] = useState(false);
	const [popUpMsg, setPopupMsg] = useState("");
	const [popUpAction, setPopUpAction] = useState("");
	const [loader, setLoader] = useState(false);
	const [hasError, setHasError] = useState(null);
	const [counter, setCounter] = useState(0);
	const [uniqueIdField, setUniqueIdField] = useState(false);
	const agent_flag = useSelector(state => state.employeeProfile?.profile?.agent_flag);
	const api_address_flag = useSelector(state => state.employeeProfile?.profile?.api_address_flag);
	const [agentFlag, setAgentFlag] = useState('');
	const [apiAddressFlag, setApiAddressFlag] = useState('');

	//Activating and Deactiving users
	const [userStatus, setUserStatus] = useState("");
	const [userId, setUserId] = useState("");
	const [statusUpdateSuccessMsg, setStatusUpdateSuccessMsg] = useState("");
	const [statusUpdateErrorMsg, setStatusUpdateErrorMsg] = useState("");
	;
	const XLSXHeaders = ["User ID", "First Name", "Last Name", "Email", "Status", "Transport Quote", "Transport Order", "Invoice System", "Trouble Tickets", "Web User Admin", "First Login", "Last Login", "Last Modified"];
	const submitJSON = {
		// how will we store MPID to pass across pages and submission
		"selectedProfile": profileFromStore.selectedProfile,
		"company": profileFromStore.company,
		"wf_company": profileFromStore.wf_company,
		"company_rollup": profileFromStore.company_rollup,
		"fName": profileFromStore.fName,
		"lName": profileFromStore.lName,
		"email": profileFromStore.email,
		"phone": profileFromStore.phone,
		"ext": profileFromStore.ext,
		"jurisdiction": profileFromStore.jurisdiction,
		"account_vertical": profileFromStore.account_vertical,
		"agent_flag": agentFlag,
		"api_address_flag": apiAddressFlag,
		"ae": profileFromStore.ae,
		"cam": profileFromStore.cam,
		"se": profileFromStore.se,
		"fixedWirelessLayer3": profileFromStore.fixedWirelessLayer3,
		"fixedWirelessEAccess": profileFromStore.fixedWirelessEAccess,
		"onNetOnly": profileFromStore.onNetOnly,
		"nctc": profileFromStore.nctc,
		"eis": profileFromStore.eis,
		"offnetVendorVisible": profileFromStore.offnetVendorVisible,
		"billingProfileGlobalID": profileFromStore.billingProfileGlobalID,
		"commsAccount": profileFromStore.commsAccount,
		"ban": profileFromStore.ban,
		"acna": profileFromStore.acna,
		"uid": profileFromStore.unique_id
	}

	const [pageSize, setPageSize] = useState(25);
	const [filterModel, setFilterModel] = useState({
		items: [],
	});

	const handleSearchChange = (searchValue) => {
		setFilterModel({
			items: [{ columnField: 'name', operatorValue: 'contains', value: searchValue },],
		});
	};

	const columns = [
		{
			field: 'userId',
			headerName: 'User ID',
			renderCell: (params) => formatActiveUserLink(params),
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.5,
		}
		,
		{
			field: 'firstName',
			headerName: 'First Name',
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.5,
		},
		{
			field: 'lastName',
			headerName: 'Last Name',
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.5,
		},
		{
			field: 'email',
			headerName: 'Email',
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.5,
		},
		{
			field: 'webAccess',
			headerName: 'Web Access',
			renderCell: (params) => formatWebAccessDisplay(params),
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.5,
		},
		{
			field: 'loginInfo',
			headerName: 'Login Info',
			renderCell: (params) => formatLoginInfoDisplay(params),
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.5,
		}
	];

	function formatSearchHeader(params) {
		return <div>
			<span style={{ marginLeft: 8, color: "#753BBD" }}>{params.colDef.headerName}</span>
		</div>;
	}

	const formatActiveUserLink = (params) => {
		let uid = params.row.userId;
		let icid = params.row.iconnectID;
		let hasID = icid?.length;
		return (
			<div className={style.activeUserLinksContainer}>
				{hasID ?
					<div className={style.activeUserLinks}>
						<Typography>
							<button className={style.userIdTextProfile}
								onClick={() => navClick("EDIT_USER", uid)}>{uid}</button>
						</Typography>
						<Typography>
							<button className={style.userIdTextProfile}
								onClick={() => statusUserAction(params.row.status, uid)}>{params.row.status}</button>
						</Typography>
					</div> :
					<div className={style.activeUserLinks}>
						<Typography>
							<p>{uid}</p>
						</Typography>
						<Typography>
							<p className='text-danger'>Not set up for iconnect access</p>
						</Typography>
					</div>
				}
			</div>
		);
	}

	const formatWebAccessDisplay = (params) => {
		const listItems = params.value.map((val, index) => (
			<Typography style={{ fontSize: "12px" }}>
				<div>{val}</div>
			</Typography>
		));

		return (
			<div>
				{listItems}
			</div>
		);
	}

	const formatLoginInfoDisplay = (params) => {
		// Initialize an empty array to hold the JSX for list items
		const listItems = [];

		// Iterate over the params.value array two elements at a time
		for (let i = 0; i < params.value?.length; i += 2) {
			listItems.push(
				<div key={i}>{params.value[i]} {params.value[i + 1]}</div>
			);
		}

		return (
			<div>
				<Typography style={{ fontSize: "12px" }}>
					{listItems}
				</Typography>
			</div>
		);
	}

	useEffect(() => {
		if (agent_flag !== undefined) {
			setAgentFlag(agent_flag);
		}
	}, [agent_flag]);

	useEffect(() => {
		if (api_address_flag !== undefined) {
			setApiAddressFlag(api_address_flag);
		}
	}, [api_address_flag]);

	useEffect(() => {
		//Wait until a company is selected before calling profileHandle
		if (selectedMpidFromStore !== profileFromStore.selectedProfile && selectedMpidFromStore?.value !== "") {
			//reset all tab loaders and clear any data in the profile object in the redux store when a new company is selected
			props.clearProfile();
			props.handleAccountsLoaded(false);
			props.handleNNIsLoaded(false);
			props.handleProductsLoaded(false);
			props.handleAPILoaded(false);
			props.handleHistoryLoaded(false);
			profileHandle(selectedMpidFromStore);
		} else {
			//if user navigates away and doesn't change the company we already have all the profile data loaded
			props.handleAccountsLoaded(true);
			props.handleNNIsLoaded(true);
			props.handleProductsLoaded(true);
			props.handleAPILoaded(true);
			props.handleHistoryLoaded(true);
		}

		if (profileFromStore.selectedProfile.value !== "") {
			setProfileSelected(true);
		}

	}, [selectedMpidFromStore]);

	const getEmployeeProfile = () => {
		const updateUserListJSON = {
			"action": "list",
			"status": "all",
			"site": "internal",
			"profile": selectedMpidFromStore.value
		};
		setUserTableLoader(true);
		/*Calls manage.php to get the data to populate the User Table at the botton of this page*/
		UserManagementService.getUserData(updateUserListJSON).then(resp => {
			props.profileUserTable(resp.data?.data);
			setUserTableLoader(false);
		}).catch(error => {
			setUserTableLoader(false);
			setHasError(error);
		})
	}

	// Moved this into a function out of profileHandle 7/18/23
	// makes calls to populate select profile data
	const getProfileData = () => {
		setLoader(true);
		ProfileService.getSelectedProfile().then(resp => {
			setCounter(counter + 1);
			logger.info(JSON.stringify(resp.data));
			props.company(resp.data.company?.length > 0 ? resp.data.company : "");
			props.wf_company(resp.data.wf_company?.length > 0 ? resp.data.wf_company : "");
			props.company_rollup(resp.data.company_rollup?.length > 0 ? resp.data.company_rollup : "");
			props.lName(resp.data.lname?.length > 0 ? resp.data.lname : "");
			props.fName(resp.data.fname?.length > 0 ? resp.data.fname : "");
			props.email(resp.data.contact_email?.length > 0 ? resp.data.contact_email : "");
			props.phone(resp.data.contact_phone?.length > 0 ? resp.data.contact_phone : "");
			props.ext(resp.data.contact_ext?.length > 0 ? resp.data.contact_ext : "");
			props.jurisdiction(resp.data.jurisdiction?.length > 0 ? resp.data.jurisdiction : "");
			props.account_vertical(resp.data.account_vertical?.length > 0 ? resp.data.account_vertical : "");
			props.agent_flag(resp.data.agent_flag?.length > 0 ? resp.data.agent_flag : "");
			setAgentFlag(resp.data.agent_flag);
			props.api_address_flag(resp.data.api_strict_addr?.length > 0 ? resp.data.api_strict_addr : "");
			setApiAddressFlag(resp.data.api_strict_addr);
			props.ae(resp.data.nam?.length > 0 ? resp.data.nam : "");
			props.cam(resp.data.cam?.length > 0 ? resp.data.cam : "");
			props.se(resp.data.se?.length > 0 ? resp.data.se : "");
			props.fixedWirelessLayer3(resp.data.eofw_avail?.length > 0 ? resp.data.eofw_avail : "");
			props.fixedWirelessEAccess(resp.data.eofw_eaccess?.length > 0 ? resp.data.eofw_eaccess : "");
			props.onNetOnly(resp.data.onnet_only?.length > 0 ? resp.data.onnet_only : "");
			props.nctc(resp.data.nctc_customer.length > 0 ? resp.data.nctc_customer : "");
			props.eis(resp.data.eis_flag?.length > 0 ? resp.data.eis_flag : "");
			props.offnetVendorVisible(resp.data.offnet_vendor_visible?.length > 0 ? resp.data.offnet_vendor_visible : "");
			props.billingProfileGlobalID(resp.data.rc8_acct?.length > 0 ? resp.data.rc8_acct : "");
			props.commsAccount(resp.data.df_salesforce_id?.length > 0 ? resp.data.df_salesforce_id : "");
			props.ban(resp.data.cdg_ban?.length > 0 ? resp.data.cdg_ban : "");
			props.acna(resp.data.acna?.length > 0 ? resp.data.acna : "");
			props.newProfile(resp.data.create_email_sent?.length > 0 && resp.data.create_email_sent === "0");
			if (resp.data.create_email_sent === "0") {
				props.updateProfileSelectedTab("ADD_USER"); // redirects to UserInfoSection.jsx
			}
			setLoader(false);
		}).catch(error => {
			logger.error("Error Getting Profiles: " + error);
			setLoader(false);
			setHasError(error);
		})

		ProfileService.getCampAcansAccount().then(axios.spread(({ data: camp }, { data: acnas }) => {
			logger.info({ camp, acnas });
			props.camp_accounts(camp.campAccounts);
			props.camp_accounts_download(camp.campAccountsDownload);
			props.acnas(acnas);
			props.handleAccountsLoaded(true); //handler passed from Employee.js that handles when accounts and acans are loaded
		})).catch(error => {
			logger.error("Error getting camp and acans : " + error);
		})

		ProfileService.getNNIS().then(resp => {
			setCounter(counter + 1);
			logger.info("NNIS: " + JSON.stringify(resp.data));
			props.nnis(resp.data);
			props.handleNNIsLoaded(true); //handler passed from Employee.js that handles when NNIs are loaded
		}).catch(err => {
			logger.error("Error Getting NNIs: " + err);
		})

		ProfileService.getAPI().then(resp => {
			setCounter(counter + 1);
			logger.info("API: " + JSON.stringify(resp.data));
			props.api_enabled(resp.data.api_enabled);
			props.api_email(resp.data.api_email);
			props.handleAPILoaded(true); //handler passed from Employee.js that handles when API is loaded
		}).catch(err => {
			logger.error("Error Getting API: " + err);
		})

		ProfileService.getCustomerProducts().then(resp => {
			setCounter(counter + 1);
			props.PrivateLine(resp.data.PrivateLine?.avail === '1');
			props.MPLSAggTail(resp.data.MPLSAggTail?.avail === '1');
			props.MPLSInternet(resp.data.MPLSInternet?.avail === '1');
			props.MPLSAggNNI(resp.data.MPLSAggNNI?.avail === '1');
			props.DIAPOP(resp.data.DIAPOP?.avail === '1');
			props.DIAPrem(resp.data.DIAPrem?.avail === '1');
			props.Colocation(resp.data.Colocation?.avail === '1');
			props.ELine(resp.data.ELine?.avail === '1');
			props.EAccess(resp.data.EAccess?.avail === '1');
			props.EAccessNNI(resp.data.EAccessNNI?.avail === '1');
			props.DarkFiber(resp.data.DarkFiber?.avail === '1');
			props.ManagedSpectrum(resp.data.ManagedSpectrum?.avail === '1');
			props.Broadband(resp.data.Broadband?.avail === '1');

			props.PrivateLineDiscount(resp.data.PrivateLine?.discount ? resp.data.PrivateLine.discount : "");
			props.MPLSAggTailDiscount(resp.data.MPLSAggTail?.discount ? resp.data.MPLSAggTail.discount : "");
			props.MPLSInternetDiscount(resp.data.MPLSInternet?.discount ? resp.data.MPLSInternet.discount : "");
			props.MPLSAggNNIDiscount(resp.data.MPLSAggNNI?.discount ? resp.data.MPLSAggNNI.discount : "");
			props.DIAPOPDiscount(resp.data.DIAPOP?.discount ? resp.data.DIAPOP.discount : "");
			props.DIAPremDiscount(resp.data.DIAPrem?.discount ? resp.data.DIAPrem.discount : "");
			props.ColocationDiscount(resp.data.Colocation?.discount ? resp.data.Colocation.discount : "");
			props.ELineDiscount(resp.data.ELine?.discount ? resp.data.ELine.discount : "");
			props.EAccessDiscount(resp.data.EAccess?.discount ? resp.data.EAccess.discount : "");
			props.EAccessNNIDiscount(resp.data.EAccessNNI?.discount ? resp.data.EAccessNNI.discount : "");
			props.DarkFiberDiscount(resp.data.DarkFiber?.discount ? resp.data.DarkFiber.discount : "");
			props.ManagedSpectrumDiscount(resp.data.ManagedSpectrum?.discount ? resp.data.ManagedSpectrum.discount : "");
			props.BroadbandDiscount(resp.data.Broadband?.discount ? resp.data.Broadband.discount : "");
			props.handleProductsLoaded(true); //handler that is passed using props from Employee.js determines whether all products have been loaded
		}).catch(error => {
			logger.error("Error Getting Customer Products: " + error);
			setHasError(error);
		})

		ProfileService.getProfileHistory().then(resp => {
			setCounter(counter + 1);
			//logger.info("History: " + JSON.stringify(resp.data));
			props.profileHistory(resp.data);
			props.handleHistoryLoaded(true); //handler passed from Employee.js that handles when History is loaded
		}).catch(err => {
			logger.error("Error Getting API: " + err);
		})

		getEmployeeProfile();
	}

	const profileHandle = () => {
		setMSG('');
		props.selectedProfile(selectedMpidFromStore); // set selectedProfile in store
		if (!selectedMpidFromStore) { // profile not selected
			props.clearProfile();
			setProfileSelected(false);
			setLoader(false);
		} else if (selectedMpidFromStore.value !== 'new') {
			setIsNewProfile(false);
			setProfileSelected(true);
			getProfileData();
		} else { // create new profile selected
			setIsNewProfile(true);
			props.clearProfile();
			setProfileSelected(true);
			props.selectedProfile(selectedMpidFromStore); // set selectedProfile in store
			setLoader(true);
			ProfileService.getAeCamSeOptions().then(axios.spread(({ data: aeData }, { data: camData }, { data: seData }) => {
				logger.info({ aeData, camData, seData });
				setAeOptions(aeData);
				setCamOptions(camData);
				setSeOptions(seData);
				setLoader(false);
			})).catch(error => {
				setLoader(false);
				logger.error("Error Getting AeCamSeOptions: " + error);
			})
			setLoader(false);
		}
	}

	const statusUserAction = (status, id) => {
		setPopupShow(true);
		setPopUpAction("updating user status");
		if (status === 'Active') {
			setPopupMsg("This will deactivate the user's account. Click Ok to continue or Cancel. ");
		} else {
			setPopupMsg("This will activate the user's account. Click Ok to continue or Cancel.");
		}
		updateStatus(status, id);
		setUserId(id);
		setUserStatus(status);
	}

	const cancelAction = () => {
		setPopupShow(true);
		setPopUpAction("cancel new profile");
		setPopupMsg("Are you sure you want to cancel?");
	}

	const popupOk = (action) => {
		logger.info(userStatus);
		switch (action) {
			// footer cancel btn
			case "cancel new profile":
				resetData();
				break;
			// clicking on status link under the user id column of the user table
			case "updating user status":
				updateStatus(userStatus, userId);
				break;
			default:
				break;
		}
		popupCancel();
	}

	const popupCancel = () => {
		setPopupShow(false);
	}

	/*Clearing profile object in redux store*/
	const resetData = () => {
		setMSG('');
		props.clearProfile();

	}

	const navClick = (link, userId) => {
		//Calls function in Employee.js which changes the active tab and sets the active tab in the redux store
		props.handleNav(link, userId);
	}


	const inputChange = (e, field) => {
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		if (field === 'agent_flag') {
			if (value) {
				setAgentFlag('1');
			} else {
				setAgentFlag('');
			}
		} else if (field === 'agent_customer_flag') {
			if (value) {
				setAgentFlag('2');
			} else {
				setAgentFlag('');
			}
		} else if (field === 'api_address_flag') {
			setApiAddressFlag(value);
		} else {
			props[field](value);
		}
	}

	/*Function to create a new profile*/
	const submitProfile = () => {
		let validationError = false;

		if (!profileFromStore['lName'] || !profileFromStore['fName'] || !profileFromStore['email'] || !profileFromStore['phone'] ||
			!profileFromStore['ae'] || !profileFromStore['cam'] || !profileFromStore['se'] ||
			!profileFromStore['billingProfileGlobalID'] || !profileFromStore['commsAccount'] || !profileFromStore['acna']) {
			validationError = true;
		}

		if (validationError && isNewProfile) {
			setMSG('Please verify all required fields are completed')
		} else {
			/*Passed validation submit Profile so we need to call profileSubmit*/
			logger.info("Profile Data: " + JSON.stringify(submitJSON));
			setLoader(true);
			ProfileService.submitProfile(submitJSON).then(response => {
				logger.info('Profile Submit Response: ' + JSON.stringify(response.data));
				logger.info('Profile Submit Result: ' + encodeURIComponent(response.data.result).replace(/%20/g, " "));
				if (response.data.result === "SUCCESS") {
					let profileList = null;
					let str = '';
					ProfileService.getProfiles().then(resp => {
						logger.info('Get Profiles: ' + encodeURIComponent(JSON.stringify(resp.data)).replace(/%20/g, " "));
						profileList = resp.data;
						profileList = profileList.map(item => {
							str = `${item.name}`;
							return { mpid: item.id, name: str }
						});
						setCompanyMenu(profileList);
						props.updateCompanyMenu(profileList);
						props.updateCompanyMpid(response.data.newProfile);
						//calls function in Employee.js
						props.newProfileCreated();
						profileHandle(response.data.newProfile);
						setLoader(false);
					}).catch(error => {
						logger.error("Getting Profiles: " + error);
						setLoader(false);
					})
					setMSG('Success');
					if (isNewProfile) {
						props.newProfile(true);
						props.updateProfileSelectedTab("ADD_USER"); // redirects to UserInfoSection.jsx
					}
				} else {
					if (response.data.uniqueIdField) {
						setUniqueIdField(true);
					} else {
						setUniqueIdField(false);
					}
					setMSG(response.data.error);
				}
				setLoader(false);
			}).catch(error => {
				logger.error("Create Profile ERROR: " + error)
				setMSG('An error has occurred.');
				setLoader(false);
				setHasError(error);
			})
		}
	}

	/*Set a user to Active or Inactive by click the status link under the User ID column of the User Table*/
	const updateStatus = (userStatus, userId) => {
		setPopupShow(false);
		setLoader(true);
		const updateJSON = {
			"action": userStatus === "Active" ? "disable" : "enable",
			"uid": userId
		}
		/*call manage.php to update a user to active or inactive*/
		UserManagementService.updateUserStatus(updateJSON).then(resp => {
			logger.info(JSON.stringify(resp.data));
			if (resp.data.result === 'SUCCESS') {
				if (userStatus === "Active") {
					logger.info(`Successfully disabled user ${userId}`);
					setStatusUpdateSuccessMsg(`Successfully disabled user: ${userId}`);
				} else {
					logger.info(`Successfully activated user ${userId}`);
					setStatusUpdateSuccessMsg(`Successfully activated user: ${userId}`);
				}
				setLoader(false);
				/*Get updated User Table with new status*/
				getEmployeeProfile();

			} else {
				/*Setting error message to response from manage.php*/
				logger.info(userStatus);
				if (userStatus === "Active") {
					logger.info(`Error disabling user ${userId}`);
					setStatusUpdateErrorMsg(resp.data.error);
				} else {
					logger.info(`Error activating user ${userId}`);
					setStatusUpdateErrorMsg(resp.data.error);
				}
				setLoader(false);
			}
			/*clearing local state variable*/
			setUserId("");
			setUserStatus("");
		}).catch(error => {
			setLoader(false);
			setHasError(error);
		});
	}

	const downloadUserData = () => {
		setLoader(true);
		const downloadUserListJSON = {
			"action": "download",
			"status": "all",
			"site": "internal",
			"profile": selectedMpidFromStore.value
		};
		/*Calls manage.php to get the data to populate the User Table at the botton of this page*/
		UserManagementService.getUserData(downloadUserListJSON).then(resp => {
			const exportArr = resp.data.data.map((item) => {
				return {
					'User ID': item.userId,
					'First Name': item.firstName,
					'Last Name': item.lastName,
					'Email': item.email,
					'Status': item.status,
					'Transport Quote': item.transportQuote,
					'Transport Order': item.transportOrder,
					'Invoice System': item.invoiceSystem,
					'Trouble Tickets': item.troubleTicket,
					'Web User Admin': item.webUserAdmin,
					'First Login': item.firstLogin,
					'Last Login': item.lastLogin,
					'Last Modified': item.lastModified
				};
			});
			XLSXExport.exportToExcel(XLSXHeaders, exportArr, `UserData_${Date.now()}`)
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			setHasError(error);
		})
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			<div>
				{loader ? <Loader /> : null}
				<p className='text-right'><span className={`${style.mandatoryProfile}`}>*</span> Denotes a required
					field</p>
				{profileSelected ?
					<>
						<div className={`sub-hdr alt0`}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2 className={`m-0 `}>Customer Information</h2>
						</div>

						<div className='px-3'>
							<div className='row'>
								<div className='col-12 col-md-6'>
									{selectedMpidFromStore?.value === 'new' ?
										<>
											<div className='my-3'>
												<label className='labelText'> COMPANY <span
													className={` ${style.mandatoryProfile} `}>*</span></label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['company']} onChange={(e) => {
														inputChange(e, 'company')
													}} />
											</div>
											<div className='my-3'>
												<label className='labelText'> First Name <span
													className={` ${style.mandatoryProfile} `}>*</span></label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['fName']} onChange={(e) => {
														inputChange(e, 'fName')
													}} />
											</div>
											<div className='my-3'>
												<label className='labelText'> Last Name <span
													className={` ${style.mandatoryProfile} `}>*</span></label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['lName']} onChange={(e) => {
														inputChange(e, 'lName')
													}} />
											</div>
											<div className='my-3'>
												<label className='labelText'> Email <span
													className={` ${style.mandatoryProfile} `}>*</span></label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['email']} onChange={(e) => {
														inputChange(e, 'email')
													}} />
											</div>
											{uniqueIdField ?
												<div className='my-3'>
													<label className='labelText'> Unique ID <span
														className={` ${style.mandatoryProfile} `}>*</span></label>
													<input type="text" className={`${style.inputFldTextProfile} `}
														value={profileFromStore['unique_id']} onChange={(e) => {
															inputChange(e, 'unique_id')
														}} />
												</div>
												: null}
											<div className='d-flex'>
												<div className='mr-3'>
													<label className='labelText'> phone <span
														className={` ${style.mandatoryProfile} `}>*</span></label>
													<input type="text"
														className={`${style.inputFldTextProfile} ${style.inputFldPhoneProfile} `}
														value={profileFromStore['phone']} onChange={(e) => {
															inputChange(e, 'phone')
														}} />
												</div>
												<div className=''>
													<label className='labelText'> Ext</label>
													<input type="text"
														className={`${style.inputFldTextProfile} ${style.inputFldPhoneProfile} `}
														value={profileFromStore['ext']} onChange={(e) => {
															inputChange(e, 'ext')
														}} />
												</div>
											</div>
										</>
										:
										<>
											<div className='my-3'>
												<label className='labelText'> COMPANY </label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['company']} onChange={(e) => {
														inputChange(e, 'company')
													}} />
											</div>
											<div className='my-3'>
												<label className='labelText'> WinForce Company </label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['wf_company']} disabled="disabled" />
											</div>
											<div className='my-3'>
												<label className='labelText'> Company Rollup </label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['company_rollup']} disabled="disabled" />
											</div>
											<div className='my-3'>
												<label className='labelText'> Vertical </label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['account_vertical']} onChange={(e) => {
														inputChange(e, 'account_vertical')
													}} disabled="disabled" />
											</div>
											<div className='my-3'>
												<label className='labelText'> Jurisdictional Traffic
													Certification </label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['jurisdiction']} onChange={(e) => {
														inputChange(e, 'jurisdiction')
													}} disabled="disabled" />
											</div>
											{profileFromStore['account_vertical'] === 'Global Accounts' ?
												<>
													<div className='my-3'>
														<input type="checkbox" className="" id="agent" checked={agentFlag === '1'}
															onChange={(e) => {
																inputChange(e, 'agent_flag')
															}} />
														<label className={`${style.agentLabelTextProfile}`}
															htmlFor="agent">Agent</label>
													</div>
													<div className='my-3'>
														<input type="checkbox" className="" id="agent_customer" checked={agentFlag === '2'}
															onChange={(e) => {
																inputChange(e, 'agent_customer_flag')
															}} />
														<label className={`${style.agentLabelTextProfile}`}
															htmlFor="agent_customer">Agent Customer</label>
													</div>
												</>
												: null}
											<div className='my-3'>
												<input type="checkbox" className="" id="api_address_flag"
													checked={apiAddressFlag} onChange={(e) => {
														inputChange(e, 'api_address_flag')
													}} />
												<label className={`${style.agentLabelTextProfile}`}
													htmlFor="api_address_flag">Use
													Strict Address Checks for Quote API</label>
											</div>
										</>
									}
								</div>

								<div className='col-12 col-md-6'>
									{selectedMpidFromStore?.value === 'new' ?
										<>
											<div className={`my-3 rel ${style.selectWrap} `}>
												<label className='labelText'> AE <span
													className={` ${style.mandatoryProfile} `}>*</span></label>
												<select className={`rel`} value={profileFromStore['ae']}
													onChange={(e) => {
														inputChange(e, 'ae')
													}}>
													{aeOptions?.length > 0 ?
														<>
															{aeOptions.map(item => (
																<option key={item.id} value={item.id}>
																	{item.name}
																</option>
															))}
														</>
														:
														null}
												</select>
											</div>

											<div className={`my-3 rel ${style.selectWrap} `}>
												<label className='labelText'> CAM <span
													className={` ${style.mandatoryProfile} `}>*</span></label>
												<select className={`rel`}
													value={profileFromStore['cam']} onChange={(e) => {
														inputChange(e, 'cam')
													}}>
													{camOptions?.length > 0 ?
														<>
															{camOptions.map(item => (
																<option key={item.id} value={item.id}>
																	{item.name}
																</option>
															))}
														</>
														:
														null}
												</select>
											</div>

											<div className={`my-3 rel ${style.selectWrap} `}>
												<label className='labelText'> SE<span
													className={` ${style.mandatoryProfile} `}>*</span></label>
												<select className={`rel`}
													value={profileFromStore['se']} onChange={(e) => {
														inputChange(e, 'se')
													}}>
													{seOptions?.length > 0 ?
														<>
															{seOptions.map(item => (
																<option key={item.id} value={item.id}>
																	{item.name}
																</option>
															))}
														</>
														:
														null}
												</select>
											</div>

										</>
										:
										<>
											<div className='my-3'>
												<label className='labelText'> AE </label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['ae']} onChange={(e) => {
														inputChange(e, 'ae')
													}} disabled="disabled" />
											</div>
											<div className='my-3'>
												<label className='labelText'> CAM </label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['cam']} onChange={(e) => {
														inputChange(e, 'cam')
													}} disabled="disabled" />
											</div>
											<div className='my-3'>
												<label className='labelText'> SE </label>
												<input type="text" className={`${style.inputFldTextProfile} `}
													value={profileFromStore['se']} onChange={(e) => {
														inputChange(e, 'se')
													}} disabled="disabled" />
											</div>
										</>
									}
								</div>
							</div>
						</div>

						<div className={`sub-hdr alt0`}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2 className={` m-0 `}>Quoting Options</h2>
						</div>

						<div className={`my-3 ${style.quotingOptionProfile}`}>
							<div className='row ml-2'>
								<div className="mx-2">
									<div className="">
										<input type="checkbox" className="" id="noNet"
											checked={profileFromStore['onNetOnly']} onChange={(e) => {
												inputChange(e, 'onNetOnly')
											}} />
										<label className={`${style.labelText}`} htmlFor="noNet">OnNet Only</label>
									</div>
								</div>
								{selectedMpidFromStore?.value !== 'new' ?
									<>
										<div className="mx-2">
											<div className="">
												<input type="checkbox" className="" id="NCTC"
													checked={profileFromStore['nctc']} onChange={(e) => {
														inputChange(e, 'nctc')
													}} disabled="disabled" />
												<label className={`${style.labelText}`} htmlFor="NCTC">NCTC</label>
											</div>
										</div>
									</>
									:
									<>
										<div className="mx-2">
											<div className="">
												<input type="checkbox" className="" id="NCTC"
													checked={profileFromStore['nctc']} onChange={(e) => {
														inputChange(e, 'nctc')
													}} />
												<label className={`${style.labelText}`} htmlFor="NCTC">NCTC</label>
											</div>
										</div>
									</>
								}
								<div className="mx-2">
									<div className="">
										<input type="checkbox" className="" id="EIS" checked={profileFromStore['eis']}
											onChange={(e) => {
												inputChange(e, 'eis')
											}} />
										<label className={`${style.labelText}`} htmlFor="EIS">EIS</label>
									</div>
								</div>
								<div className="mx-2">
									<div className="">
										<input type="checkbox" className="" id="ovv"
											checked={profileFromStore['offnetVendorVisible']} onChange={(e) => {
												inputChange(e, 'offnetVendorVisible')
											}} />
										<label className={`${style.labelText}`} htmlFor="ovv">OffNet Vendor
											Visible</label>
									</div>
								</div>
							</div>
						</div>

						{selectedMpidFromStore?.value === 'new' ?
							<>
								<div className={`sub-hdr alt0`}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={` m-0 `}>Accounts</h2>
								</div>

								<div className='my-3 px-3'>
									<div className='row'>
										<div className='col-12 col-sm-12 col-md-4'>
											<label className='labelText'> Billing Profile global ID <span
												className={` ${style.mandatoryProfile} `}>*</span></label>
											<input type="text" className={`${style.inputFldTextProfile} w-100 `}
												value={profileFromStore['billingProfileGlobalID']} onChange={(e) => {
													inputChange(e, 'billingProfileGlobalID')
												}} />
										</div>
										<div className='col-12 col-sm-12 col-md-4'>
											<label className='labelText'> Comms Account <span
												className={` ${style.mandatoryProfile} `}>*</span></label>
											<input type="text" className={`${style.inputFldTextProfile} w-100 `}
												value={profileFromStore['commsAccount']} onChange={(e) => {
													inputChange(e, 'commsAccount')
												}} />
										</div>
										<div className='col-12 col-sm-12 col-md-4'>
											<label className='labelText'> CDG BAN </label>
											<input type="text" className={`${style.inputFldTextProfile} w-100 `}
												value={profileFromStore['ban']} onChange={(e) => {
													inputChange(e, 'ban')
												}} />
										</div>
										<div className='col-12 col-sm-12 col-md-4'>
											<label className='labelText'> ACNA<span
												className={` ${style.mandatoryProfile} `}>*</span></label>
											<input type="text" className={`${style.inputFldTextProfile} w-100 `}
												value={profileFromStore['acna']} onChange={(e) => {
													inputChange(e, 'acna')
												}} />
										</div>
									</div>
								</div>
							</>
							:
							<>
								<div className={`sub-hdr alt0`}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={` m-0 `}>Accounts</h2>
								</div>

								<div className='my-3 px-3'>
									<div className='row'>
										<div className='col-12 col-sm-12 col-md-4'>
											<label className='labelText'> Billing Profile global ID </label>
											<input type="text" className={`${style.inputFldTextProfile} w-100 `}
												value={profileFromStore['billingProfileGlobalID']} onChange={(e) => {
													inputChange(e, 'billingProfileGlobalID')
												}} />
										</div>
										<div className='col-12 col-sm-12 col-md-4'>
											<label className='labelText'> Comms Account </label>
											<input type="text" className={`${style.inputFldTextProfile} w-100 `}
												value={profileFromStore['commsAccount']} onChange={(e) => {
													inputChange(e, 'commsAccount')
												}} />
										</div>
										<div className='col-12 col-sm-12 col-md-4'>
											<label className='labelText'> CDG BAN</label>
											<input type="text" className={`${style.inputFldTextProfile} w-100 `}
												value={profileFromStore['ban']} onChange={(e) => {
													inputChange(e, 'ban')
												}} />

										</div>
									</div>
								</div>
							</>
						}

						{MSG?.length ?
							<div>
								<p className={`text-center my-4 ${MSG === 'Success' ? 'text-success' : 'text-danger'} `}>{MSG}</p>
							</div> : null}

						<div className='text-center mt-4'>
							<button className={` ico-button light-button  ${style.cancelBtn} mr-4`} onClick={() => {
								cancelAction()
							}}>
								Cancel
								<svg className="ico-x">
									<path
										d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
								</svg>
							</button>

							<button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `}
								onClick={() => {
									submitProfile()
								}}>
								Submit
								<svg className="ico light-ico ico-arrow ml-2">
									<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
								</svg>
							</button>
						</div>

						{selectedMpidFromStore?.value !== 'new' ?
							<>
								{statusUpdateSuccessMsg ?
									<div className="mt-5">
										<p className="text-success text-center">{statusUpdateSuccessMsg}</p>
									</div> : null
								}
								{statusUpdateErrorMsg ?
									<div className="mt-5">
										<p className="text-danger text-center">{statusUpdateErrorMsg}</p>
									</div> : null
								}
								<div className={`sub-hdr alt0`}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={` m-0 `}><span>Users{userTableLoader ?
										<div className='spinner-border spinner-border-lg ml-2'
											style={{ borderColor: '#753bbd', borderRightColor: 'transparent' }}
											role='status' /> : null}</span></h2>
								</div>
								<div className={style.downloadBtn}>
									<button className="ico-button light-button" onClick={() => {
										downloadUserData()
									}}>
										<span><strong>Download User Data</strong></span>
										<svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
											<path className="cls-1"
												d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
												transform="translate(-1 -1)"></path>
										</svg>
									</button>
								</div>
								<div>
									<div style={{ width: "100%" }}>
										<StripedDataGrid
											autoHeight
											theme={CustomTheme}
											rows={profileFromStore.userTable}
											rowHeight={120}
											getRowId={(row) => row.userId}
											columns={columns}
											filterModel={filterModel}
											onFilterModelChange={(model) => setFilterModel(model)}
											pageSize={pageSize}
											rowsPerPageOptions={[]}
											resultsPerPageOptions={[]}
											pageSizeOptions={[]}
											onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
											slots={{
												pagination: TablePagination,
											}}
											initialState={{
												pagination: {
													paginationModel: {
														pageSize: 25
													},
												},
											}}
										/>
									</div>
								</div>
							</>
							: null}
					</>
					:
					null
				}

				{(popupShow ?
					<div>
						<div className={`${style.overlyProfile}`}></div>
						<div className="modal fade show d-block">
							<div className={` modal-dialog modal-dialog-centered modal-sm`}>
								<div className="modal-content">
									<div className="modal-body">
										{popUpMsg}
									</div>

									<div className="modal-footer d-block text-center">
										<button type="button" className="btn-style btn-theme" data-dismiss="modal"
											onClick={() => popupOk(popUpAction)}>Ok
										</button>
										<button type="button" className="btn-style btn-cancel" data-dismiss="modal"
											onClick={() => popupCancel(popUpAction)}>Cancel
										</button>
									</div>

								</div>
							</div>
						</div>
					</div>
					: null)
				}
			</div>
		</>
	)
}

const mapDispatchToProps = {
	selectedProfile: selectedProfile,
	company: company,
	wf_company: wf_company,
	company_rollup: company_rollup,
	fName: fName,
	lName: lName,
	email: email,
	phone: phone,
	ext: ext,
	jurisdiction: jurisdiction,
	account_vertical: account_vertical,
	agent_flag: agent_flag,
	api_address_flag: api_address_flag,
	ae: ae,
	cam: cam,
	se: se,
	fixedWirelessLayer3: fixedWirelessLayer3,
	fixedWirelessEAccess: fixedWirelessEAccess,
	onNetOnly: onNetOnly,
	nctc: nctc,
	eis: eis,
	offnetVendorVisible: offnetVendorVisible,
	billingProfileGlobalID: billingProfileGlobalID,
	commsAccount: commsAccount,
	ban: ban,
	acna: acna,
	clearProfile: resetProfile,
	unique_id: unique_id,
	newProfile: newProfile,
	profileUserTable: profileUserTable,
	// Camp Accounts
	acnas: acnas,
	camp_accounts: camp_accounts,
	camp_accounts_download: camp_accounts_download,
	// products
	PrivateLine: PrivateLine,
	MPLSAggTail: MPLSAggTail,
	MPLSInternet: MPLSInternet,
	MPLSAggNNI: MPLSAggNNI,
	DIAPOP: DIAPOP,
	DIAPrem: DIAPrem,
	Colocation: Colocation,
	ELine: ELine,
	EAccess: EAccess,
	EAccessNNI: EAccessNNI,
	DarkFiber: DarkFiber,
	ManagedSpectrum: ManagedSpectrum,
	Broadband: Broadband,
	PrivateLineDiscount: PrivateLineDiscount,
	MPLSAggTailDiscount: MPLSAggTailDiscount,
	MPLSInternetDiscount: MPLSInternetDiscount,
	MPLSAggNNIDiscount: MPLSAggNNIDiscount,
	DIAPOPDiscount: DIAPOPDiscount,
	DIAPremDiscount: DIAPremDiscount,
	ColocationDiscount: ColocationDiscount,
	ELineDiscount: ELineDiscount,
	EAccessDiscount: EAccessDiscount,
	EAccessNNIDiscount: EAccessNNIDiscount,
	DarkFiberDiscount: DarkFiberDiscount,
	ManagedSpectrumDiscount: ManagedSpectrumDiscount,
	BroadbandDiscount: BroadbandDiscount,
	nnis: nnis,
	api_enabled: api_enabled,
	api_email: api_email,
	updateCompanyMenu: setCompanyMenu,
	updateCompanyMpid: setCompanyMpid,
	clearCompanyMpid: resetCompanyMpid,
	updateProfileSelectedTab: profileChange,
	profileHistory: profileHistory
}

export default connect(null, mapDispatchToProps)(Profile);
