import { useCallback, useEffect, useMemo, useState } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import ReportHelper from '../../services/ReportService';
import InvoiceReport from './InvoiceReport';
import ParentChildReport from './ParentChildReport';
import Loader from "../shared/Loader";
import TroubleTicketReport from './TroubleTicketReport';
import { useSelector } from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';
import CsrReport from './CsrReport';
import OrderActivity from './OrderActivity';

const SupportReport = () => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const [hasError, setHasError] = useState(null);
	const [selectionError, setSelectionError] = useState(null);
	const [displayOnlyGenerateButton, setDisplayOnlyGenerateButton] = useState(false);
	const reportOptions = [
		{ id: "1", name: "Billing/Pricing" },
		{ id: "2", name: "Account Details" },
		...(isEmployee ? [
			{ id: "3", name: "Order Activity" },
			{ id: "4", name: "Account Team" }
		] : [])
	];
	const reportName = [
		{ "id": "13", "rid": "1", "name": "Executive Invoice Summary" },
		{ "id": "15", "rid": "1", "name": "Parent - Child Report" },
		{ "id": "14", "rid": "2", "name": "CSR - Customer Service Record" },
		{ "id": "16", "rid": "2", "name": "Trouble Ticket by Parent Account" },
		{ "id": "N", "rid": "3", "name": "New Service" },
		{ "id": "B", "rid": "3", "name": "Bandwidth Upgrade" },
		{ "id": "A", "rid": "3", "name": "Add" },
		{ "id": "C", "rid": "3", "name": "Change" },
		{ "id": "D", "rid": "3", "name": "Disconnect" },
		{ "id": "R", "rid": "3", "name": "Renewal" },
	];

	const [selectedReportOption, setSelectedReportOption] = useState({});
	const [selectedReportName, setSelectedReportName] = useState({});
	const [parentMenuOptions, setParentMenuOptions] = useState([]);
	const [childMenuOptions, setChildMenuOptions] = useState([]);
	const [invoiceMenuOptions, setInvoiceMenuOptions] = useState([]);
	const [reportData, setReportData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [selectedParentAccount, setSelectedParentAccount] = useState("");
	const [selectedChildAccount, setSelectedChildAccount] = useState("");
	const [selectedInvoice, setSelectedInvoice] = useState("");
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);

	/* Retrieves Parent Account Options */
	const getParentMenuOptions = useCallback(() => {
		setLoader(true);
		ReportHelper.getParentMenu().then(response => {
			logger.info(response.data);
			setParentMenuOptions(response.data);
			setLoader(false);
		}).catch(error => {
			logger.error("Error getting parent menu: " + error);
			setLoader(false);
			setHasError(error);
		})
	}, [logger])


	/* Retrieves Child Account Options */
	const getChildMenuOptions = useCallback(() => {
		setLoader(true);
		ReportHelper.getChildMenu(selectedParentAccount).then(response => {
			logger.info(response.data);
			setChildMenuOptions(response.data);
			setLoader(false);
		}).catch(error => {
			logger.error(`Error getting child menu: ${error}`);
			setLoader(false);
			setHasError(error);
		})
	}, [logger, selectedParentAccount])

	useEffect(() => {
		setSelectionError('');
		if (isEmployee && selectedReportOption?.id !== '3') {
			if (selectedInternalMpid) {
				getParentMenuOptions();
			}
		} else {
			getParentMenuOptions();
		}
	}, [getParentMenuOptions, isEmployee, selectedInternalMpid]);

	useEffect(() => {
		if (selectedParentAccount && ['CSR - Customer Service Record'].includes(selectedReportName?.name)) {
			getChildMenuOptions();
		}
	}, [getChildMenuOptions, selectedParentAccount, selectedReportName?.name])

	useEffect(() => {
		//Invoice Menu is only fetch when the parent account is selected and the report option is Billing/Pricing
		if (selectedParentAccount?.length > 0 && selectedReportOption.id === "1") {
			setInvoiceMenuOptions([]);
			setReportData([]);
			setLoader(true);
			/* Retrieves Invoice Month Options */
			ReportHelper.getInvoiceMenu(selectedParentAccount).then(response => {
				logger.info(encodeURIComponent(response.data).replace(/%20/g, " "));
				setInvoiceMenuOptions(response.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Error getting invoice menu: " + error);
				setLoader(false);
				setHasError(error);
			})
		}
	}, [logger, selectedParentAccount, selectedReportOption.id]);

	/*Reset selected parent account and invoice when report option or report name changes*/
	useEffect(() => {
		setSelectionError(null); // Clear any previous selection errors
		if (selectedInternalMpid === 'all' && (!selectedReportOption?.id || (selectedReportOption?.id !== '3' && selectedReportOption?.id !== '4'))) {
			setSelectionError("'All' companies is an invalid for the selected report, please choose a company.");
			return;
		}
		setSelectedParentAccount("");
		setSelectedInvoice("");
		setReportData([]);
		setInvoiceMenuOptions([]);
		setFromDate("");
		setToDate("");
	}, [selectedReportOption, selectedReportName])



	const reportOption = async (e) => {
		let index = e.nativeEvent.target.selectedIndex;
		let label = e.nativeEvent.target[index].text;
		let value = e.target.value;
		setSelectedReportOption({ id: value, name: label });
		setSelectedReportName({});
		setDisplayOnlyGenerateButton(value === "4"); // Hide menus if "Account Team" is selected
	}

	const reportNameHandle = async (e) => {
		let index = e.nativeEvent.target.selectedIndex;
		let label = e.nativeEvent.target[index].text;
		let value = e.target.value;
		setSelectedReportName({ id: value, name: label });
	}

	const generateReport = () => {
		if (isEmployee && !selectedInternalMpid && selectedReportOption?.id !== '4') {
			setSelectionError("Please select a company before generating a report.");
			return;
		}

		if (selectedInternalMpid === 'all' && (selectedReportOption?.id !== '3' && selectedReportOption?.id !== '4')) {
			setSelectionError("'All' companies is an invalid for the selected report, please choose a company.");
			return;
		} else {
			setSelectionError(null);
		}

		setLoader(true);
		let section = selectedReportOption.id;
		let report = selectedReportName.id;

		ReportHelper.getReport(section, report, selectedInvoice, selectedParentAccount, selectedChildAccount, fromDate, toDate).then(resp => {
			logger.info(JSON.stringify(resp.data));
			setReportData(resp.data);
			setLoader(false);

			if (selectedReportOption.id === "4") {
				downloadCSV(resp.data, 'Account_Team_Report.csv');
			}
		}).catch(error => {
			logger.error("Error getting report " + error);
			setLoader(false);
			setHasError(error);
		});
	}

	const downloadCSV = (csv, filename) => {
		const blob = new Blob([csv], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Reports" : "Reports"}</h1>
						{isEmployee ? <CompanyDropdown area={"Reports"} /> : null}
						{!hasError && (<div className="section">
							{/* <TabsQuote /> */}
							<div className="content">
								<div className=''>
									<div className={`sub-hdr alt `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className="">Report Options</h2>
									</div>
									{selectionError ? (
										<div className='text-danger'>{selectionError}</div>
									) : null}
									<div className='row d-flex my-5 ml-3'>
										<div className='col-4'>
											<label>Report Options</label>
											<select className="nice-select dark-select wide" onChange={(e) => { reportOption(e) }}>
												<option></option>
												{
													reportOptions.map(rp => (
														<option value={rp.id}>{rp.name}</option>
													))
												}
											</select>
										</div>
										{!!selectedReportOption?.id && selectedReportOption?.id !== '4' ?
											<div className='col-4'>
												{selectedReportOption?.id === '3' ? <label>Activity Type</label> : <label>Report Name</label>}
												<select className="nice-select dark-select wide" onChange={(e) => { reportNameHandle(e) }}>
													<option></option>
													{
														reportName.map(rn => (
															selectedReportOption?.id === rn.rid ?
																<option value={rn.id}>{rn.name}</option> : null
														))
													}
												</select>
											</div> : null
										}
									</div>

									{!displayOnlyGenerateButton && !!selectedReportOption?.id && !!selectedReportName?.name &&
										<>
											{selectedReportOption?.id !== '3' &&
												<div className='row d-flex my-5 ml-3'>
													<div className='col-4'>
														<label>Select Parent Account</label>
														<select className="nice-select dark-select wide" value={selectedParentAccount} onChange={(e) => { setSelectedParentAccount(e.target.value) }}>
															{parentMenuOptions.map(item => (
																<option key={item.id} value={item.id}>
																	{item.name}
																</option>
															))}
														</select>
													</div>
													{selectedReportName?.name === 'CSR - Customer Service Record' &&
														<div className='col-4'>
															<label>Select Child Account</label>
															{childMenuOptions === "No Child Accounts" ?
																<p className='text-danger'>No Child Accounts</p> :
																<select className="nice-select dark-select wide" value={selectedChildAccount} onChange={(e) => { setSelectedChildAccount(e.target.value) }}>
																	{childMenuOptions?.map(item => (
																		<option key={item.id} value={item.id}>
																			{item.name}
																		</option>
																	))}
																</select>
															}

														</div>
													}

													{['Executive Invoice Summary', 'Parent - Child Report'].includes(selectedReportName?.name) && selectedParentAccount?.length > 0 &&
														<div className='col-4'>
															<label>Invoice Month</label>
															{invoiceMenuOptions !== "No Invoices" ?
																<select className="nice-select dark-select wide" value={selectedInvoice} onChange={(e) => { setSelectedInvoice(e.target.value) }}>
																	{invoiceMenuOptions.map(item => (
																		<option key={item.id} value={item.id}>
																			{item.name}
																		</option>
																	))}
																</select> : <p className='text-danger'>No Invoices</p>
															}
														</div>
													}
												</div>
											}
											{selectedReportName?.name === 'Trouble Ticket by Parent Account' || selectedReportOption?.id === '3' &&
												<div className='row d-flex my-5 ml-3'>
													<div className='col-4'>
														<label>From</label>
														<input type='date' className='date-fld' placeholder='From' value={fromDate} onChange={(e) => { setFromDate(e.target.value) }} />
													</div>
													<div className='col-4'>
														<label>To</label>
														<input type='date' className='date-fld' placeholder='To' value={toDate} onChange={(e) => { setToDate(e.target.value) }} />
													</div>
												</div>
											}
										</>
									}

									{/*Wait until parent account and invoice number are selected to generate report*/}
									{displayOnlyGenerateButton ||
										((selectedParentAccount?.length > 0 && selectedInvoice?.length > 0) || ((selectedParentAccount?.length > 0 || selectedReportOption?.id === "3") && fromDate?.length > 0 && toDate?.length > 0) || (selectedReportName.id === "14")) ?
										<div className='text-center mt-3'>
											<button className='light-button' onClick={generateReport}>Generate Report</button>
										</div> : null
									}
									{/*Invoice Report*/}
									{reportData?.length > 0 && selectedReportName.id === "13" &&
										<div className='d-block my-5 ml-3'>
											{reportData !== "No data found for the given report." ?
												<InvoiceReport InvoiceData={reportData} /> : <p className='text-danger'>{reportData}</p>
											}
										</div>
									}
									{/*ParentChild Report*/}
									{reportData?.length > 0 && selectedReportName.id === "15" &&
										<div className='d-block my-5 ml-3'>
											{reportData !== "No data found for the given report." ?
												<ParentChildReport ParentChildData={reportData} /> : <p className='text-danger'>{reportData}</p>
											}
										</div>
									}
									{/*Trouble Ticket Report*/}
									{reportData?.length > 0 && selectedReportName.id === "14" &&
										<div className='d-block my-5 ml-3'>
											{reportData !== "No data found for the given report." ?
												<CsrReport csrData={reportData} /> : <p className='text-danger'>{reportData}</p>
											}
										</div>
									}
									{/*Trouble Ticket Report*/}
									{reportData?.length > 0 && selectedReportName.id === "16" &&
										<div className='d-block my-5 ml-3'>
											{reportData !== "No data found for the given report." ?
												<TroubleTicketReport TroubleTicketData={reportData} /> : <p className='text-danger'>{reportData}</p>
											}
										</div>
									}
									{/* Order Activity */}
									{reportData?.length > 0 && selectedReportOption?.id === "3" &&
										<div className='d-block my-5 ml-3'>
											{reportData !== "No data found for the given report." ?
												<OrderActivity reportData={reportData} activity={selectedReportName.id} /> : <p className='text-danger'>{reportData}</p>
											}
										</div>
									}
								</div>

							</div>
						</div>)}

					</div>
				</div>
			</div>
		</>
	);
}

export default SupportReport;