import React, { useState } from 'react';
import ProfileService from "../../services/ProfileServices";
import NNIModal from "./NNIModal";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import Loader from "../shared/Loader";
import { connect, useSelector } from 'react-redux';
import { nnis } from '../../redux';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import { Typography } from "@mui/material";

const Nnis = (props) => {
	const profileFromStore = useSelector(state => state.employeeProfile.profile);
	const nnisFromStore = useSelector(state => state.employeeProfile.nni);
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false);
	//const [NNIs, setNNIs] = useState([]);
	const [NNIObject, setNNIObject] = useState({ type: 'cse' });
	const [addNNIModal, setAddNNIModal] = useState(false);
	const [addMplsNNIModal, setAddMplsNNIModal] = useState(false);
	const [addNNICircuitModal, setAddNNICircuitModal] = useState(false);
	const [editNNIModal, setEditNNIModal] = useState(false);
	const [deleteNNIModal, setDeleteNNIModal] = useState(false);
	const [ErrorMSG, setErrorMSG] = useState('');
	const [SuccessMSG, setSuccessMSG] = useState('');

	const [pageSize, setPageSize] = useState(25);
	const [filterModel, setFilterModel] = useState({
		items: [],
	});

	const columns = [
		{
			field: 'nni',
			headerName: 'NNI',
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.25,
		},
		{
			field: 'address',
			headerName: 'Address',
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.5,
		},
		{
			field: 'circuitID',
			headerName: 'Circuit ID',
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.25,
		},

		{
			field: 'description',
			headerName: 'Description',
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.5,
		},
		{
			field: 'type',
			headerName: 'Type',
			renderHeader: (params) => formatSearchHeader(params),
			flex: 0.15,
		},
		{
			headerName: 'Action',
			renderCell: (params) => formatActionManager(params),
			flex: 0.25,
		}
	];

	function formatSearchHeader(params) {
		return <div>
			<span style={{ marginLeft: 8, color: "#753BBD" }}>{params.colDef.headerName}</span>
		</div>;
	}

	const formatActionManager = (params) => {
		const circuitID = params.row?.circuitID;

		const editNNI = () => {
			const obj = nnisFromStore.nnis.find(NNI => NNI.circuitID === circuitID);
			setNNIObject(obj);
			setEditNNIModal(!editNNIModal);
		}

		const deleteNNI = () => {
			logger.info('DELETE Row: ' + JSON.stringify(params.row));
			const obj = nnisFromStore.nnis.find(NNI => NNI.circuitID === circuitID);
			setNNIObject(obj);
			setDeleteNNIModal(!deleteNNIModal);
		}

		return (
			<div>
				<Typography className={"text-center"}>
					<button type="button" className={`light-button m-3 `} onClick={() => {
						editNNI();
					}}>
						<span>Edit</span>
					</button>
					<button type="button" className={`light-button m-3`} onClick={() => {
						deleteNNI();
					}}>
						<span>Delete</span>
					</button>
				</Typography>
			</div>
		)
	}
	const addCseNNIAction = () => {
		setAddNNIModal(true);
		setNNIObject({ type: 'cse' });
	}

	const addMplsNNIAction = () => {
		setAddMplsNNIModal(true);
		setNNIObject({ type: 'mpls' });
	}


	const addNNICircuitAction = () => {
		setAddNNICircuitModal(true);
	}

	const modalAction = () => {
		setAddNNIModal(false);
		setAddMplsNNIModal(false);
		setAddNNICircuitModal(false);
		setEditNNIModal(false);
		setDeleteNNIModal(false);
	}

	const setNNIValues = (addNNIValues) => {
		addNNI(addNNIValues);
	}

	const addNNI = (values) => {
		setLoader(true);
		setErrorMSG('');
		setSuccessMSG('');
		let action = 'add';
		let idx = values.index;
		let o_cid = '';
		if (!addNNIModal && !deleteNNIModal && editNNIModal) {
			action = 'edit';
			o_cid = nnisFromStore.nnis[idx].circuitID;
		}
		if (deleteNNIModal && !addNNIModal && !editNNIModal) {
			action = 'delete';
		}

		let submitJSON = {
			"selectedProfile": profileFromStore.selectedProfile.value,
			"action": action,
			"orig_cid": o_cid,
			"data": values
		}
		logger.info('SubmitNNI:' + JSON.stringify(submitJSON));
		ProfileService.submitNNIData(submitJSON).then(resp => {
			logger.info('submitNNIData Response: ' + encodeURIComponent(resp.data.result).replace(/%20/g, " "));
			if (resp.data.result === "SUCCESS") {
				ProfileService.getNNIS(profileFromStore.selectedProfile.value).then(nniData => {
					logger.info("NNIS re-displaying the table: " + encodeURIComponent(JSON.stringify(nniData.data)).replace(/%20/g, " "));
					props.nnis(nniData.data);
				}).catch(err => {
					logger.error("Error Getting NNIs: " + err);
					setLoader(false);
					setErrorMSG(err);
					setHasError(err);
				})
				setSuccessMSG(resp.data?.update);
				setErrorMSG(resp.data?.error);
				setLoader(false);
				//props.updatingNNI(); // accessing prop from Employee.js to make page scroll to top and view msg
			} else {
				logger.info("NNI Submit Error: " + encodeURIComponent(resp.data.error).replace(/%20/g, " "));
				if (resp?.data?.error?.length > 0) {
					setHasError(resp.data.error);
					setErrorMSG(resp.data.error);
				} else {
					setErrorMSG("An error occured and the NNI was not added or updated.");
					setHasError("An error occured and the NNI was not added or updated.")
				}
				setLoader(false);
				//props.updatingNNI(); // accessing prop from Employee.js to make page scroll to top and view msg
			}
		})
	}

	const setNNICircuitValues = (addNNIValues) => {
		addNNICircuit(addNNIValues);
	}

	const addNNICircuit = (values) => {
		setLoader(true);
		setErrorMSG('');
		setSuccessMSG('');
		let submitJSON = {
			"selectedProfile": profileFromStore.selectedProfile.value,
			"action": 'add_circuit',
			"data": values
		}
		logger.info('SubmitNNI:' + JSON.stringify(submitJSON));
		ProfileService.submitNNICircuitData(submitJSON).then(resp => {
			logger.info('submitNNICircuitData Response: ' + encodeURIComponent(resp.data.result).replace(/%20/g, " "));
			if (resp.data.result === "SUCCESS") {
				ProfileService.getNNIS(profileFromStore.selectedProfile.value).then(nniData => {
					logger.info("NNIS re-displaying the table: " + encodeURIComponent(JSON.stringify(nniData.data)).replace(/%20/g, " "));
					props.nnis(nniData.data);
				}).catch(err => {
					logger.error("Error Getting NNIs: " + err);
					setLoader(false);
					setErrorMSG(err);
					setHasError(err);
				})
				setSuccessMSG(resp.data.update);
				setLoader(false);
				//props.updatingNNI(); // accessing prop from Employee.js to make page scroll to top and view msg
			} else {
				logger.info("NNI Circuit Submit Error: " + encodeURIComponent(resp.data.error).replace(/%20/g, " "));
				if (resp?.data?.error?.length > 0) {
					setHasError(resp.data.error);
					setErrorMSG(resp.data.error);
				} else {
					setErrorMSG("An error occured and the NNI was not added or updated.");
					setHasError("An error occured and the NNI was not added or updated.")
				}
				setLoader(false);
			}
		})
	}

	return (
		<>
			{loader ? <Loader /> : null}
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			<div className="NNIsPage mt-5">
				<div className='headerName-right'>
					<button type="button" className={`light-button mr-3 px-3`} onClick={() => {
						addCseNNIAction()
					}}>
						Add CSE NNI
					</button>

					<button type="button" className={`light-button mr-3 px-3`} onClick={() => {
						addMplsNNIAction()
					}}>
						Add MPLS NNI
					</button>

					<button type="button" className={`light-button mr-3 px-3`} onClick={() => {
						addNNICircuitAction()
					}}>
						Add Circuit to NNI
					</button>
				</div>
				{ErrorMSG.length ?
					<div>
						<p className="text-danger text-center">{ErrorMSG}</p>
					</div> : null}
				{SuccessMSG.length ?
					<div>
						<p className={`headerName-center my-4 headerName-success`}>{SuccessMSG}</p>
					</div> : null}

				{nnisFromStore?.nnis.length ?
					<>
						<div className={`sub-hdr alt0`}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2 className={` m-0 `}>Customer NNI's</h2>
						</div>

						<div>
							<div style={{ width: "100%" }}>
								<StripedDataGrid
									autoHeight
									theme={CustomTheme}
									rows={nnisFromStore.nnis}
									rowHeight={75}
									rowSelection={false}
									getRowId={(row) => row.index}
									columns={columns}
									filterModel={filterModel}
									onFilterModelChange={(model) => setFilterModel(model)}
									pageSize={pageSize}
									rowsPerPageOptions={[]}
									pageSizeOptions={[]}
									onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
									slots={{
										pagination: TablePagination,
									}}
									initialState={{
										pagination: {
											paginationModel: {
												pageSize: 25
											},
										},
									}}
								/>
							</div>
						</div>
					</>
					:
					'No NNIs on Profile'
				}

				{addNNIModal ? <NNIModal title="Add CSE NNI" action="ADD" type={NNIObject.type} setNNIValues={setNNIValues} emitAction={() => {
					modalAction()
				}} /> : null}
				{addMplsNNIModal ? <NNIModal title="Add MPLS NNI" action="ADD" type={NNIObject.type} setNNIValues={setNNIValues} emitAction={() => {
					modalAction()
				}} /> : null}
				{addNNICircuitModal ? <NNIModal title="Add NNI Circuit" action="ADD_CIRCUIT" type={NNIObject.type} setNNIValues={setNNICircuitValues} emitAction={() => {
					modalAction()
				}} /> : null}
				{editNNIModal ?
					<NNIModal title="Edit NNI" action="EDIT" NNIObject={NNIObject} setNNIValues={setNNIValues}
						emitAction={() => {
							modalAction()
						}} /> : null}
				{deleteNNIModal ?
					<NNIModal title="Delete NNI" action="DELETE" NNIObject={NNIObject} setNNIValues={setNNIValues}
						emitAction={() => {
							modalAction()
						}} /> : null}
			</div>
		</>
	)
}

const mapDispatchToProps = {
	nnis: nnis
}

export default connect(null, mapDispatchToProps)(Nnis);