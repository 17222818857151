import React, {useState} from 'react';

export default function Navs(props) {
    const [linkName, setLinkName] = useState('Summary');

    const navClick = (link) => {
        setLinkName(link);
        props.handleNav(link);
    }

    return (
        <div className="tab-nav cf">
            <a href className={`tab-opt circuit-summary ${linkName === 'Summary' ? 'active' : ''} `} onClick={() => { navClick('Summary') }}>
                <span>Summary</span>
            </a>
            {props?.circuit?.includes("WG") && (props.coherentProbeData?.length === 0 || props.coherentProbeData?.cdA24HArray || props.coherentProbeData?.data) ?
                <a href className={`tab-opt circuit-summary ${linkName === 'Layer Zero' ? 'active' : ''} ${props.coherentProbeLoader ? 'disabled' : ''} `} onClick={() => { navClick('Layer Zero') }}>
                    <span>
                        Layer Zero
                        {props.coherentProbeLoader ? <div className='spinner-border spinner-border-sm ml-2' role='status'/> :  null}
                    </span>

                </a> : null
            }
            <a href className={`tab-opt circuit-create ${linkName === 'Tickets' ? 'active' : ''} `} onClick={() => { navClick('Tickets') }}>
                <span>Tickets</span>
            </a>
            <a href className={`tab-opt circuit-upload ${linkName === 'SM' ? 'active' : ''} `} onClick={() => { navClick('SM') }}>
                <span>Scheduled Maintenance</span>
            </a>
            {/*ADO-895806 - Hide Full Circuit Path tab for now
            <a href className={`tab-opt circuit-upload ${linkName === 'FCP' ? 'active' : ''} `} onClick={() => { navClick('FCP') }}>
                <span>Full Circuit Path</span>
            </a>
            */}
            {Number(props.designId) !== 0 && (/WGXA|LXFS|KJUS|KGUS|KRUS|KMUS/.test(props?.circuit) || /WAVE/.test(props?.product.toUpperCase())) ?
                <a href className={`tab-opt circuit-upload ${linkName === 'FP' ? 'active' : ''} ${props.kmzLoader  ? 'disabled' : ''}`} onClick={() => { navClick('FP') }}>
                    <span>
                        Full Fiber Path
                        {props.kmzLoader ? <div className='spinner-border spinner-border-sm ml-2' role='status'/> :  null}
                    </span>
                </a> : null
            }
        </div>
    );
}
