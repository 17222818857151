import { useSelector } from "react-redux";
import style from "../BulkQuoteView.module.css";
import { NavLink } from "react-router-dom";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import ReactTooltip from 'react-tooltip';

const BroadbandBulkView = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
    const isCustomer = localStorage.getItem("isLoggedIn");
    const selectedAccessValues = useSelector(state => state.userReducer.user.webaccess);
    let checkedList = [];

    const columns = [{
        dataField: "ref_id",
        text: "Ref ID",
        formatter: (cell, row) => quoteLinkFormatter(cell, row)
    }, {
        dataField: "bq_desc",
        text: "Status",
        formatter: (cell, row) => statusFormater(cell, row),
        headerFormatter: (cell) => headerFormatter(cell)
    }, {
        dataField: "address",
        text: "Address",
    }, {
        dataField: "bus_name",
        text: "Business Name"
    }, {
        dataField: "loop_band",
        text: "Bandwidth"
    }, {
        dataField: "modem",
        text: "Modem Rental"
    }, {
        dataField: "ip",
        text: "IPs"
    }, {
        dataField: "tier",
        text: "Tier"
    },{
        dataField: "mtm_mrc",
        text: "MTM MRC"
    }, {
        dataField: "1yr_mrc",
        text: "1yr MRC"
    }, {
        dataField: "2yr_mrc",
        text: "2yr MRC"
    }, {
        dataField: "3yr_mrc",
        text: "3yr MRC"
    }];

    const headerFormatter = (cell) => {
        return (
            <>
                <ReactTooltip id="statusToolTip" delayHide={75} effect="solid" place="top">
                    This will select all available quotes that can be made orderable.
                </ReactTooltip>
                <span data-tip data-for="statusToolTip" style={{"font-weight": "bold"}}>{cell.text}<input className={`${style.bulkOrderCheckbox} ml-2`} type="checkbox" id="all" onChange={handleOrderableCheckboxes} /></span>
            </>
        )
    }

    //Handles how the status column data is displayed
    const statusFormater = (cell, row) => {
        if (Number(row.status) === 1 && Number(row.made_orderable) === 1) {
            return (
                <div className={`${style.bulkQuoteLabel}`}>
                    Success
                </div>
            )
        } else if (Number(row.status) === 1 && Number(row.made_orderable) === 0 && (isEmployee || (isCustomer && Number(selectedAccessValues?.TRANS_ORDER) > 1))) {
            return (
                <div>
                    <input className={style.bulkOrderCheckbox} type={"checkbox"} id={row.ref_id} onChange={handleOrderableCheckboxes} />
                    <label htmlFor={row.ref_id} className={`${style.bulkQuoteLabel}`}>Make Orderable</label>
                </div>
            );
        } else {
            return (
                <div className={`${style.bulkQuoteLabel}`}>
                    <abbr title={row.note}>{row.bq_desc}</abbr>
                </div>
            );
        }
    }

    // This is how the link is set up.
    const quoteLinkFormatter = (cell, row) => {
        let URL = `/Quote/View/${row.ref_id}`;
        return (
            <NavLink className={`${style.bulkQuoteLabel} ${style.bulkQuoteLink}`} to={URL} activeClassName="active">{row.ref_id}</NavLink>
        )
    }

    // Handler for orderable checkboxes
    const handleOrderableCheckboxes = (event) => {
        logger.info(`Event: ${event.target.id}`);
        console.log(JSON.stringify(checkedList));
        if(event.target.id !== "all"){
            if (event.target.checked) {
                if (!checkedList.includes(event.target.id)) {
                    checkedList.push(event.target.id);
                }
            } else {
                let index = checkedList.indexOf(event.target.id);
                if (index > -1) {
                    checkedList.splice(index, 1);
                }
            }
        } else {
            if(event.target.checked){
                props?.bulkQuoteList?.forEach(obj => {
                    if(obj.bq_desc === "SUCC") {
                        if (!checkedList.includes(obj.ref_id)) {
                            checkedList.push(obj.ref_id);
                        }
                        document.getElementById(obj.ref_id).checked = true;
                    }
                });
                logger.info("checking the all orderable checkbox");
            } else {
                checkedList = []; //clearing any checkboxes
                props?.bulkQuoteList?.forEach(obj => {
                    if(obj.bq_desc === "SUCC") {
                        document.getElementById(obj.ref_id).checked = false;
                    }
                });
                logger.info("unchecking the all orderable checkbox");
            }
        }
        logger.info(`Checked List: ${JSON.stringify(checkedList)}`);
        // Passing the checked list to the parent component BulkQuoteView.js
        props.handleCheckboxes(checkedList);
    }

    return (

        <div className={`row ${style.rowGrp} mt-3`}>
            <div className={`${style.contentQueryPage} `}>
                <div className={`tableWrap bulkQuoteSummaryTableWrap`}>
                    <div className={`rel pagination-table page-list`}>
                        <BootstrapTable
                            id="bulkTable"
                            keyField="ref_id"
                            classes={`table-fixed table table-hover table-striped text-center`}
                            data={props.bulkQuoteList}
                            columns={columns}
                            bootstrap4
                            striped
                            hover
                            bordered={false}
                            pagination={paginationFactory({
                                sizePerPage: 10
                            })}
                            filter={filterFactory()}
                            loading={true}
                            className="mb-0"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BroadbandBulkView;
