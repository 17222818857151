import React from 'react';

const NotificationUpdaterTabs = ({ activeTab, setActiveTab }) => {
    return (
        <div className="tab-nav cf">
            <a href className={`tab-opt ${activeTab === 'information' ? 'active' : ''}`} onClick={() => setActiveTab('information')}>
                <span>Information</span>
            </a>
            <a href className={`tab-opt ${activeTab === 'message' ? 'active' : ''}`} onClick={() => setActiveTab('message')}>
                <span>Message</span>
            </a>
        </div>
    );
}

export default NotificationUpdaterTabs;