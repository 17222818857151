import axios from "./axios/customAxios";
import { ConsoleLogger } from "../logger/ConsoleLogger";

const apiURL = `${window.location.origin}/assets/json`; //api path URL
const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });

const localURL = "iconnect-local.windstreamwholesale.com";
const devURL = "iconnect-dev.windstreamwholesale.com";

const getTicketContacts = async () => {
	const contactsURL = `${process.env.REACT_APP_API_URL}/TroubleTickets.php?action=contact`;
	return await axios.get(contactsURL);
}

const getTicketContactInfo = async (value) => {
	const autoFillURL = `${process.env.REACT_APP_API_URL}/TroubleTickets.php?action=contacts&data=${value}`;
	return await axios.get(autoFillURL);
}

const verifyTicket = async (circuit) => {
	const verifyTicketURL = `${process.env.REACT_APP_API_URL}/verifyTicket.php?cid=${circuit}`;
	logger.info(verifyTicketURL);
	return await axios.get(verifyTicketURL);
}

const createTicket = async (ticketValues) => {
	let createTicketURL = `${process.env.REACT_APP_API_URL}/submitTicket.php`;
	if (window.location.hostname === localURL || window.location.hostname === devURL) {
		// For local or dev, use GET request
		createTicketURL += `?value=${encodeURIComponent(JSON.stringify(ticketValues))}`;
		return await axios.get(createTicketURL);
	} else {
		// For uat and production, use POST request
		return await axios.post(createTicketURL, ticketValues);
	}
};


const getCircuitValues = async (circuitId) => {
	const circuitValuesURL = `${process.env.REACT_APP_API_URL}/TroubleTickets.php?action=circuitAddress&circuitId=${circuitId}`;
	return await axios.get(circuitValuesURL);
}

const getTroubleProducts = async () => {
	const url = `${apiURL}/troubleProducts.json`;
	return await axios.get(url);
}

const getTroubleTypeOptions = async () => {
	const url = `${apiURL}/troubleTypeOptions.json`;
	return await axios.get(url);
}

const getTroubleDetails = async () => {
	const url = `${apiURL}/troubleDetails.json`;
	return await axios.get(url);
}

const getTroubleContactPrimary = async () => {
	const url = `${apiURL}/troubleContactPrimary.json`;
	return await axios.get(url);
}

const getTroubleContactLocal = async () => {
	const url = `${apiURL}/troubleContactLocal.json`;
	return await axios.get(url);
}

const getTroubleContactNew = async () => {
	const url = `${apiURL}/troubleContactNew.json`;
	return await axios.get(url);
}

const getTroubleAuthorization = async () => {
	const url = `${apiURL}/troubleAuthorization.json`;
	return await axios.get(url);
}

const TroubleTicketData = {
	getTicketContacts,
	getTicketContactInfo,
	getCircuitValues,
	verifyTicket,
	createTicket,
	getTroubleProducts,
	getTroubleTypeOptions,
	getTroubleDetails,
	getTroubleContactPrimary,
	getTroubleContactLocal,
	getTroubleContactNew,
	getTroubleAuthorization
}

export default TroubleTicketData
