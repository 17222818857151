import axios from "./axios/customAxios";

const RfoRcaRequest = async (values) => {
	const requestURL = `${process.env.REACT_APP_API_URL}/rfoRcaSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(requestURL);
}

const SupportHelper = {
	RfoRcaRequest
}

export default SupportHelper;