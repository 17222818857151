import React, { Fragment, useEffect, useState } from "react";
import style from "./TicketCreate.module.css";
import Select from "react-select";
import TroubleTicketData from "../../services/TicketCreateService";
import Loader from "../shared/Loader";
import { connect, useSelector } from "react-redux";
import { tcCircuit, tcCircuitSearch, tcCircuitAddress, tcErrorMsg, tcOptions, tcProducts } from "../../redux";
import { Redirect } from "react-router-dom";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import TicketRedirectModal from "./TicketRedirectModal";

const TicketCreateProducts = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const troubleTypeOption = props.products;
	const productType = props.productSubOption;
	const troubleDetailsControls = props.troubleDetailsData;
	const circuitList = props.circuitData;

	//Local useState Variables
	const [productTypeFilter, setProductTypeFilter] = useState([]);
	const [incidentList, setIncidentList] = useState("");
	const [loader, setLoader] = useState(false);
	const [hasError, setHasError] = useState(null);
	const [contactPopup, setContactPopup] = useState(false);
	const [ticketCreateRedirect, setTicketCreateRedirect] = useState(false);
	const [ticketViewRedirect, setTicketViewRedirect] = useState(false);
	const [incident_number, setIncident_number] = useState("");

	//Redux Store Values
	const selectedCircuitSearch = useSelector(state => state.ticketCreate?.selectedCircuitSearch);
	const storeTicketCreate = useSelector(state => state.ticketCreate);
	const findCircuitValue = useSelector(state => state.ticketCreate?.selectedCircuitValue);
	const selectedproduct = useSelector(state => state.ticketCreate?.selectedProduct);
	const productSubOptionSelected = useSelector(state => !!state.ticketCreate?.selectedProductType?.id);


	useEffect(() => {

		if (selectedproduct?.id && productType.length > 0) {
			filterProductType(productType, selectedproduct);
		} else {
			setProductTypeFilter([])
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productSubOptionSelected, productType, selectedproduct]);

	useEffect(() => {
		if (incidentList.length > 0) {
			setContactPopup(true);
		}
	}, [incidentList]);
	//Sets the Product
	const productSelect = (selectedProduct) => {
		props.updateProduct(selectedProduct);
		props.updatedProductType({});
		filterProductType(productType, selectedProduct)
	}

	const filterProductType = (pType, productOption) => {
		let filterProduct = pType.filter(PT => PT.pid === productOption.id);
		setProductTypeFilter(filterProduct);
	}

	//Sets the Trouble Type
	const productSubType = (selectedPT) => {
		props.updateErrorMsg("");

		/*Determining which fields are mandatory based on trouble type selected*/
		for (let i = 0; i < troubleDetailsControls.length; i++) {
			if (troubleDetailsControls[i].dbId === selectedPT.dbId) {
				troubleDetailsControls[i].mandatory = true;

			} else {
				if (troubleDetailsControls[i].dbId) {
					troubleDetailsControls[i].mandatory = false;
				}
			}
		}

		//Overide for Number of Sites since it is a mandatory field among different types
		if (selectedPT.dbId === 5 || selectedPT.dbId === 6 || selectedPT.dbId === 7) {
			troubleDetailsControls[18].mandatory = true;
		}
		props.updatedProductType(selectedPT);
	}

	//Calls the validate ticket "ticket api" which returns a list incident numbers if a ticket exists.
	const validateTroubleTicket = async (event) => {
		setLoader(true);
		logger.info(event.label);
		props.updatedCircuitValue(event);
		TroubleTicketData.verifyTicket(event.label).then(response => {
			if (response?.data?.length > 0) {
				setLoader(false);
				logger.info(response.data);
	
				let incidents = response.data.map((obj) => {
					return obj.incidentNumber
				});
				
				logger.info(JSON.stringify(incidents));
				setIncidentList(incidents);
			} else {
				setLoader(false);
				props.updateErrorMsg("");
			}
		}).catch(error => {
			logger.error("verifyTicket ERROR: " + error)
			setHasError(error);
			setLoader(false);
			props.updateErrorMsg(JSON.stringify(error["message"]));
		})

	}

	//Sets the circuit id and determines addition information based on circuit selected
	const findCircuit = (event) => {
		let circuitVal = "";
		if (!event) {
			circuitVal = ""
		} else {
			circuitVal = event.label;
		}
		if (circuitVal) {
			props.updatedCircuitValue(event);
			props.updateErrorMsg("");
			validateTroubleTicket(event);
		}

		setLoader(true);
		TroubleTicketData.getCircuitValues(circuitVal).then(resp => {
			props.updatedCircuitAddress({
				ckt_gaid: resp.data.gaid,
				ckt_designId: resp.data.designId,
				ckt_type: resp.data.type,
				ckt_status: resp.data.status,
				ckt_name: resp.data.name
			});
			setLoader(false);
		}).catch(error => {
			logger.error("getCircuitValues ERROR: " + error)
			setHasError(error);
			setLoader(false);
			props.updateErrorMsg(JSON.stringify(error["message"]));
		});

	}

	const dupTicketPopupEmit = (action, incident) => {
		if (action === "Yes") {
			if (incident !== "" && incident !== undefined) {
				setIncident_number(incident);
				setTicketViewRedirect(true);
				setTicketCreateRedirect(false);
			}
		} else if ("No") {
			setTicketViewRedirect(false);
			setTicketCreateRedirect(true);
		}
		setContactPopup(false);
	}

	if (ticketViewRedirect) {
		logger.info(`incident_number: ${incident_number}`)
		return <Redirect to={`/Network/TicketDetails/${incident_number}`} />
	}

	if (ticketCreateRedirect) {
		/* Intentionally left empty  so that the user can continue and create a ticket*/
		logger.info("nothing to do...");
	}

	const customCircuitIdFilter = (option, searchText) => {
		let sanitizedSearchText = searchText.replace(/\W/g, "").toUpperCase();
		return option.value.includes(sanitizedSearchText);
	}

	return (<>
		{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
		<div className={`step1 ${style.rowGap}`}>
			{loader ? <Loader /> : null}
			{!hasError && (<div className="text-center">
				{troubleTypeOption.map((tto, i) => <Fragment key={i}>
					<div
						className={` ${style.productThumb} ${storeTicketCreate?.selectedProduct?.id === tto.id ? style.selected : storeTicketCreate?.selectedProduct?.id === undefined ? "" : style.notSelected}  `}
						key={i + "_optionType"} onClick={() => {
							productSelect(tto)
						}}>
						<h1 className={` ${style.productLink} `}>
							{tto.name}
						</h1>
						<div className={` ${style.productType} `}>
						</div>

						<img alt={tto.productName} src={`../../images/product_icons/${tto.code}.svg `}
							className={style.productImg} />

						<svg className={`ico ${style.ptCheck}`}>
							<path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path>
						</svg>

					</div>
				</Fragment>)}

			</div>)}

			{!hasError && (<div className={` subProduct my-3 text-center ${style.fWrap}`}>
				<>
					{storeTicketCreate?.selectedProduct?.id !== undefined ? <div className="text-left">
						<div className="sub-hdr alt3">
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2>Trouble Experienced</h2>
							{/* <p>Please select all of the problems you are experiencing:</p> */}
						</div>

						<div className="sub-section">
							<p>Please select the problem you are experiencing:</p>
						</div>
					</div> : null}
					{productTypeFilter.map((ptf, i) => <div className={`productSubTypeGroup ${style.subGrp} `}
						key={i + "subType"}>
						<button
							className={` d-block ${storeTicketCreate?.selectedProductType?.dbId === ptf.dbId ? style.selected : ""}`}
							onClick={() => {
								productSubType(ptf)
							}}>
							<svg className={`ico ${style.configOptIco} `}>
								<path
									d="M17.76,18.84l9.5-9.46c1.36-.34,3.09-.69,3.09-.69L33.1,3.55,32.51,3,27.37,5.71,26.68,8.8l-9.47,9.5c-.75.74-2.37-.38-1.37-1.38L25.1,7.66,25.55,5c7.21-4.49,7-4.65,7.79-3.87,0,0,1.92,1.92,1.71,2.6l-3.22,6.31c-1.67.49-3.3.9-3.3.9l-9.4,9.26C18.54,20.89,16.93,19.67,17.76,18.84ZM2.46,33.49a5.24,5.24,0,0,1-1.18-5.87,4.82,4.82,0,0,1,1.18-1.68L7.36,21C9.8,21,9.47,20,9.53,18.84l2-1.92a1.11,1.11,0,0,1,1.37,0l6.28,6.28a1,1,0,0,1,0,1.37l-1.92,2c-1.8,0-2,.13-2.19,2.16l-4.91,4.8C11.09,32.42,6.83,37.85,2.46,33.49ZM8.39,22.62,3.83,27.21a3.47,3.47,0,0,0,4.91,4.91l4.56-4.56a3.19,3.19,0,0,1,3-3l.93-.92-4.94-4.91-.89.93A3.12,3.12,0,0,1,8.39,22.62ZM18.1,11.44,16.59,9.86C17.84,1,9.81.43,7.81,1.25a1,1,0,0,0-.45,1.61l3,2.95L8,8.11,5.07,5.26c.42.43-1-1.17-1.58.35a6.54,6.54,0,0,0,3,7.88,11.5,11.5,0,0,0,5.54.79l1.48,1.48c2.29.23,1.37-1.37,1.37-1.37L13.44,13c-1.36-1.23-2-.44-3.57-.44C5.85,12.57,5,8,5,8L6.58,9.58c2.27,1.42,5-2.26,5-2.26C13.85,5,10.11,2.86,10.11,2.86c4.62.12,4.7,4.92,4.7,4.91,0,1.37-.79,2.29.45,3.53l1.47,1.51C17.47,13.44,18.81,12.86,18.1,11.44Zm16,15.88-9.7-9.71c-.82-.82-2.48.09-1.38,1.37l9.71,9.71h0c1.12,1.12-.7,2.83-1.82,1.71l-9.74-9.7c-.92-.92-2.4.11-1.37,1.37l9.74,9.7a3.21,3.21,0,0,0,4.65.07A3.08,3.08,0,0,0,34.12,27.32Z"></path>
							</svg>
							<svg className={`ico ${style.configOptCheck} `}>
								<path d="M34.5,7V8l-22,22h-2l-9-9V20l1-1,9,9,22-22Z"></path>
							</svg>
							{ptf.name}
						</button>

					</div>)}
				</>
			</div>)}

			{!hasError && ((storeTicketCreate?.selectedProductType?.dbId && storeTicketCreate?.selectedProduct?.dbId !== 1) ?
				<div>
					<div className="sub-hdr alt1">
						<div className="nib">
							<div className="the-notch">
								<div className="notch-fg">
									<svg>
										<path
											d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
									</svg>
								</div>
							</div>
						</div>
						<h2>Circuit Details</h2>
					</div>
					{Number(props.circuitCount) > 5000 ?
						<div className="d-flex justify-content-center">
							<div className={` col-4 col-sm-4 col-md-2 col-lg-2 ${style.colGrp} mt-4`}>
								<input type='text' className={`${style.inputFld}`} id="searchCircuit" name="searchCircuit" onChange={(ev) => props.updatedCircuitSearch(ev.target.value)} value={selectedCircuitSearch}></input>
								<p className='text-danger'>{`There are ${props.circuitCount} circuits available. Please provide part of the Circuit ID and click search.`}</p>
							</div>
							<div className={` col-1 col-sm-1 col-md-1 col-lg-1 ${style.colGrp}`}>
								<button type="button" className={`ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={() => props.searchCircuitList()}>Search</button>
							</div>
						</div> :  null
					}

					{Number(props.circuitCount) < 5000 ?
						<form>
							<div className="w-25 m-auto">
								<div className="rel">
									<Select
										defaultValue={findCircuitValue}
										onChange={(event) => {
											findCircuit(event)
										}}
										options={circuitList?.map(item => {
											return { value: item.circuit, label:item.circuitId };
										})}
										isSearchable
										filterOption={customCircuitIdFilter}
										noOptionsMessage={() => {
											return <a href="https://customers.windstreamwholesale.com/"
												className="text-danger">Not seeing your CID, click here to search
												the legacy portal</a>
										}} />
								</div>

							</div>
						</form> : null
					}
				</div> : null)}

		</div>
		{contactPopup ?
			<TicketRedirectModal dupTicketPopupEmit={dupTicketPopupEmit} incidentList={incidentList} /> : null
		}
	</>
	)
}

const mapDispatchToProps = {
	updateProduct: tcProducts,
	updatedProductType: tcOptions,
	updatedCircuitValue: tcCircuit,
	updatedCircuitSearch: tcCircuitSearch,
	updatedCircuitAddress: tcCircuitAddress,
	updateErrorMsg: tcErrorMsg
}

export default connect(null, mapDispatchToProps)(TicketCreateProducts);
