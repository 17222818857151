import {useState} from "react";
import style from "./TicketCreate.module.css";

const TicketRedirectModal = (props) => {
    const [incident, setIncident] = useState(props?.incidentList[0]);
    const [error, setError] = useState(false);

    const popupYes = () => {
        if (incident !== "" && incident !== undefined) {
            props.dupTicketPopupEmit("Yes", incident);
        } else {
            setError(true);
        }
    }

    const popupNo = () => {
        props.dupTicketPopupEmit("No");
    }

    return (
        <div>
            <div className="overlay show"></div>
            <div className="modal fade show d-block">
                <div className={` modal-dialog modal-dialog-centered modal-`}>
                    <div className="modal-content">
                        <div className="modal-body">
                            There is an existing ticket on this circuit.
                            <div className="my-3">
                                <label className='labelText'>Incident List<span className={`${style.mandatory}`}>*</span></label>
                                <select className={`nice-select dark-select wide ${style.select}`} value={incident} onChange={(event) => { setIncident(event.target.value); }}>
                                    <option>Select</option>
                                    {
                                        props.incidentList?.map((list, index) => (
                                            <option key={index}>{list}</option>
                                        ))
                                    }
                                </select>
                                <p className={style.errorMsg}>{error}</p>
                            </div>
                            Would you like to access the selected ticket to view the latest updates, add a worklog
                            comment,
                            request a status
                            update, or submit an escalation?
                        </div>

                        <div className="modal-footer d-block text-center">
                            <button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={(event) => {popupYes(event)}}>Yes</button>
                            <button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={(event) => {popupNo(event)}}>No</button>
                            {error ? <p className={"red"}>Please select an incident.</p> : null}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default TicketRedirectModal;