import style from "./QuoteCreate.module.css";
import { useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import DIAPremHelper from "../../services/DIAPremService";
import PremAddress from "./PremAddress";
import {
	setQuoteBroadbandAdsl,
	setQuoteBroadbandCable,
	setQuoteBW,
	setQuoteIps,
	setQuotePopLocation,
	setQuoteQty
} from '../../redux/actions/quoteActions';
import Term from './Term';
import VendorExclusion from './VendorExclusion';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const DIAPremBroadband = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);
	const [hasError, setHasError] = useState(null);
	const [premIpOptions, setPremIpOptions] = useState([]);
	const selectedDIAPremValues = useSelector(state => state.quote.quoteValues);

	useEffect(() => {
		if(props.stepCode === 2){
			DIAPremHelper.getPremIPs(selectedDIAPremValues.circuit).then(resp => {
				logger.info(resp.data);
				setPremIpOptions(resp.data);
			}).catch(error => {
				logger.error(`Error getting DIA Prem IPs: ${error}`);
				setHasError(error);
			})
		}
	}, [logger, props.stepCode, selectedDIAPremValues.circuit])

	const inputChange = (event) => {
		switch (event.target.id) {
			case "bw":
				props.updateDIAPremBw(event.target.value);
				break;
			case "qty":
				props.updateDIAPremQty(event.target.value);
				break;
			case "ips":
				props.updateDIAPremIPs(event.target.value);
				break;
			case "cable":
				props.updateDIAPremCable(event.target.checked);
				break;
			case "adsl":
				props.updateDIAPremAdsl(event.target.checked);
				break;
			default:
				break;
		}
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{!hasError && (<>{props.stepCode === 2 ?
				<>
					{/*BROADBAND*/}
					{selectedDIAPremValues.circuit === 14 ?
						<>
							<div className={` sub-hdr alt2 ${style.subHeader} `}>
								<div className="nib"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={style.sectionTitle}>Term</h2>
							</div>
							<Term />
							<div className={style.dividers}></div>
							<div className={` sub-hdr alt3 ${style.subHeader} `}>
								<div className="nib"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={style.sectionTitle}>Configuration</h2>
							</div>
							<div className="col-5 col-sm-4 col-md-3 rel mb-5">
								<label className={style.labelSelectRequiredFld}> Access Type <span className={style.mandatory}>*</span></label>
								<div>
									<input type="checkbox" id="cable" name="cable" onChange={inputChange} checked={selectedDIAPremValues.cable ? "checked" : ""} />
									<label htmlFor="cable" className={` ${style.checkboxText} `}>CABLE</label>
									<input type="checkbox" id="adsl" name="adsl" onChange={inputChange} checked={selectedDIAPremValues.adsl ? "checked" : ""} />
									<label htmlFor="adsl" className={` ${style.checkboxText} `}>ADSL</label>
								</div>
							</div>

							<div className="col-5 col-sm-4 col-md-3 rel mb-5">
								<label className={style.labelSelectRequiredFld}> IP addresses <span className={style.mandatory}>*</span></label>
								<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='ips' onChange={inputChange} value={selectedDIAPremValues.ips}>
									{premIpOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>
							<div className={style.dividers}></div>

							{/*Location A Header Location A Header*/}
							<div className={`sub-hdr alt ${style.subHeader} `}>
								<div className="nib"><div className="the-notch"><div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div></div></div><h2 className={style.sectionTitle}>Location A</h2>
							</div></>

						: null}
						<PremAddress routeToIlecBroadband={() => props.ilecBroadband()}></PremAddress> </> : null
			}

				{(props.stepCode === 3) && (props.section === "bandwidth") && (selectedDIAPremValues.circuit === 14) ?
					<div className={style.bandwidthWrap}>
						<div className={`row ${style.rowGrp} `}>
							<div className="col-6 col-sm-4 col-md-3">
								<label className={style.labelSelectRequiredFld}>Bandwidth <span className={style.mandatory}>*</span></label>
								<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw"
									onChange={inputChange} value={selectedDIAPremValues.bw}>
									{selectedDIAPremValues.broadbandBWOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>
						</div>
					</div> : null
				}
				{(props.stepCode === 3) && (props.section === "productConfig") ?
					<>
						<div className="col-12 col-sm-4 col-md-3 mb-5">
							<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' onChange={inputChange} value={selectedDIAPremValues.qty}>
								<option value="">-</option>
								<option value="1">1</option>
							</select>
						</div>
					</> : null}
				{(props.stepCode === 3) && (props.section === "Options") ?
					<VendorExclusion />
					: null}</>)}

		</>
	)
}

const mapDispatchToProps = {
	updateDIAPremLocation: setQuotePopLocation,
	updateDIAPremBw: setQuoteBW,
	updateDIAPremQty: setQuoteQty,
	updateDIAPremIPs: setQuoteIps,
	updateDIAPremCable: setQuoteBroadbandCable,
	updateDIAPremAdsl: setQuoteBroadbandAdsl
}

export default connect(null, mapDispatchToProps)(DIAPremBroadband);
