import React, {useEffect} from 'react'
import { clearReduxStore } from "../../redux/actions/userActions";
import { connect } from 'react-redux';

const Logout = (props) => {

	const handleLogout = () => {
		localStorage.clear();
		props.clearStore();
		window.location.href = `${process.env.REACT_APP_LOGIN_URL}/login.php?action=logout`;
    }

    useEffect(() => {
        handleLogout();
    },[])

    return (
        <>
            <div className="page login-page">
                <div className="page-title text-center my-5 rel z9">
                    <img alt="iconnect-logo" src="../../images/iconnect-logo-light-tag.png" />
                </div>
                <div className="login-form rel z9">
                    <div className="my-3 text-center">
                        <h4>Logging Out</h4>
                        <h6>Thank you for using iconnect</h6>
                        <span>If you have not be redirected in 5 seconds please click below.</span>
                        <button type="button" className="login-btn" onClick={handleLogout}>
                            <span>Complete Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapDispatchToProps = {
	clearStore: clearReduxStore
}

export default connect(null, mapDispatchToProps)(Logout);
