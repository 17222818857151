import style from "./QuoteCreate.module.css";
import { useEffect, useMemo, useState } from "react";
import HomeData from '../../services/HomeServices';
import Loader from "../shared/Loader";
import { connect, useSelector } from "react-redux";
import {
	setLocAAddress,
	setLocACity,
	setLocAEAccessValues,
	setLocAFloorNum,
	setLocAState,
	setLocAStruct,
	setLocAStructNum,
	setLocAUnit,
	setLocAUnitNum,
	setLocAValidationFlag,
	setLocAZip,
	setLocAOverride,
	setLocALatitude,
	setLocALongitude,
	setLocALecType,
} from '../../redux/actions/quoteLocAActions';
import {
	setQuoteProduct,
	setQuoteProductCode,
	setQuoteCircuitType
} from '../../redux/actions/quoteActions';
import { setQuoteAddressA, setQuoteBamKey, setQuoteBroadbandBWOptions } from '../../redux/actions/quoteActions';
import DIAPremHelper from "../../services/DIAPremService";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CustomPopRules from "./CustomPopRules";
import {
	setQuotePopLocation
} from '../../redux/actions/quoteActions';
import Popup from "../shared/Popup";

const PremAddress = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const [hasError, setHasError] = useState(null);
	const [avloader, setAvLoader] = useState(false) // AV loader
	const [stateList, setStateList] = useState([]); // build states list
	const [addErrorMessage, setAddErrorMessage] = useState("");
	const [stateErrorMessage, setStateErrorMessage] = useState("");
	const [cityErrorMessage, setCityErrorMessage] = useState("");
	const [zipErrorMessage, setZipErrorMessage] = useState("");
	const [latErrorMessage, setLatErrorMessage] = useState("");
	const [longErrorMessage, setLongErrorMessage] = useState("");
	const [structNumErrorMessage, setStructNumErrorMessage] = useState("");
	const [floorNumErrorMessage, setFloorNumErrorMessage] = useState("");
	const [unitNumErrorMessage, setUnitNumErrorMessage] = useState("");
	const [ld2Val, setld2Val] = useState("");
	const [addressLoaded, setAddressLoaded] = useState(false);
	const [validAddress, setValidAddress] = useState("");
	const [validationErrorMessage, setValidationError] = useState("");
	const [ilecErrorFlag, setILECErrorFlag] = useState(false);
	const [wavePopCheckFlag, setWavePopCheckFlag] = useState(false);
	const [nobid, setNobid] = useState(false);
	const [waveMultiPopList, setWaveMultiPopList] = useState([]);
	const [showPopup, setShowPopup] = useState(false);
	const [popUpMessage, setPopupMessage] = useState("");

	const selectedQuoteValues = useSelector(state => state.quote.quoteValues);
	const selectedLocAValues = useSelector(state => state.quoteLocA.locA);
	const google = window.google = window.google ? window.google : {};

	const fillInAddress = (ac) => {
		var place = ac.getPlace();
		props.updateLocAAddress(place.formatted_address);
		for (var x = 0; x < place.address_components.length; x++) {
			switch (place.address_components[x].types[0]) {
				case 'locality':
					props.updateLocACity(place.address_components[x].short_name);
					break;
				case 'administrative_area_level_1':
					props.updateLocAState(place.address_components[x].short_name);
					break;
				case 'postal_code':
					props.updateLocAZip(place.address_components[x].short_name);
					break;
				default:
					break;
			}
		}
		logger.info(place);
		return place;
	}

	const initAutocomplete = () => {
		const autocomplete = new google.maps.places.Autocomplete(document.getElementById('a_add'), { types: ['geocode'] });
		google.maps.event.addListener(autocomplete, 'place_changed', function () {
			fillInAddress(autocomplete);
		});
	}

	const addressHandler = (event) => {
		switch (event.target.id) {
			case 'a_add':
				setAddErrorMessage("");
				props.updateLocAAddress(event.target.value);
				break;
			case 'a_ld1':
				props.updateLocAStruct(event.target.value);
				break;
			case 'a_lv1':
				setStructNumErrorMessage("");
				props.updateLocAStructNum(event.target.value);
				break;
			case 'a_ld2':
				setld2Val(event.target.value);
				break;
			case 'a_lv2':
				setFloorNumErrorMessage("");
				props.updateLocAFloorNum(event.target.value);
				break;
			case 'a_ld3':
				props.updateLocAUnit(event.target.value);
				break;
			case 'a_lv3':
				setUnitNumErrorMessage("");
				props.updateLocAUnitNum(event.target.value);
				break;
			case 'a_city':
				setCityErrorMessage("");
				props.updateLocACity(event.target.value);
				break;
			case 'a_state':
				setStateErrorMessage("");
				props.updateLocAState(event.target.value);
				break;
			case 'a_zip':
				setZipErrorMessage("");
				props.updateLocAZip(event.target.value);
				break;
			case 'a_lat':
				props.updateLocALatitude(event.target.value);
				setLatErrorMessage("");
				break;
			case 'a_long':
				props.updateLocALongitude(event.target.value);
				setLongErrorMessage("")
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		initAutocomplete();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(() => {
		// Get the list of all the states
		HomeData.getStateList().then(resp => {
			setStateList(resp.data);
		}).catch((error) => {
			logger.error("getStates ERROR: " + error)
			setHasError(error);
		})
	}, [logger]);

	// This is called when clicking to validate Address
	const validateAddress = () => {
		setAvLoader(true);
		setAddressLoaded(false);

		if (!selectedLocAValues.a_add) {
			setAddErrorMessage('Address is required');
		}
		if (selectedLocAValues.a_structNum && selectedLocAValues.a_structNum.length > 10) {
			setStructNumErrorMessage('Only 10 chars allowed')
		}
		if (selectedLocAValues.a_floorNum && selectedLocAValues.a_floorNum.length > 10) {
			setFloorNumErrorMessage('Only 10 chars allowed')
		}
		if (selectedLocAValues.a_unitNum && selectedLocAValues.a_unitNum.length > 10) {
			setUnitNumErrorMessage('Only 10 chars allowed')
		}
		if (!selectedLocAValues.a_city) {
			setCityErrorMessage('City is required');
		}
		if (!selectedLocAValues.a_state) {
			setStateErrorMessage('State is required');
		}
		if (!selectedLocAValues.a_zip) {
			setZipErrorMessage('Zip is required')
		}
		if (selectedLocAValues.a_override) {
			if (!selectedLocAValues.a_lat) {
				setLatErrorMessage("Latitude is required");
			}
			if (!selectedLocAValues.a_long) {
				setLongErrorMessage("Longitude is required");
			}
		}

		let addressData = {
			'address': selectedLocAValues.a_add,
			'ld1': selectedLocAValues.a_struct,
			'lv1': selectedLocAValues.a_structNum,
			'ld2': ld2Val,
			'lv2': selectedLocAValues.a_floorNum,
			'ld3': selectedLocAValues.a_unit,
			'lv3': selectedLocAValues.a_unitNum,
			'city': selectedLocAValues.a_city,
			'state': selectedLocAValues.a_state,
			'zip': selectedLocAValues.a_zip,
			'override': selectedLocAValues.a_override,
			'lat': selectedLocAValues.a_lat,
			'lon': selectedLocAValues.a_long
		};
		//build something to send to AV
		if (selectedQuoteValues.circuit === 14) {
			let terms = {
				"term1": selectedQuoteValues.term1,
				"term2": selectedQuoteValues.term2,
				"term3": selectedQuoteValues.term3,
				"term4": selectedQuoteValues.term4,
				"term5": selectedQuoteValues.term5
			};

			DIAPremHelper.getBroadbandBW(selectedQuoteValues.adsl, selectedQuoteValues.cable, selectedQuoteValues.ips, terms, addressData).then(resp => {
				setAvLoader(false);
				logger.info(resp.data);
				if (resp.data.error) {
					logger.info("Address NOT VALID");
					setValidAddress(false);
					props.updateLocAValidAddress(false);
				} else {
					logger.info(`response: ${JSON.stringify(resp.data)}`)
					props.updateBroadbandBWOptions(resp.data.bandwidthOptions);
					props.updateQuoteBamKey(resp.data.bamKey);
					if(resp.data?.lec_type === "ILEC") {
						setPopupMessage(`The address you have entered is within Windstream's territory. Would you like to proceed with our Broadband offering?`);
						setShowPopup(true);
						setValidAddress("");
					} else {
						setValidAddress(true);
						props.updateLocAValidAddress(true);
						setAddressLoaded(true);
					}
				}
			}).catch(error => {
				logger.error('getBroadbandBW Error: ' + error);
				setHasError(error);
				setAvLoader(false);
			})
		} else {
			HomeData.validateAddressResult(addressData, selectedQuoteValues.product).then(resp => {
				logger.info('Response: ' + JSON.stringify(resp.data));
				if (selectedQuoteValues.product !== 20) {
					setAddressLoaded(true);
					setAvLoader(false);
				}
				if (selectedQuoteValues.product === 20 && resp.data.av_result) {
					//ADO-904946 Due to an issue with EACCESS validation we need to call this twice to make sure everything we need gets set in the php session
					HomeData.validateAddressResult(addressData, selectedQuoteValues.product).then(resp => {
						props.updateLocAEAccessValues(resp.data);
						logger.info("eaccessValidation is a true");
						setValidAddress(true);
						props.updateLocAValidAddress(true);
						setAddressLoaded(true);
						setAvLoader(false);
					}).catch(error => {
						logger.error(`Error calling EACCESS validation twice: ${error}`);
						setHasError(error);
					})
				} else if (resp.data.av_result) {
					if (resp.data?.nobid) {
						setValidAddress(false);
						props.updateLocAValidAddress(false);
						setValidationError(resp.data.av_message);
						setNobid(true);
					} else {
						setValidAddress(true);
						props.updateLocAValidAddress(true);
						props.updateLocALecType(resp.data.lec_type);
					}
					//ADO-663156 Wave Pop Query for existing pop locations for WAVE and ADO-742923 Colocation
					if ((selectedQuoteValues.product === 1 || selectedQuoteValues.product === 15) && resp.data?.pop_return_list?.length > 0) {
						if (resp.data.pop_return_list[0].autoselect === true) {
							if (selectedQuoteValues.product === 15) {
								props.updateColocationLocation({ value: resp.data.pop_return_list[0].id, label: resp.data.pop_return_list[0].address });
							} else {
								props.updateWavePopAddressA({ value: resp.data.pop_return_list[0].id, label: resp.data.pop_return_list[0].address });
							}
						} else {
							setWavePopCheckFlag(true);
							setWaveMultiPopList(resp.data.pop_return_list);
						}
					}
				} else {
					if (resp.data.ilec) {
						setValidationError(resp.data.av_message);
						setValidAddress(false);
						setILECErrorFlag(true);
						props.updateLocAValidAddress(false);
					} else {
						setILECErrorFlag(false);
						setValidAddress(false);
						props.updateLocAValidAddress(false);
					}
				}
			}).catch(error => {
				logger.error('validateAddressResult Error: ' + error);
				setHasError(error);
			})
		}
	};

	const togglePopup = (action) => {
		setShowPopup(!showPopup);
		if(action === "YES"){
			props.updateQuoteProduct(23);
			props.updateQuoteCircuitType(13);
			props.updateQuoteProductCode("BROADBAND");
			//this is a prop passed from DIAPremBroadband
			props.routeToIlecBroadband();
		} else {
			logger.info("Clearing the current address input to allow the user to re-enter an address, ensuring it can be validated for CLEC eligibility.");
			props.updateLocAAddress("");
			props.updateLocAStruct("");
			props.updateLocAStructNum("");
			props.updateLocAFloorNum("");
			props.updateLocAUnit("");
			props.updateLocAUnitNum("");
			props.updateLocACity("");
			props.updateLocAState("");
			props.updateLocAZip("");
			props.updateLocAOverride("");
			props.updateLocALongitude("");
			props.updateLocALatitude("");
			props.updateLocALecType("");
			props.updateLocAValidAddress(false);
			setValidAddress("");
		}
	};


	return (

		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{avloader ? <Loader /> : null}
			{!hasError && (<>{isEmployee || (selectedQuoteValues.product === 29) || (validAddress === false && !nobid) ?
				<div className={`row ${style.rowGrp} `}>
					<div className="col-12 col-sm-4 col-md-4">
						<input type="checkbox" id="override" name="override" onChange={() => { props.updateLocAOverride(!selectedLocAValues.a_override) }} checked={selectedLocAValues.a_override ? "checked" : ""} />
						<label htmlFor="override" className={` ${style.checkboxText} `}>New Construction / Override</label>
					</div>
				</div>
				: null}
				{selectedLocAValues.a_override ?
					<div className={`row ${style.rowGrp} `}>

						<div className="col-12 col-sm-4 col-md-2">
							<label className={style.lableTextS}>Latitude <span className={style.mandatory}>*</span></label>
							<input type="text" className={style.inputFld} id='a_lat' onChange={addressHandler} value={selectedLocAValues.a_lat} />
							{latErrorMessage ?
								<div class="invalid-feedback d-block">
									{latErrorMessage}
								</div> : null}
						</div>
						<div className="col-12 col-sm-4 col-md-2">
							<label className={style.lableTextS}>Longitude <span className={style.mandatory}>*</span></label>
							<input type="text" className={style.inputFld} id='a_long' onChange={addressHandler} value={selectedLocAValues.a_long} />
							{longErrorMessage ?
								<div class="invalid-feedback d-block">
									{longErrorMessage}
								</div> : null}
						</div>
					</div> : null
				}

				<div className={`row ${style.rowGrp} `}>
					<div className="col-12 col-sm-4 col-md-4">
						<label className={style.lableTextS}>Address <span className={style.mandatory}>*</span></label>
						<input type="text" className={style.inputFld} id="a_add" onChange={addressHandler} value={selectedLocAValues.a_add} />

						{addErrorMessage ?
							<div class="invalid-feedback d-block">
								{addErrorMessage}
							</div> : null}
					</div>
				</div>


				<div className={`row ${style.rowGrp} ${style.alignFlexEnd}`}>
					<div className="col-6 col-sm-4 col-md-2">
						<label className={style.labelSelectRequiredFld}>Structure</label>
						<select className={` form-control  ${style.selectFld} `} id="a_ld1" onChange={addressHandler} value={selectedLocAValues.a_struct}>
							<option value="">-</option>
							<option value="BLDG">BLDG</option>
							<option value="WNG">WNG</option>
							<option value="PIER">PIER</option>

						</select>
					</div>
					<div className="col-6 col-sm-4 col-md-1">
						<input type="text" className={style.inputFld} id="a_lv1" onChange={addressHandler} value={selectedLocAValues.a_structNum} />
						{structNumErrorMessage ?
							<div class="invalid-feedback d-block">
								{structNumErrorMessage}
							</div> : null}
					</div>
					<div className="col-6 col-sm-4 col-md-2">
						<label className={style.labelSelectRequiredFld}>Floor</label>
						<select defaultValue={'FL'} className={` form-control  ${style.selectFld} `} >
							<option value="FL">FL</option>
						</select>
					</div>
					<div className="col-6 col-sm-4 col-md-1">
						<input type="text" className={style.inputFld} id='a_lv2' onChange={addressHandler} value={selectedLocAValues.a_floorNum} />
						{floorNumErrorMessage ?
							<div class="invalid-feedback d-block">
								{floorNumErrorMessage}
							</div> : null}
					</div>
					<div className="col-12 col-sm-4 col-md-2">
						<label className={style.labelSelectRequiredFld}>Unit</label>
						<select className={` form-control  ${style.selectFld} `} id='a_ld3' onChange={addressHandler} value={selectedLocAValues.a_unit}>
							<option value="">-</option>
							<option value="APT">APT</option>
							<option value="RM">RM</option>
							<option value="LOT">LOT</option>
							<option value="SLIP">SLIP</option>
							<option value="UNIT">UNIT</option>
							<option value="SUIT">SUITE</option>
						</select>
					</div>
					<div className="col-6 col-sm-4 col-md-1">
						<input type="text" className={style.inputFld} id='a_lv3' onChange={addressHandler} value={selectedLocAValues.a_unitNum} />
						{unitNumErrorMessage ?
							<div class="invalid-feedback d-block">
								{unitNumErrorMessage}
							</div> : null}
					</div>
				</div>
				<div className={`row ${style.rowGrp}`}>
					<div className="col-12 col-sm-4 col-md-2">
						<label className={style.lableTextS}>City <span className={style.mandatory}>*</span></label>
						<input type="text" className={style.inputFld} id='a_city' onChange={addressHandler} value={selectedLocAValues.a_city} />
						{cityErrorMessage ?
							<div class="invalid-feedback d-block">
								{cityErrorMessage}
							</div> : null}
					</div>
					<div className="col-12 col-sm-4 col-md-2">
						<label className={style.labelSelectRequiredFld}>State <span className={style.mandatory}>*</span></label>
						<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='a_state' onChange={addressHandler} value={selectedLocAValues.a_state}>
							{stateList.map((obj) => {
								return <option value={obj.id}>{obj.name}</option>
							})}
						</select>
						{stateErrorMessage ?
							<div class="invalid-feedback d-block">
								{stateErrorMessage}
							</div> : null}
					</div>
					<div className="col-12 col-sm-4 col-md-2">
						<label className={style.lableTextS}>ZIP <span className={style.mandatory}>*</span></label>
						<input type="text" className={style.inputFld} id='a_zip' onChange={addressHandler} value={selectedLocAValues.a_zip} />
						{zipErrorMessage ?
							<div class="invalid-feedback d-block">
								{zipErrorMessage}
							</div> : null}
					</div>
					<div className="col-6 col-sm-4 col-md-1">
						<button type="button" className="btn-style btn-theme" onClick={validateAddress}>Validate</button>
						{addressLoaded ?
							<>
								{validAddress ?
									<div class="valid-feedback d-block">
										Validated
									</div> :
									<>
										{ilecErrorFlag ?
											<div class="invalid-feedback d-block">
												<a href="https://customers.windstreamwholesale.com/">{validationErrorMessage}</a>
											</div> :
											<>
												{!nobid ?
													<div class="invalid-feedback d-block">
														Invalid
													</div> : null
												}
											</>
										}
									</>
								}
								{nobid ?
									<div class="invalid-feedback d-block">
										{validationErrorMessage}
									</div> : null
								}
							</> : null}
					</div>
					{wavePopCheckFlag ?
						<CustomPopRules pop_list={waveMultiPopList} type={"locA"} product={selectedQuoteValues.product} /> : null
					}
				</div>
				<Popup message={popUpMessage} show={showPopup} onClose={(action) => togglePopup(action)} />
				</>)}</>
	);
}

const mapDispatchToProps = {
	updateQuoteProduct: setQuoteProduct,
	updateQuoteProductCode: setQuoteProductCode,
	updateQuoteCircuitType: setQuoteCircuitType,
	updateLocAAddress: setLocAAddress,
	updateLocAStruct: setLocAStruct,
	updateLocAStructNum: setLocAStructNum,
	updateLocAFloorNum: setLocAFloorNum,
	updateLocAUnit: setLocAUnit,
	updateLocAUnitNum: setLocAUnitNum,
	updateLocACity: setLocACity,
	updateLocAState: setLocAState,
	updateLocAZip: setLocAZip,
	updateLocAOverride: setLocAOverride,
	updateLocALatitude: setLocALatitude,
	updateLocALongitude: setLocALongitude,
	updateLocALecType: setLocALecType,
	updateLocAValidAddress: setLocAValidationFlag,
	updateLocAEAccessValues: setLocAEAccessValues,
	updateBroadbandBWOptions: setQuoteBroadbandBWOptions,
	updateQuoteBamKey: setQuoteBamKey,
	updateWavePopAddressA: setQuoteAddressA,
	updateColocationLocation: setQuotePopLocation
}

export default connect(null, mapDispatchToProps)(PremAddress);
