import { useCallback, useEffect, useState, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import style from './MACD.module.css';
import MACDHelper from '../../services/MACDService';
import {
	setMACDACableFid,
	setMACDADirection,
	setMACDANumOfFibers,
	setMACDANumOfRoutes,
	setMACDBW,
	setMACDCircuitType,
	setMACDDesignInquiryNum,
	setMACDDispatchOtsideWin,
	setMACDEdgeDevice,
	setMACDEndBillingDate,
	setMACDManualCircuit,
	setMACDExistingCircuit,
	setMACDExistingTN,
	setMACDFiberArray,
	setMACDIps,
	setMACDQty,
	setMACDReqSerDate,
	setMACDSpliceArray,
	setMACDTestType,
	setMACDEscortAccessRequest,
} from '../../redux';
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CircuitListHelper from '../../services/CircuitListService';

const MACDServiceInfo = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);

	//State Variables
	const [hasError, setHasError] = useState(null);
	const [circuitTypeOptions, setCircuitTypeOptions] = useState([]);
	const [bandwidthOptions, setBandwidthOptions] = useState([]);
	const [existingCircuitOptions, setExistingCiruitOptions] = useState([]);
	const [routeCount, setRouteCount] = useState(1);
	const [loader, setLoader] = useState(false);
	const [fiberErrorFlag, setFiberErrorFlag] = useState(false);
	const [qty, setQty] = useState(1);

	//Redux Store Variables
	const selectedCircuitSearch = useSelector(state => state?.MACDOrder.circuitSearch);
	const selectedMainCircuit = useSelector(state => state?.MACDOrder.selectedCircuitDetails);
	const selectedCircuitGaid = useSelector(state => state?.MACDOrder.circuitGaid);
	const selectedMACDProduct = useSelector(state => state?.MACDOrder.selectedProduct.productId);
	const selectedMACDServiceInfo = useSelector(state => state?.MACDOrder.ServiceInformation);
	const selectedActivityRequest = useSelector(state => state?.MACDOrder.selectedActivityRequest);
	const selectedActivityType = useSelector(state => state?.MACDOrder.selectedActivityType.name);

	//Fetching Bandwidth Menu from the backend
	const getBandwidthMenu = useCallback(async () => {
		setLoader(true);
		if (selectedMACDServiceInfo.CIRCUIT_TYPE) {
			MACDHelper.getMACDBandwidths(selectedMACDProduct, selectedMACDServiceInfo.CIRCUIT_TYPE).then(response => {
				logger.info(encodeURIComponent(response.data).replace(/%20/g, " "));
				//autoselect option if only 1 bandwidth
				if (response.data?.length === 1) {
					props.BANDWIDTH(response.data[0].id);
				}
				setBandwidthOptions(response.data);
				setLoader(false);
			}).catch(error => {
				logger.error(`Error Getting bandwidth menu: ${error}`);
				setHasError(error);
				setLoader(false);
			})
		}
	}, [logger, props, selectedMACDProduct, selectedMACDServiceInfo.CIRCUIT_TYPE])

	//Fetching Circuit Type Menu from the backend
	const getCircuitTypeMenu = useCallback(() => {
		if ((selectedActivityRequest === "Disconnect" && !selectedActivityType) || (selectedActivityRequest === "Add" && selectedActivityType === "Public IP Addresses") || (selectedActivityRequest === "Change" && ["CABS Billing Change", "CFA", "Circuit Configuration", "Circuit Regroom", "MPLS-VPN", "Public BGP Routing", "Public Static IP Routing"].includes(selectedActivityType))) {
			MACDHelper.getMACDCircuitTypes(selectedMACDProduct).then(response => {
				logger.info(encodeURIComponent(response.data).replace(/%20/g, " "));
				setCircuitTypeOptions(response.data);
				//autoselect option if only 1 circuit type
				if (response.data?.length === 1) {
					props.CIRCUIT_TYPE(response.data[0].id);
				}
			}).catch(error => {
				logger.error(`Error Getting Circuit Types: ${error}`);
				setHasError(error);
			})
		}
	}, [logger, props, selectedActivityRequest, selectedActivityType, selectedMACDProduct])

	//Fetching Existing Circuit Menu from the the backend
	const getExistingCircuitMenu = useCallback(() => {
		//Only fetch the existing circuit menu once and if the existing circuit menu is empty
		if (Object.entries(existingCircuitOptions)?.length === 0 && ![15, 24, 27].includes(selectedMACDProduct)) {
			setLoader(true);
			let cirList = [];
			let gaid = "";
			//ADO-909573 Update QTY and Circuit Picklist for Additional Disconnects only only circuit on the same gaid to be displayed
			if (selectedActivityRequest === "Disconnect") {
				gaid = selectedCircuitGaid;
			}
			CircuitListHelper.getCircuitList(selectedCircuitSearch, gaid).then(response => {
				if (response.data !== "No circuits found") {
					cirList = response.data;
					//filter out the selected circuit from step 1
					cirList = cirList.filter(obj => { return obj.circuit !== selectedMainCircuit.value })
					setQty(response.data?.length);
				} else {
					setQty(1);
				}
				setLoader(false);
				setExistingCiruitOptions([{ value: '', label: 'Select Circuit' }].concat(cirList));
			}).catch(error => {
				logger.error(`Error Getting Existing Circuit Menu: ${error}`);
				setHasError(error);
				setLoader(false);
			})
		} else if (selectedMACDProduct === 27) {
			//POTS have the ability to add mulitple TNs so set the quantity to 25
			setQty(25);
		}

	}, [existingCircuitOptions, logger, selectedActivityRequest, selectedCircuitGaid, selectedCircuitSearch, selectedMACDProduct, selectedMainCircuit.value])

	//Fetching Default Service Date from the backend
	const getServiceDate = useCallback(() => {
		setLoader(true);
		MACDHelper.getDefaultServiceDate(selectedMACDProduct).then(response => {
			props.REQUESTED_SERVICE_DATE(response.data);
			props.END_BILLING_DATE(response.data);
			setLoader(false);
		}).catch(error => {
			logger.error(`Error getting Default Service Date: ${error}`);
			setHasError(error);
			setLoader(false);
		})
	}, [logger, props, selectedMACDProduct])

	//Calling getCircuitTypeMenu and getExistingCircuitMenu on component load
	useEffect(() => {
		getCircuitTypeMenu();
		getExistingCircuitMenu();
	}, [getCircuitTypeMenu, getExistingCircuitMenu, logger])

	//Getting the default service date
	useEffect(() => {
		if (selectedMACDServiceInfo.REQUESTED_SERVICE_DATE === "") {
			getServiceDate();
		}
	}, [getServiceDate, selectedMACDServiceInfo.REQUESTED_SERVICE_DATE])

	//Calling getBandwidthMenu when component loads or if the circuit type or product changes
	useEffect(() => {
		//Only call getBandwidthMenu if the circuit type has be selected
		if (selectedMACDServiceInfo.CIRCUIT_TYPE !== "") {
			logger.info("Calling bandwidth menu");
			getBandwidthMenu();
		}
	}, [getBandwidthMenu, logger, selectedMACDServiceInfo.CIRCUIT_TYPE])

	//Function used to handle input changes
	const inputHandler = (ev, index) => {
		switch (ev.target.id) {
			case "escort_request":
				props.ESCORT_ACCESS_REQUEST(ev.target.value);
				break;
			case "serv_date":
				props.REQUESTED_SERVICE_DATE(ev.target.value);
				break;
			case "edge_device":
				props.EDGE_DEVICE(ev.target.value);
				break;
			case "end_date":
				//Need to set both since the UI uses END Billing Date but the backend uses requested service date
				props.REQUESTED_SERVICE_DATE(ev.target.value);
				props.END_BILLING_DATE(ev.target.value);
				break;
			case "circuitType":
				props.CIRCUIT_TYPE(ev.target.value);
				getBandwidthMenu(selectedMACDProduct, ev.target.value);
				break;
			case "bandwidth":
				props.BANDWIDTH(ev.target.value);
				break;
			case "qty":
				props.QUANTITY(ev.target.value);
				break;
			case "ips":
				props.IPS(ev.target.value);
				break;
			case "cable_fid":
				props.CABLE_FID(ev.target.value);
				break;
			case "num_routes":
				props.NUMBER_OF_ROUTES(ev.target.value);
				if (Number(ev.target.value) > 0) {
					setRouteCount(Number(ev.target.value));
				}
				break;
			case "dispatch":
				props.DISPATCH_OUTSIDE_WIN_BUSINESS_HOURS(ev.target.checked);
				break;
			case "design_num":
				props.DESIGN_INQUIRY_NUM(ev.target.value);
				break;
			case "test_type":
				props.TEST_TYPE(ev.target.value);
				break;
			case "direction":
				props.DIRECTION(ev.target.value);
				break;
			default:
				break;
		}
	}

	/*
	Custom Handler for Number of Fiber input that checks
	input to be greater than 0 and an even number
	*/
	const fiberHandler = (value, index, id) => {
		setFiberErrorFlag(false);
		if (value > 0 && value % 2 === 0) {
			if (id === "fiberArray") {
				props.FIBER_ARRAY(value, index);
			} else {
				props.NUMBER_OF_FIBERS(value);
			}
		} else {
			setFiberErrorFlag(true);
			if (id === "fiberArray") {
				props.FIBER_ARRAY("", index);
			} else {
				props.NUMBER_OF_FIBERS("");
			}
		}
	}

	//Handler for Escort Access Checkbox
	const escortAccessCheckboxChange = (event) => {
		props.ESCORT_ACCESS_REQUEST(event.target.checked);
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<div className={style.section1}>
				<div className={`sub-hdr alt0 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>Service Information</h2>
				</div>
				{selectedMACDProduct === 30 && selectedActivityType === "Submit Project Request" && (Number(props?.mpid) === 4718 || Number(props?.mpid) === 8722) ?
					<div className="sub-section">
						<div className={` row ${style.rowGrp} ${style.colGrp}`}>
							<input type="checkbox" id="escort_request" name="escort_request" onChange={(e) => { escortAccessCheckboxChange(e) }} checked={selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST} />
							<label htmlFor="term1" className={` ${style.checkboxText} `}>Escort Access Request (3 Or More Sites)</label>
						</div>
					</div>
					: null}
				{selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST ?
					<div className="sub-section">
						<div className={` row ${style.rowGrp} ${style.colGrp}`}>
							Scope of Work is required to be uploaded  - see Uniti Escort Access Procedure Document
						</div>
					</div>
					: null}
				<div className="sub-section">
					<div className={` row ${style.rowGrp}`}>
						{selectedActivityRequest === "Add" || selectedActivityRequest === "Change" ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Requested Service Date<span className={style.mandatory}>*</span></label>
								<input type='date' className={style.dateFld} id='serv_date' name='serv_date' onChange={inputHandler} value={selectedMACDServiceInfo.REQUESTED_SERVICE_DATE}></input>
							</div> : null

						}

						{selectedActivityRequest === "Disconnect" ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>End Billing Date<span className={style.mandatory}>*</span></label>
								<input type='date' className={style.dateFld} id='end_date' name='end_date' onChange={inputHandler} value={selectedMACDServiceInfo.END_BILLING_DATE}></input>
							</div> : null
						}

						{/*Colo does have Circuit Type or Bandwidth*/}
						{selectedMACDProduct !== 15 ?
							<>
								{(selectedActivityRequest === "Disconnect" && !selectedActivityType && selectedMACDProduct !== 26) || (selectedActivityRequest === "Add" && selectedActivityType === "Public IP Addresses") || (selectedActivityRequest === "Change" && ["CABS Billing Change", "CFA", "Circuit Configuration", "Circuit Regroom", "MPLS-VPN", "Public BGP Routing", "Public Static IP Routing"].includes(selectedActivityType)) ?
									<>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Circuit Type<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='circuitType' name='circuitType' onChange={inputHandler} value={selectedMACDServiceInfo.CIRCUIT_TYPE}>
												{circuitTypeOptions.map((obj) => {
													return <option value={obj.id}>{obj.name}</option>
												})}
											</select>
										</div>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Bandwidth<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='bandwidth' name='bandwidth' onChange={inputHandler} value={selectedMACDServiceInfo.BANDWIDTH}>
												{bandwidthOptions.map((obj) => {
													return <option value={obj.id}>{obj.name}</option>
												})}
											</select>
										</div>
									</> : null
								}
							</> : null
						}
						{/*Qty gets replace with number of routes for Dark FIber*/}
						{selectedMACDProduct !== 30 ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Quantity<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='qty' name='qty' onChange={inputHandler} value={selectedMACDServiceInfo.QUANTITY}>
									<option value="">-</option>
									{[...Array(qty)].map((x, i) =>
										<option value={i + 1}>{i + 1}</option>
									)}
								</select>
							</div> : null
						}


						{selectedActivityRequest === "Add" && selectedActivityType === "Public IP Addresses" ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>IP's<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='ips' name='ips' onChange={inputHandler} value={selectedMACDServiceInfo.IPS}>
									<option value="99"></option>
									<option value="2">2 IP Addresses (/30 Subnet)</option>
									<option value="6">6 IP Addresses (/29 Subnet)</option>
									<option value="14">14 IP Addresses (/28 Subnet)</option>
									<option value="30">30 IP Addresses (/27 Subnet)</option>
								</select>
							</div> : null
						}

						{/*Hide Existing Circuit ID Menu for Colocation, SD-WAN, POTS*/}
						{![15, 24, 27].includes(selectedMACDProduct) ?
							<>
								{Number(selectedMACDServiceInfo.QUANTITY) > 1 ?
									[...Array(Number(selectedMACDServiceInfo.QUANTITY) - 1)].map((ex, i) =>
										<>
											<div key={i} className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}> Existing Circuit ID {i + 2} </label>
												{selectedMACDServiceInfo.EXISTING_CIRCUIT_ID[0] === "Manual Circuit" ?
													<input type='text' className={style.inputFld} placeholder="Manually Enter Circuit ID" id={`manual_circuit` + i} onChange={(ev) => props.MANUAL_CIRCUIT(ev.target.value, i + 1)}></input>
													:
													<select className={style.selectFld} id={`existing_circuit` + i} value={selectedMACDServiceInfo.EXISTING_CIRCUIT_ID[i + 1]} onChange={(ev) => props.EXISTING_CIRCUIT_ID(ev.target.value, i + 1)}>
														{existingCircuitOptions.map((obj) => {
															return <option value={obj.circuitId}>{obj.circuitId}</option>
														})}
													</select>
												}
											</div>
										</>
									) : null
								}
							</> : null
						}

						{selectedActivityRequest === "Change" && ["Splice Request", "OTDR (Test & Turnup)", "Generic ICB Request", "Jumpers", "Records Confirmation"].includes(selectedActivityType) ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Cable Fid</label>
									<input type='text' className={style.inputFld} id='cable_fid' name='cable_fid' maxLength='53' onChange={inputHandler} value={selectedMACDServiceInfo.CABLE_FID}></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Number of Fibers<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id='num_fibers' name='num_fibers' maxLength='3' onChange={(event) => fiberHandler(event.target.value, "", "num_fibers")} value={selectedMACDServiceInfo.NUMBER_OF_FIBERS}></input>
									{fiberErrorFlag ?
										<div class="invalid-feedback d-block">
											Please enter an even number
										</div> : null
									}
								</div>
							</> : null
						}

						{!selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST && selectedActivityType === "Submit Project Request" ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Number Of Routes<span className={style.mandatory}>*</span></label>
									<select className={style.selectFld} id='num_routes' name='num_routes' onChange={inputHandler} value={selectedMACDServiceInfo.NUMBER_OF_ROUTES}>
										<option value="">-</option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
									</select>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Cable Fid</label>
									<input type='text' className={style.inputFld} id='cable_fid' name='cable_fid' maxLength='53' onChange={inputHandler} value={selectedMACDServiceInfo.CABLE_FID}></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Design Inquiry Number</label>
									<input type='text' className={style.inputFld} id='design_num' name='design_num' maxLength='30' onChange={inputHandler} value={selectedMACDServiceInfo.DESIGN_INQUIRY_NUM}></input>
								</div>
							</> : null
						}

						{!selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST && selectedActivityRequest === "Change" && ["OTDR (Test & Turnup)", "Submit Project Request"].includes(selectedActivityType) ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Test Type<span className={style.mandatory}>*</span></label>
									<select className={style.selectFld} id='test_type' name='test_type' onChange={inputHandler} value={selectedMACDServiceInfo.TEST_TYPE}>
										<option></option>
										<option value="1550">Standard</option>
										<option value="1625">1625</option>
									</select>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Direction<span className={style.mandatory}>*</span></label>
									<select className={style.selectFld} id='direction' name='direction' onChange={inputHandler} value={selectedMACDServiceInfo.DIRECTION}>
										<option></option>
										<option value="1">Uni</option>
										<option value="2">Bi</option>
									</select>
								</div>
							</> : null
						}

						{selectedActivityRequest === "Change" && ["Splice Request", "OTDR (Test & Turnup)", "Fiber Access Request", "Jumpers", "Submit Project Request"].includes(selectedActivityType) ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Dispatch outside WIN business hours
									<select className={style.selectFld} id='dispatch' name='dispatch' onChange={inputHandler} value={selectedMACDServiceInfo.DISPATCH_OUTSIDE_WIN_BUSINESS_HOURS}>
										<option value="NO">NO</option>
										<option value="YES">YES</option>
									</select>
								</label> </div> : null
						}
						{/* commenting out the condition below. Existing TN should display for POTS 7/17/24 ADO #917449
						{(selectedActivityRequest === "Change") && (["Features", "Suspend", "Restore"].includes(selectedActivityType) || (selectedActivityRequest === "Disconnect" && selectedActivityType)) ?
						*/}
						{/*POTS*/}
						{selectedMACDProduct === 27 ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Existing TN<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} maxLength='10' id='existing_tn' name='existing_tn' onChange={(event) => props.EXISTING_TN(event.target.value, 0)} value={selectedMACDServiceInfo.EXISTING_TN[0]}></input>
								</div>
								{Number(selectedMACDServiceInfo.QUANTITY) > 1 ?
									[...Array(Number(selectedMACDServiceInfo.QUANTITY) - 1)].map((ex, i) =>
										<div key={i} className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}> Existing TN {i + 2} </label>
											<input type='text' className={style.inputFld} maxLength='10' id='existing_tn' name='existing_tn' onChange={(event) => props.EXISTING_TN(event.target.value, i + 1)} value={selectedMACDServiceInfo.EXISTING_TN[i + 1]}></input>
										</div>
									) : null}
							</> : null
						}
						{/*SD WAN*/}
						{selectedMACDProduct === 24 ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Edge Device<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='edge_device' name='edge_device' onChange={inputHandler} value={selectedMACDServiceInfo.EDGE_DEVICE}>
									<option></option>
									<option value="1">Purchased</option>
									<option value="2">Leased</option>
								</select>
							</div> : null
						}

					</div>
					{/*DARK FIBER*/}
					{!selectedMACDServiceInfo.ESCORT_ACCESS_REQUEST && selectedActivityType === "Submit Project Request" ?
						<>
							{routeCount > 0 ?
								[...Array(routeCount)].map((ex, i) =>
									<>
										<div className={` row ${style.rowGrp}`}>
											<div key={i} className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}> Route {i + 1}</label>
											</div>
										</div>
										<div className={` row ${style.rowGrp}`}>
											<div key={i} className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}> Number of Fibers</label>
												<input type='text' className={style.inputFld} id='fiberArray' name='fiberArray' onChange={(event) => fiberHandler(event.target.value, i + 1, "fiberArray")} value={selectedMACDServiceInfo.FIBER_ARRAY[i + 1]}></input>
												{fiberErrorFlag ?
													<div class="invalid-feedback d-block">
														Please enter an even number
													</div> : null
												}
											</div>
											<div key={i} className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}> Number of Splice Points</label>
												<input type='text' className={style.inputFld} id='spliceArray' name='spliceArray' onChange={(event) => props.SPLICE_ARRAY(event.target.value, i + 1)} value={selectedMACDServiceInfo.SPLICE_ARRAY[i + 1]}></input>
											</div>
										</div>
									</>
								)
								: null
							}
						</> : null
					}
				</div></div>)}
			<div className={style.dividers}></div>
		</>
	)
}



const mapDispatchToProps = {
	ESCORT_ACCESS_REQUEST: setMACDEscortAccessRequest,
	REQUESTED_SERVICE_DATE: setMACDReqSerDate,
	EDGE_DEVICE: setMACDEdgeDevice,
	END_BILLING_DATE: setMACDEndBillingDate,
	CIRCUIT_TYPE: setMACDCircuitType,
	BANDWIDTH: setMACDBW,
	QUANTITY: setMACDQty,
	IPS: setMACDIps,
	MANUAL_CIRCUIT: setMACDManualCircuit,
	EXISTING_CIRCUIT_ID: setMACDExistingCircuit,
	CABLE_FID: setMACDACableFid,
	NUMBER_OF_FIBERS: setMACDANumOfFibers,
	NUMBER_OF_ROUTES: setMACDANumOfRoutes,
	DISPATCH_OUTSIDE_WIN_BUSINESS_HOURS: setMACDDispatchOtsideWin,
	TEST_TYPE: setMACDTestType,
	DIRECTION: setMACDADirection,
	EXISTING_TN: setMACDExistingTN,
	DESIGN_INQUIRY_NUM: setMACDDesignInquiryNum,
	FIBER_ARRAY: setMACDFiberArray,
	SPLICE_ARRAY: setMACDSpliceArray
}

export default connect(null, mapDispatchToProps)(MACDServiceInfo);
