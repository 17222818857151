import { useEffect, useState } from "react";
import style from "./Employee.module.css";
import ProfileService from "../../services/ProfileServices";
import { connect, useSelector } from 'react-redux';
import { nnis } from '../../redux';
import Loader from "../shared/Loader";


const TypeOptions = [{
	name: 'Select One',
	value: '',
}, {
	name: 'CSE',
	value: 'cse',
}, {
	name: 'MPLS',
	value: 'mpls',
}];

const NNIModal = (props) => {
	const profileFromStore = useSelector(state => state.employeeProfile.profile);
	const [NNIOptions, setNNIOptions] = useState([]);
	const [addNNIValues, setAddNNIValues] = useState({ type: props.type }); // pass to API
	const [isNNISelected, setIsNNISelected] = useState(false);
	const modalTitle = props.title;
	const modalAction = props.action;
	const [errMsg, setErrMsg] = useState(null);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		let nniList = '';
		let nniName = ''
		switch (modalAction) {
			case 'ADD':
				ProfileService.getNNIOptions(profileFromStore.selectedProfile.value, modalAction, props.type).then(nniData => {
					if (props.type === 'cse') {
						nniList = nniData.data.cse;
					} else {
						nniList = nniData.data.mpls;
					}
					nniList = nniList.map(item => {
						nniName = `${item.name}`;
						return { value: item.id, label: nniName }
					});
					setNNIOptions(nniList);
				});
				break;
			case 'ADD_CIRCUIT':
				ProfileService.getAssignedNNIOptions(profileFromStore.selectedProfile.value, modalAction).then(response => {
					let nniData = response.data.nnis;
					nniList = nniData.map(item => {
						nniName = `${item.name}`;
						return { value: item.id, label: nniName }
					});
					setNNIOptions(nniList);
				})
					.catch(error => {
						setErrMsg('Error retrieveing assigned NNIs.')
					});
				break;
			case 'EDIT':
			case 'DELETE':
				setAddNNIValues(props.NNIObject);
				break;
			default:
				break;
		}
	}, [modalAction, profileFromStore.selectedProfile.value, props.NNIObject]);

	const popupCancel = () => {
		props.emitAction(false);
	};

	const popupOk = () => {
		if (validateForm()) {
			setErrMsg(null);
			props.setNNIValues(addNNIValues);
			props.emitAction(false);
		} else {
			setErrMsg('Required fields are missing');
		}
	};

	const deleteNNI = () => {
		props.setNNIValues(addNNIValues);
		props.emitAction(false);
	};

	const onChangeInput = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

		if ((modalAction === 'ADD' || modalAction === 'ADD_CIRCUIT') && name === 'nni') {
			setLoader(true);
			if (modalAction === 'ADD_CIRCUIT') {
				setIsNNISelected(true);
			}
			ProfileService.getNNIAddress(value, props.type).then(response => {
				setAddNNIValues({ ...addNNIValues, [name]: value, 'address': response.data });
				setLoader(false);
			});
		} else {
			setAddNNIValues({ ...addNNIValues, [name]: value });
		}
	};

	const validateForm = () => {
		if (modalAction === 'EDIT' || modalAction === 'ADD_CIRCUIT') {
			if (!addNNIValues.circuitID) {
				setErrMsg('Required fields are missing');
				return false;
			}
		} else {
			if (!addNNIValues.nni || !addNNIValues.address || !addNNIValues.type || !addNNIValues.circuitID) {
				setErrMsg('Required fields are missing');
				return false;
			}
		}
		return true;
	};

	return (
		<div>
			<div className={`${style.overly}`}> </div>
			<div className="modal fade show d-block">
				<div className={`modal-dialog modal-dialog-centered modal-md`}>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title m-auto text-dark">{modalTitle}</h5>
						</div>
						<div className="modal-body">
							{modalAction === "DELETE" ?
								<>
									<div className='mb-3'>
										{`Are you sure you want to delete NNI: ${addNNIValues.nni} Circuit ID: ${addNNIValues.circuitID}?`}
									</div>
								</>
								: null}

							{modalAction === "EDIT" ?
								<>
									<div className='mb-3'>
										<label className='labelText'> NNI </label>
										<input type="text" name="nni" value={addNNIValues.nni} className={`${style.inputFldText}`} disabled="disabled" />
									</div>
									<div className='mb-3'>
										<label className='labelText'> ADDRESS </label>
										<input type="text" name="address" value={addNNIValues.address} className={`${style.inputFldText}`} disabled="disabled" />
									</div>
									<div className='mb-3'>
										<label className='labelText'> TYPE </label>
										<input type="text" name="type" value={addNNIValues.type} className={`${style.inputFldText}`} disabled="disabled" />
									</div>
									<div className='mb-3'>
										<label className='labelText'> DESCRIPTION </label>
										<input type="text" name="description" value={addNNIValues.description} className={`${style.inputFldText}`} onChange={onChangeInput} />
									</div>
									<div className='mb-3'>
										<label className='labelText'> CIRCUIT ID <span className={`${style.mandatory}`}>*</span></label>
										<input type="text" name="circuitID" value={addNNIValues.circuitID} className={`${style.inputFldText}`} onChange={onChangeInput} />
									</div>
								</>
								: null
							}

							{modalAction === "ADD" ?
								<>
									{loader ? <Loader /> : null}
									<div className='mb-3'>
										<label className='labelText'> NNI <span className={`${style.mandatory}`}>*</span></label>
										<select defaultValue={'-'} className={`form-control ${style.selectFld}`} id='nni' onChange={onChangeInput} name="nni" value={addNNIValues.nni}>
											{NNIOptions?.length > 0 ?
												<>
													{NNIOptions.map(item => (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													))}
												</>
												:
												null}
										</select>
									</div>
									<div className='mb-3'>
										<label className='labelText'> ADDRESS <span className={`${style.mandatory}`}>*</span></label>
										<input type="text" name="address" value={addNNIValues.address} className={`${style.inputFldText}`} onChange={onChangeInput} />
									</div>
									<div className='mb-3'>
										<label className='labelText'> TYPE <span className={`${style.mandatory}`}>*</span></label>
										<select defaultValue={props.type || 'cse'} className={`form-control ${style.selectFld}`} id='type' onChange={onChangeInput} name="type" value={addNNIValues.type} disabled>
											{TypeOptions.map((obj) => (
												<option key={obj.value} value={obj.value}>{obj.name}</option>
											))}
										</select>
									</div>
									<div className='mb-3'>
										<label className='labelText'> DESCRIPTION </label>
										<input type="text" name="description" value={addNNIValues.description} className={`${style.inputFldText}`} onChange={onChangeInput} />
									</div>
									<div className='mb-3'>
										<label className='labelText'> CIRCUIT ID <span className={`${style.mandatory}`}>*</span></label>
										<input type="text" name="circuitID" value={addNNIValues.circuitID} className={`${style.inputFldText}`} onChange={onChangeInput} />
									</div>
								</>
								: null}

							{modalAction === "ADD_CIRCUIT" ?
								<>
									{loader ? <Loader /> : null}
									<div className='mb-3'>
										<label className='labelText'> Existing NNI </label>
										<select defaultValue={'-'} className={`form-control ${style.selectFld}`} id='nni' onChange={onChangeInput} name="nni" value={addNNIValues.nni}>
											{NNIOptions?.length > 0 ?
												<>
													{NNIOptions.map(item => (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													))}
												</>
												:
												null}
										</select>
									</div>
									{isNNISelected && (
										<>
											<div className='mb-3'>
												<label className='labelText'> ADDRESS </label>
												<input type="text" name="address" value={addNNIValues.address} className={`${style.inputFldText}`} disabled="disabled" />
											</div>
											<div className='mb-3'>
												<label className='labelText'> TYPE</label>
												<select defaultValue={props.type || 'cse'} className={`form-control ${style.selectFld}`} id='type' onChange={onChangeInput} name="type" value={addNNIValues.type} disabled>
													{TypeOptions.map((obj) => (
														<option key={obj.value} value={obj.value}>{obj.name}</option>
													))}
												</select>
											</div>
											<div className='mb-3'>
												<label className='labelText'> DESCRIPTION </label>
												<input type="text" name="description" value={addNNIValues.description} className={`${style.inputFldText}`} onChange={onChangeInput} />
											</div>
											<div className='mb-3'>
												<label className='labelText'> CIRCUIT ID <span className={`${style.mandatory}`}>*</span></label>
												<input type="text" name="circuitID" value={addNNIValues.circuitID} className={`${style.inputFldText}`} onChange={onChangeInput} />
											</div>
										</>
									)}
								</>
								: null}
						</div>

						<div className="modal-footer d-block text-center">
							<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => popupCancel()}>Cancel</button>
							{modalAction === "DELETE" ?
								<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => { deleteNNI() }}>Yes</button>
								:
								<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => { popupOk() }}>Save</button>
							}
						</div>
						{errMsg && <div className="mt-5">
							<p className="text-danger text-center">{errMsg}</p>
						</div>}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	nnis: nnis
};

export default connect(null, mapDispatchToProps)(NNIModal);