import TabsQuote from "./includes/TabsQuote";
import style from "./QuoteCreate.module.css";
import HomeData from "../../services/HomeServices";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import { useEffect, useMemo, useRef, useState } from "react";
import RenewalHelper from "../../services/RenewalService";
import DIAPremHelper from "../../services/DIAPremService";
import DIAPopHelper from "../../services/DIAPopService";
import Select from 'react-select';
import { useSelector } from "react-redux";
import CompanyDropdown from "../shared/CompanyDropdown";
import Loader from "../shared/Loader";
import ReactTooltip from 'react-tooltip';
import QuoteHelper from "../../services/QuoteService";
import Term from "./Term";
import ColocationHelper from "../../services/ColocationService";
import axios from "axios";

const Renewals = () => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [stateList, setStateList] = useState([]);
	const [qty, setQty] = useState(1);
	const [circuitList, setCircuitList] = useState([]);
	const [circuitsOnSameGaid, setCircuitsOnSameGaid] = useState([]);
	const [circuitGaid, setCiruitGaid] = useState("");
	const [selectedCircuit, setSelectedCircuit] = useState([]);
	const [manualCircuitID, setManualCircuitID] = useState([]);
	const [circuitValidated, setCircuitValidated] = useState(false);
	const [addressAObj, setAddressAObj] = useState({});
	const [addressZObj, setAddressZObj] = useState({});
	const [addressErrorObj, setAddressErrorObj] = useState({});
	const [generalDetailData, setGeneralDetailData] = useState({});
	const [generalDetailErrorObj, setGeneralDetailErrorObj] = useState({});
	const [configurationData, setConfigurationData] =  useState({});
	const [configurationErrorObj, setConfigurationErrorObj] = useState({})
	const [bandwidthOptions, setBandwidthOptions] = useState([]);
	const [circuitMenuOptions, setCircuitMenuOptions] = useState([]);
	const [diaPopIpOptions, setDiaIpOptions] = useState([]);
	const [rackSpaceOptions, setRackSpaceOptions] = useState([]);
	const [breakerAmpOptions, setBreakerAmpOptions] = useState([]);
	const [primaryPowerOptions, setPrimaryPowerOptions] = useState([]);
	const [expectedProduct, setExpectedProduct] = useState("");
	const [expectedBandwidth, setExpectedBandwidth] = useState("");
	const [loader, setLoader] = useState(false);
	const [requiredMsg, setRequiredMsg] = useState("");
	const [renewalErrorMsg, setRenewalErrorMsg] = useState("");
	const [contactObj, setContactObj] = useState({});
	const [contactErrorObj, setContactErrorObj] = useState({});
	const [newContactFlag, setNewContactFlag] = useState("");
	const [contactOptions, setContactOptions] = useState([]);
	const [productCode, setProductCode] = useState("");
	const [premIpOptions, setPremIpOptions] = useState([]);
	const [popupMsg, setPopupMsg] = useState("");
	const [inputValue, setInputValue] = useState("");
	const addressRef = useRef(null);

	const selectedInternalMpid = useSelector(state => state.internal?.company?.value);

	useEffect(() => {
		//Get the list of all the states for AV form
		HomeData.getStateList().then(response => {
			setStateList(response.data);
		}).catch((error) => {
			logger.error("getStates ERROR: " + error)
		})

	}, [logger]);

	useEffect(() => {
		clearRenewalForm();
	}, [selectedInternalMpid])

	useEffect(() => {
		if (circuitGaid !== "" && selectedCircuit[0].label !== "Manual Circuit") {
			setLoader(true);
			RenewalHelper.getRenewalCircuitsByGaid(circuitGaid).then(response => {
				setCircuitsOnSameGaid(response.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Error Getting Existing Quote Circuits: " + error);
				setLoader(false);
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [circuitGaid])

	useEffect(() => {
		if (selectedInternalMpid) {
			setLoader(true);
			//Pulls existing circuit ids
			RenewalHelper.getRenewalCircuitsByMpid().then(response => {
				let cirList = response.data;
				setCircuitList(cirList);
				setLoader(false);
			}).catch(error => {
				logger.error("Error Getting Existing Quote Circuits: " + error);
				setLoader(false);
			})

			//Get the list of all contacts
			QuoteHelper.getQuoteContact().then(response => {
				setContactOptions(response.data);
				setLoader(false);
			}).catch(error => {
				setLoader(false);
				logger.error(`Error Getting Contacts: ${error}`);
			})
		}

	}, [logger, selectedInternalMpid])

	//Perform circuit lookup search again camp database and quickbase
	const validateCircuit = () => {
		let searchCircuit = null
		//Determine whether to use the circuit selected value or the manual circuit value to search
		if (selectedCircuit[0].value === "manual") {
			searchCircuit = manualCircuitID;
		} else {
			searchCircuit = selectedCircuit
		}

		campCircuitSearch(searchCircuit);
		quickbaseCircuitSearch(searchCircuit);

		setCircuitValidated(true);
	}

	//Helper function that calls the camp database to pull circuit location data
	const campCircuitSearch = (circuitSearchValue) => {
		setLoader(true);
		let idx = null;
		RenewalHelper.pullCircuitInfo(circuitSearchValue).then(response => {
			logger.info(`Camp response: ${JSON.stringify(response.data)}`);
			idx = response.data?.findIndex(item => item.result === "SUCCESS");
			if (idx >= 0) {
				//auto populate Location A and Z data
				setAddressAObj({
					"a_add": response.data[idx].aloc_street,
					"a_add_2": response.data[idx].aloc_address2,
					"a_city": response.data[idx].aloc_city,
					"a_state": response.data[idx].aloc_state,
					"a_zip": response.data[idx].aloc_zip
				});
				setAddressZObj({
					"z_add": response.data[idx].zloc_street,
					"z_add_2": response.data[idx].zloc_address2,
					"z_city": response.data[idx].zloc_city,
					"z_state": response.data[idx].zloc_state,
					"z_zip": response.data[idx].zloc_zip
				});

				//Product and Bandwidth tooltip values
				if (response.data[idx]?.billing_product !== "") {
					setExpectedProduct(response.data[idx].billing_product);
				} else {
					setExpectedProduct(response.data[idx].product)
				}

				setExpectedBandwidth(response.data[idx].con_rate_code);

				//Auto populate global account id
				setGeneralDetailData((prevState) => ({ ...prevState, "gaid": response.data[idx].gaid }));
			}
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.info(error);
		})
	}

	//Helper function that calls quickbase to pull circuit data
	const quickbaseCircuitSearch = (circuitSearchValue) => {
		setLoader(true);
		let idx = null;
		RenewalHelper.qbCircuitInfo(circuitSearchValue).then(response => {
			logger.info(`QB RESPONSE: ${JSON.stringify(response.data)} `);
			idx = response.data?.findIndex(item => item.result === "SUCCESS");
			if (idx >= 0) {
				//auto populate some of the general detail input fields
				setGeneralDetailData((prevState) => ({
					...prevState,
					"endTerm": response.data[idx].endTerm,
					"currentMrc": response.data[idx].totalMrc,
					"termFromSource": response.data[idx].termFromSource
				}))
			}
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error(`quickbaseCircuitSearch Error: ${error}`);

		})
	}

	//Helper function to fetch the bandwidths for the product and circuit
	const fetchBandwidthOptions = (product, circuit) => {
		setLoader(true);
		RenewalHelper.getBandwidths(product, circuit).then(response => {
			logger.info(`Bandwidth options: ${JSON.stringify(response.data)}`);
			setBandwidthOptions(response.data);

			//autoselect bw if only one option is available
			if (response.data.length === 1) {
				setGeneralDetailData((prevState) => ({ ...prevState, "bw": response.data[0].id }));
				setGeneralDetailErrorObj(prevError => ({ ...prevError, "bwError": "" }));
			}

			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error(`fetchBandwidth ERROR: ${error}`);
		})
	}

	//Helper function to fetch the circuit types for the product
	const fetchCircuitTypeOptions = (product) => {
		setLoader(true);
		RenewalHelper.getRenewalCircuitMenu(product).then(response => {
			logger.info(response.data);
			if (response.data.length === 1) {
				setGeneralDetailData((prevState) => ({ ...prevState, "circuit": response.data[0].id }));
				setGeneralDetailErrorObj(prevError => ({ ...prevError, "circuitError": "" }));
				fetchBandwidthOptions(product, response.data[0].id);
			}
			setCircuitMenuOptions(response.data);
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error(`fetchCircuitTypeOptions ERROR: ${error}`);
		})
	}

	//Handler for the existing circuit id selection
	const circuitHandler = (ev, index) => {
		if (index === 0) {
			//clear all input fields and errors when a new circuit is selected
			setCircuitValidated(false);
			setAddressAObj({});
			setAddressZObj({});
			setAddressErrorObj({})
			setGeneralDetailData({});
			setGeneralDetailErrorObj({});
			setContactObj({});
			setContactErrorObj({});
			setRequiredMsg("");
			setQty(1);
		}

		let circuitVal = '';
		if (!ev) {
			circuitVal = ''
		} else {
			circuitVal = ev;
		}

		if (ev.label !== "Manaul Circuit" && index === 0) {
			setCiruitGaid(ev.gaid);
			//Clearing any manual ciruits that might have been entered
			setManualCircuitID([]);
		}
		setSelectedCircuit((prevState) => ({ ...prevState, [index]: circuitVal }));
	}

	//Handler for manual circuit entry
	const manualCircuitHandler = (inputValue, index) => {
		logger.info("inputValue: " + JSON.stringify(inputValue));
		setManualCircuitID((prevState) => ({ ...prevState, [index]: inputValue }));
		logger.info(JSON.stringify(manualCircuitID));
	}

	//Helper function to fetch DIA POP IPs
	const fetchDIAPopIps = () => {
		DIAPopHelper.getPopIPs().then(response => {
			logger.info(`DIA Pop ips ${JSON.stringify(response.data)}`);
			setDiaIpOptions(response.data);
		}).catch(error => {
			logger.error(`Error fetching dia pop ips ${error}`);
		})
	}


	//Handles all inputs in the general details section and the comments
	const generalDetailDataHandler = (event) => {
		let id = event.target.id;
		let value = event.target.value;
		let type = event.target.type;
		let checked = event.target.checked
		let errorId = id + "Error";

		if (id === "product") {
			//clear circuit & bandwidth after each product selection
			setGeneralDetailData((prevState) => ({ ...prevState, "circuit": "" }));
			setGeneralDetailData((prevState) => ({ ...prevState, "bw": "" }));
			getProductCode(value);
			fetchCircuitTypeOptions(value);

			switch(Number(value)){
				case 1:
					//WAVE
					setGeneralDetailData((prevState) => ({ ...prevState, "circuit": "7" }));
					fetchBandwidthOptions(value, "7");
					setGeneralDetailErrorObj(prevError => ({ ...prevError, "circuitError": "" }));
					break;
				case 10:
					//DIA POP
					setGeneralDetailData((prevState) => ({ ...prevState, "circuit": "6" }));
					fetchBandwidthOptions(value, "6");
					fetchDIAPopIps();
					setGeneralDetailErrorObj(prevError => ({ ...prevError, "circuitError": "" }));
					break;
				case 15:
					//COLOCATION
					setConfigurationData((prevState) => ({ ...prevState, "crossConnect": 0 }));
					fetchColoMenus();
					break;
				default:
					break;
			}

		}

		//If broadband is selected as circuit type we need to display additional options
		if (id === "circuit" && value !== "14") {
			fetchBandwidthOptions(generalDetailData.product, value);
		}

		if (id === "circuit" && Number(generalDetailData?.product) === 11) {
			getDIAPremIPs(value);
		}

		if (type === "checkbox") {
			setGeneralDetailData((prevState) => ({ ...prevState, [id]: checked }));
		} else {
			setGeneralDetailData((prevState) => ({ ...prevState, [id]: value }));
		}
		setGeneralDetailErrorObj(prevError => ({ ...prevError, [errorId]: "" }));
	}

	//Handles the configuration section for colocation
	const configurationHandler = (event) => {
		let id = event.target.id;
		let value = event.target.value;
		let type = event.target.type;
		let checked = event.target.checked
		let errorId = id + "Error";

		if (type === "checkbox") {
			setConfigurationData((prevState) => ({ ...prevState, [id]: checked }));
		} else {
			setConfigurationData((prevState) => ({ ...prevState, [id]: value }));
		}

		setConfigurationErrorObj(prevError => ({ ...prevError, [errorId]: ""}))
	}

	//Handles the renewal term selection from Term.js component
	const renewalTermHandler = (id, value) => {
		logger.info("Inside Renewal Term Handler");
		logger.info(`Term: ${id} Value: ${value}`);
		setGeneralDetailData((prevState) => ({ ...prevState, [id]: value }));
		setGeneralDetailErrorObj(prevError => ({ ...prevError, "termError": "" }));
	}

	//Handles all input in the Location A and Location Z fields
	const addressInputHandler = (event, type) => {
		let id = event.target.id;
		let errorId = id + "Error";
		if (type === "A") {
			setAddressAObj(prevAdd => ({ ...prevAdd, "a_edit": true }));
			setAddressAObj(prevAdd => ({ ...prevAdd, [id]: event.target.value }));
		} else {
			setAddressZObj(prevAdd => ({ ...prevAdd, "z_edit": true }));
			setAddressZObj(prevAdd => ({ ...prevAdd, [id]: event.target.value }));
		}
		setAddressErrorObj(prevAdd => ({ ...prevAdd, [errorId]: "" }));
	}

	//Handles contact selection
	const contactHandler = (event) => {
		let value = event.target.value;
		let id = event.target.id;
		let errorId = id + "Error";
		if (id === "contact") {
			if (value === "new") {
				setNewContactFlag(true);
				setContactObj((prevState) => ({ ...prevState, [id]: value }));
			} else {
				autoFillContact(value);
			}
		} else {
			setContactObj((prevState) => ({ ...prevState, [id]: value }));
			setContactErrorObj((prevState) => ({ ...prevState, [errorId]: "" }));
		}

	}

	//Handles new contact entry
	const newContactHandler = (event) => {
		setContactObj((prevState) => ({ ...prevState, [event.target.id]: event.target.value }));
		//clearing any error with the new contact field
		setContactErrorObj((prevState) => ({ ...prevState, "newContactError": "" }));
	}

	//Autofills contact info and adds it to the contact object
	const autoFillContact = (value) => {
		setLoader(true);
		QuoteHelper.getQuoteContactInfo(value).then(response => {
			logger.info(response.data);
			setContactObj((prevState) => ({
				...prevState,
				"contact": value,
				"phone": response.data.phone,
				"email": response.data.email,
			}));
			//clearing any errors
			setContactErrorObj((prevState) => ({
				...prevState,
				"phoneError": "",
				"emailError": ""
			}));
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error(`Error Autofilling contact: ${error}`);
		})
	}

	//Clears Renewal Form
	const clearRenewalForm = () => {
		setQty(1);
		setAddressAObj({});
		setAddressZObj({});
		setSelectedCircuit([]);
		setManualCircuitID([]);
		setCircuitValidated(false);
		setAddressErrorObj({});
		setGeneralDetailData({});
		setGeneralDetailErrorObj({});
		setContactObj({});
		setContactErrorObj({});
		setRequiredMsg("");
	}

	//Validates all required fields
	const requiredFieldValidation = () => {
		let error = false;
		//Loc A Validation Check
		if (!addressAObj?.a_add) { setAddressErrorObj(prevAddError => ({ ...prevAddError, "a_addError": "Address is required!" })); error = true; }
		if (!addressAObj?.a_city) { setAddressErrorObj(prevAddError => ({ ...prevAddError, "a_cityError": "City is required!" })); error = true; }
		if (!addressAObj?.a_state) { setAddressErrorObj(prevAddError => ({ ...prevAddError, "a_stateError": "State is required!" })); error = true; }
		if (!addressAObj?.a_zip) { setAddressErrorObj(prevAddError => ({ ...prevAddError, "a_zipError": "Zip is required!" })); error = true; }

		//Loc Z Validation Check
		if (generalDetailData.product === "1" || generalDetailData.product === "19" || generalDetailData?.product === "20" || generalDetailData.product === "35") {
			if (!addressZObj?.z_add) { setAddressErrorObj(prevAddError => ({ ...prevAddError, "z_addError": "Address is required!" })); error = true; }
			if (!addressZObj?.z_city) { setAddressErrorObj(prevAddError => ({ ...prevAddError, "z_cityError": "City is required!" })); error = true; }
			if (!addressZObj?.z_state) { setAddressErrorObj(prevAddError => ({ ...prevAddError, "z_stateError": "State is required!" })); error = true; }
			if (!addressZObj?.z_zip) { setAddressErrorObj(prevAddError => ({ ...prevAddError, "z_zipError": "Zip is required!" })); error = true; }
		}

		if (!generalDetailData?.product) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "productError": "Product is required!" })); error = true; }
		if (generalDetailData?.product !== "15") {
			if (!generalDetailData?.circuit) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "circuitError": "Circuit is required!" })); error = true; }
			if (!generalDetailData?.bw) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "bwError": "Bandwidth is required!" })); error = true; }
		}
		if (!generalDetailData?.gaid) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "gaidError": "Global Account ID is required!" })); error = true; }
		if (!generalDetailData?.term1 && !generalDetailData?.term2 && !generalDetailData?.term3 && !generalDetailData?.term4 && !generalDetailData?.term5 && !generalDetailData?.term10) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "termError": "Renewal Term is required!" })); error = true; }
		if (!generalDetailData?.currentMrc) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "currentMrcError": "Current MRC is required!" })); error = true; }
		if (!generalDetailData?.termFromSource) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "termFromSource": "Term is required!" })); error = true; }
		if (!generalDetailData?.endTerm) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "endTermError": "End Term is required!" })); error = true; }

		//IPs are required for DIA Pop or DIA Prem
		if (generalDetailData?.product === "10" || generalDetailData?.product === "11") {
			if (!generalDetailData?.ips) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "ipsError": "Ips is required!" })); error = true; }
		}

		//Additional required fields for manual circuits
		if (selectedCircuit[0].value === "manual") {
			if (!generalDetailData?.billingSystem) { setGeneralDetailErrorObj(prevError => ({ ...prevError, "billingSystemError": "Billing System is required!" })); error = true; }
		}

		//Colocation Configuration Section
		if (Number(configurationData?.crossConnect === 0)) {
			if (!configurationData?.rackSpace) { setConfigurationErrorObj(prevError => ({ ...prevError, "rackSpaceError": "Rack Space is required!"})); error = true; }
			if (!configurationData?.breakerAmp) { setConfigurationErrorObj(prevError => ({ ...prevError, "breakerAmpError": "Breaker Amperage is required!"})); error = true;}
			if (!configurationData?.primaryPower) { setConfigurationErrorObj(prevError => ({ ...prevError, "primaryPowerError": "Primary Power is required!"})); error = true; }
		}

		//Contact validation
		if (!contactObj?.contact) { setContactErrorObj(prevError => ({ ...prevError, "contactError": "Contact is required!" })); error = true; }
		if (!contactObj?.phone) { setContactErrorObj(prevError => ({ ...prevError, "phoneError": "Phone is required!" })); error = true; }
		if (!contactObj?.email) { setContactErrorObj(prevError => ({ ...prevError, "emailError": "Email is required!" })); error = true; }

		//If new contact make sure a name is entered
		if (contactObj.contact === "new") {
			if (!contactObj?.newContact) { setContactErrorObj(prevError => ({ ...prevError, "newContactError": "New Contact is required!" })); error = true; }
		}

		return error;
	}

	//Function to get the product code of the selected product
	const getProductCode = (product) => {
		let code = "";
		switch (Number(product)) {
			case 1:
				code = "WAVE";
				break;
			case 6:
				code = "MPLS_AGG_TAIL";
				break;
			case 10:
				code = "DIA_POP";
				break;
			case 11:
				code = "DIA_PREM";
				break;
			case 13:
				code = "MPLS_INTERNET";
				break;
			case 15:
				code = "COLOCATION";
				break;
			case 19:
				code = "E_LINE";
				break;
			case 20:
				code = "E_ACCESS";
				break;
			case 22:
				code = "E_ACCESS_NNI";
				break;
			case 35:
				code = "MANAGED_SPECTRUM";
				break;
			default:
				break;
		}
		logger.info(`Product Code: ${code}`);
		setProductCode(code);
	}

	//Validates all required fields have been completed and submits renewal quote
	const renewalSubmit = () => {
		setRequiredMsg("");
		logger.info(`Quantity: ${qty}`);
		logger.info(`Circuit Ids selected: ${JSON.stringify(selectedCircuit)}`);
		logger.info(`Manual Circuits Entered: ${JSON.stringify(manualCircuitID)}`);
		logger.info(`Location A DATA: ${JSON.stringify(addressAObj)}`);
		logger.info(`Location Z DATA: ${JSON.stringify(addressZObj)}`);
		logger.info(`General Detail DATA:  ${JSON.stringify(generalDetailData)}`);
		logger.info(`Contact Data: ${JSON.stringify(contactObj)} `);

		let error = requiredFieldValidation();
		if (error) {
			setRequiredMsg("Please verify all required fields are completed");
			return;
		}

		let renewalJSON = {
			"qty": qty,
			"circuit": selectedCircuit,
			"manualCircuit": manualCircuitID,
			"data": generalDetailData,
			"configurationData": configurationData,
			"contactData": contactObj,
			"locA": addressAObj,
			"locZ": addressZObj
		}

		setLoader(true);
		RenewalHelper.submitRenewalQuote(renewalJSON).then(response => {
			logger.info(response.data);
			if (response.data.result === "SUCCESS" && response.data.refId) {
				setLoader(false);
				window.location = `/Quote/View/${response.data.refId}`;
			} else {
				setLoader(false);
				setRenewalErrorMsg(response.data.error);
			}
		}).catch(error => {
			setLoader(false);
			logger.error(`submitRenewalQuote ERROR: ${error}`);
		})
	}

	//Handler to remove or add any additional circuits
	const qtyHandler = (action) => {
		if (action === "add") {
			if (selectedCircuit[0]?.label !== "Manual Circuit") {
				//prevent user from adding additional circuit if the current option is empty
				if (!selectedCircuit[qty - 1]) {
					logger.info(`Must enter an existing circuit id ${qty - 1} before adding another`);
					return;
				}
				if (filterCircuitList(circuitsOnSameGaid).length === 0) {
					setPopupMsg(`No more circuits reside on service location: ${circuitGaid}`);
					return;
				}
			} else {
				//prevent user from adding additional circuit if the current option is empty
				if (!manualCircuitID[qty - 1]) {
					logger.info(`Must enter a manual circuit id ${qty - 1} before adding another`);
					return;
				}
			}
			setQty(qty + 1);
		} else {
			setQty(qty - 1);
			//clearing any additional circuit ids added by the user
			if (selectedCircuit[0].label !== "Manual Circuit") {
				setSelectedCircuit((prevState) => ({ ...prevState, [qty - 1]: { value: "", label: "" } }));
			} else {
				setManualCircuitID((prevState) => ({ ...prevState, [qty - 1]: { value: "", label: "" } }));
			}
		}
	}

	//Helper function to determine dia prem ips
	const getDIAPremIPs = (value) => {
		DIAPremHelper.getPremIPs(value).then(response => {
			logger.info(response.data);
			setPremIpOptions(response.data);
		}).catch(error => {
			logger.error(`Getting DIA Prem IPs: ${error}`);

		})
	}

	//Helper function to determine dia prem bandwidths and the bamKey needed for submission
	const getDiaPremBroadbandBandwidths = () => {
		let broadbandBWError = ""
		if (!generalDetailData?.term1 && !generalDetailData?.term2 && !generalDetailData?.term3 && !generalDetailData?.term4 && !generalDetailData?.term5) {
			logger.info("missing term selection");
			broadbandBWError = "Please select at least one term to determine bandwidths. ";
		}

		if (!addressAObj?.a_add || !addressAObj?.a_city || !addressAObj?.a_state || !addressAObj?.a_zip) {
			logger.info("missing address");
			broadbandBWError += "An address is required to determine bandwidths. ";
			addressRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}

		if (!generalDetailData?.ips) {
			logger.info("missing ip selection");
			broadbandBWError += "IPs are required to determine bandwidths. ";
		}

		if (broadbandBWError) {
			logger.info(broadbandBWError);
			setPopupMsg(broadbandBWError);
			return;
		}

		let terms = {
			"term1": generalDetailData?.term1,
			"term2": generalDetailData?.term2,
			"term3": generalDetailData?.term3,
			"term4": generalDetailData?.term4,
			"term5": generalDetailData?.term5
		};
		setLoader(true);
		DIAPremHelper.getBroadbandBW(generalDetailData?.adsl, generalDetailData?.cable, generalDetailData?.ips, terms, addressAObj).then(response => {
			logger.info(response.data);
			if (response.data.error) {
				logger.info("Address NOT VALID");
				setPopupMsg(response.data.error);
			} else {
				logger.info("Set broadband bw options and bamKey");
				setBandwidthOptions(response.data.bandwidthOptions);
				setGeneralDetailData((prevState) => ({ ...prevState, "bamKey": response.data?.bamKey }));
			}
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error(`getBroadbandBW ERROR: ${error}`);
		})
	}

	//Helper function to pull the rack space, breaker amp, and primary power choices from our database
	const fetchColoMenus = () => {
		setLoader(true);
		ColocationHelper.getColoMenus().then(axios.spread(({data: rackSpace}, {data: breakerAmps}, {data: primaryPower}) => {
			setRackSpaceOptions(rackSpace);
			setBreakerAmpOptions(breakerAmps);
			setPrimaryPowerOptions(primaryPower);
			setLoader(false);
		})).catch(error => {
			logger.info(`getColoMenus Error ${error}`);
			setLoader(false);
		})
	}

	//Function used to filter main cirucit list when user searches
	const filterOptions = (candidate, input) => {
		let sanitizedInput = input.replace(/\W/g, "").toLowerCase();
		if (input) {
			const matches = candidate.value.toLowerCase().includes(sanitizedInput);
			return matches;
		}
		return true;
	}

	//Custom filter to add manual circuit option if no circuits are found for the main circuit selection
	const customFilter = (inputValue) => {
		if (circuitList?.length === 0) {
			logger.info("No circuits we loaded");
			return [{ value: "manual", label: "Manual Circuit" }];;
		}
		const filteredOptions = circuitList.filter((option) => filterOptions(option, inputValue));
		logger.info(`filteredOptions: ${JSON.stringify(filteredOptions)}`);
		if (filteredOptions?.length === 0 && inputValue) {
			logger.info("No circuits found showing manual circuit optino")
			return [{ value: "manual", label: "Manual Circuit" }];;
		} else {
			logger.info("circuits found")
			return filteredOptions;
		}
	}

	//Function used to filter circuit list for additional circuits when user searches
	const customCircuitIdFilter = (option, searchText) => {
		let sanitizedSearchText = searchText.replace(/\W/g, "").toUpperCase();
		return option.value.includes(sanitizedSearchText);
	}

	//Function to only show circuit on the same gaid for additional circuits
	const filterCircuitList = (circuitOptions) => {
		const filteredCircuitOptions = circuitOptions.filter(circuit =>
			!Object.keys(selectedCircuit).some(key => selectedCircuit[key].value === circuit.value && selectedCircuit[key].label === circuit.label && selectedCircuit[key].gaid === circuit.gaid)
		)
		return filteredCircuitOptions;
	}

	return (
		<>
			{loader ? <Loader /> : null}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<div className="wrap">
						<h1 className="page-title">Renewals</h1>
						<CompanyDropdown area={"Renewals"} />
						<div className="section">
							<TabsQuote />
							<div className={`content ${style.contentQueryPage}`}>
								<div className={`row `}>
									<div className="col-12">
										<p className={` ${style.lableTextS} ${style.textBlack} `}>
											<span className={style.mandatory}>*</span> Denotes a required field</p>
									</div>
								</div>
								<div className={`sub-hdr alt ${style.subHeader} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Circuit Selection</h2>
									<p className={` ${style.lableTextS} ${style.textBlack} `}>
										Multiple circuits must reside on the same child account
									</p>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-12 col-sm-4 col-md-3">
										<label className={style.labelSelectRequiredFld}> Existing Circuit ID<span className={style.mandatory}>*</span></label>
										<Select
											onChange={(event) => circuitHandler(event, 0)}
											value={selectedCircuit[0]?.value ? selectedCircuit[0] : null}
											onInputChange={(value) => setInputValue(value)}
											options={customFilter(inputValue)}
											placeholder={'enter circuit information here...'}
											styles={{
												placeholder: (base) => ({
													...base,
													fontSize: '14px',
												})
											}}
											isSearchable
											isClearable
											filterOption={() => true}
											isDisabled={selectedInternalMpid ? false : true}
											noOptionsMessage={() => 'Loading Circuit Options'}
										/>
									</div>
									{selectedCircuit[0]?.label === "Manual Circuit" ?
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.labelSelectRequiredFld}> Manual Circuit ID<span className={style.mandatory}>*</span></label>
											<input type="text" className={`${style.inputFld} mt-3`} id="manualCircuit0" onChange={(event) => manualCircuitHandler({ "value": event.target.value, "label": event.target.value }, 0)} value={manualCircuitID[0]?.value} />
										</div> : null
									}
									{qty === 1 ?
										<div className="col-2 col-sm-2 col-md-2 align-self-center clearfix">
											<div className="add-toggle mt-4" onClick={() => qtyHandler("add")}>
												<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
											</div>
										</div> : null
									}
								</div>
								{qty > 1 ?
									[...Array(qty - 1)].map((x, i) =>
										<>
											<div className={`row ${style.rowGrp}`}>
												{selectedCircuit[0]?.label !== "Manual Circuit" ?
													<div key={i + 2} className="col-12 col-sm-4 col-md-3">
														<label className={style.labelSelectRequiredFld}> Existing Circuit ID {i + 2}<span className={style.mandatory}>*</span></label>
														<Select
															onChange={(event) => circuitHandler(event, i + 1)}
															value={{ value: selectedCircuit[i + 1]?.value, label: selectedCircuit[i + 1]?.label, gaid: selectedCircuit[i + 1]?.gaid }}
															options={filterCircuitList(circuitsOnSameGaid).map((item) => {
																return { value: item.value, label: item.label, gaid: item.gaid }
															})}
															isSearchable
															isClearable
															isDisabled={selectedInternalMpid ? false : true}
															filterOption={customCircuitIdFilter}
															noOptionsMessage={() => 'Loading Circuit Options'}
														/>
													</div>
													:
													<>
														<div className="col-12 col-sm-4 col-md-3">
															<label className={style.labelSelectRequiredFld}> Manual Circuit ID {i + 2}<span className={style.mandatory}>*</span></label>
															<input type="text" className={`${style.inputFld} mt-3`} id={`manualCircuit${i + 1}`} onChange={(event) => manualCircuitHandler({ "value": event.target.value, "label": event.target.value }, i + 1)} value={manualCircuitID[i + 1]?.value} />
														</div>
													</>
												}
												{qty === i + 2 ?
													<>
														<div className={`col-1 col-sm-1 col-md-1 align-self-center clearfix`}>
															<div className={`add-toggle mt-4`}>
																<svg className="ico-plus" onClick={() => qtyHandler("add")}><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
															</div>
														</div>
														<div className={`col-1 col-sm-1 col-md-1 align-self-center clearfix`}>
															<div className={`add-toggle mt-4`}>
																<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={() => qtyHandler("remove")}>
																	<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
										C8.27,265.1,8.27,243.76,8.27,222.43z"/>
																</svg>
															</div>
														</div>
													</> : null
												}
											</div>
										</>

									) : null
								}
								{selectedCircuit[0]?.label || (selectedCircuit[0]?.label === "Manual Circuit" && manualCircuitID[0]?.label) ?
									<div className={`row ${style.rowGrp}`}>
										<div className="col-12 col-sm-4 col-md-3">
											<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={() => validateCircuit()}>
												Validate
												<svg className="ico light-ico ico-arrow">
													<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
												</svg>
											</button>
										</div>
									</div> : null
								}
								{circuitValidated ?
									<>
										<div className={style.dividers}></div>
										<div className={`sub-hdr alt ${style.subHeader} `}>
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg">
														<svg>
															<path
																d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
														</svg>
													</div>
												</div>
											</div>
											<h2 className={style.sectionTitle}>General Details</h2>

										</div>
										<div className={`row ${style.rowGrp} `}>
											<div className="col-12">
												<p>
													Please manually enter required information below if not auto-populated once circuit id is selected.
													<br></br>
													Tool tip to provide what resides in the circuit inventory.
												</p>
											</div>
										</div>
										<div className={`row ${style.rowGrp} `}>
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.labelSelectRequiredFld}>Product<span className={style.mandatory}>*</span></label>
												<img data-tip data-for="productInfo" className="tipped cornertip-right ml-2 mb-1" src="../../../images/svg/ico-info-filled-dark-off.svg" width="16" height="16" alt="" />
												<ReactTooltip id="productInfo" delayHide={100} effect="solid">
													{`Inventory data - ${expectedProduct}`}
												</ReactTooltip>
												<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="product" value={generalDetailData.product} onChange={generalDetailDataHandler}>
													<option value="-">-</option>
													<option value="1">Wave</option>
													<option value="19">E-Line</option>
													<option value="20">E-Access</option>
													<option value="22">E-Access NNI</option>
													<option value="6">MPLS Aggregation Tail</option>
													<option value="13">MPLS + Internet</option>
													<option value="10">DIA: POP</option>
													<option value="11">DIA: PREM</option>
													<option value="15">Colocation</option>
													<option value="35">Managed Spectrum</option>
												</select>
												{generalDetailErrorObj.productError ?
													<div className="invalid-feedback d-block">
														{generalDetailErrorObj.productError}
													</div> : null
												}
											</div>
											{generalDetailData?.product !== "15" ?
												<>
													<div className="col-12 col-sm-4 col-md-4">
														<label className={style.lableTextS}>Circuit Type<span className={style.mandatory}>*</span></label>
														<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="circuit" value={generalDetailData.circuit} onChange={generalDetailDataHandler} disabled={generalDetailData.product && generalDetailData.circuit !== "7" ? "" : "disabled"}>
															{circuitMenuOptions.map((obj) => {
																return <option value={obj.id}>{obj.name}</option>
															})}
														</select>
														{generalDetailErrorObj.circuitError ?
															<div className="invalid-feedback d-block">
																{generalDetailErrorObj.circuitError}
															</div> : null
														}
													</div>
													{generalDetailData?.product?.length > 0 && generalDetailData.circuit === "14" ?
														<div className="col-12 col-sm-4 col-md-4">
															<label className={style.lableTextS}>Renewal Term<span className={style.mandatory}>*</span></label>
															<Term renewalFlag={true} product={productCode} renewalDetailData={generalDetailData} renewalTermHandler={(term, value) => renewalTermHandler(term, value)} />
															{generalDetailErrorObj.termError ?
																<div className="invalid-feedback d-block">
																	{generalDetailErrorObj.initialTermError}
																</div> : null
															}
														</div> : null
													}
													{generalDetailData?.circuit !== "14" ?
														<div className="col-12 col-sm-4 col-md-4">
															<label className={style.lableTextS}>Bandwidth<span className={style.mandatory}>*</span></label>
															<img data-tip data-for="bwInfo" className="tipped cornertip-right ml-2 mb-1" src="../../../images/svg/ico-info-filled-dark-off.svg" width="16" height="16" alt="" />
															<ReactTooltip id="bwInfo" delayHide={100} effect='solid'>
																{`Inventory data - ${expectedBandwidth}`}
															</ReactTooltip>
															<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw" value={generalDetailData.bw} onChange={generalDetailDataHandler} disabled={generalDetailData.product && generalDetailData.circuit ? "" : "disabled"}>
																{bandwidthOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}
															</select>
															{generalDetailErrorObj.bwError ?
																<div className="invalid-feedback d-block">
																	{generalDetailErrorObj.bwError}
																</div> : null
															}
														</div> : null
													}
												</> : null
											}
										</div>
										{/*Only display this row if the product is DIA Pop or DIA Prem */}
										{["10", "11"].includes(generalDetailData?.product) ?
											<>
												<div className={`row ${style.rowGrp} `}>
													{/*Only display for DIA Prem Broadband*/}
													{generalDetailData?.circuit === "14" ?
														<div className="col-12 col-sm-4 col-md-4">
															<label className={style.labelSelectRequiredFld}> Access Type <span className={style.mandatory}>*</span></label>
															<div>
																<input type="checkbox" id="cable" name="cable" value={generalDetailData.cable} onChange={generalDetailDataHandler} />
																<label htmlFor="cable" className={` ${style.checkboxText} `}>CABLE</label>
																<input type="checkbox" id="adsl" name="adsl" value={generalDetailData.adsl} onChange={generalDetailDataHandler} />
																<label htmlFor="adsl" className={` ${style.checkboxText} `}>ADSL</label>
															</div>
														</div> : null
													}
													<div className="col-12 col-sm-4 col-md-4">
														<label className={style.labelSelectRequiredFld}> IP addresses <span className={style.mandatory}>*</span></label>
														<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='ips' value={generalDetailData.ips} onChange={generalDetailDataHandler}>
															{generalDetailData?.product === "10" ?
																<>
																	{diaPopIpOptions?.map((obj) => {
																		return <option value={obj.id}>{obj.name}</option>
																	})}
																</> : null
															}
															{generalDetailData?.product === "11" ?
																<>
																	{premIpOptions?.map((obj) => {
																		return <option value={obj.id}>{obj.name}</option>
																	})}
																</> : null
															}
														</select>
														{generalDetailErrorObj.ipsError ?
															<div className="invalid-feedback d-block">
																{generalDetailErrorObj.ipsError}
															</div> : null
														}
													</div>
													{/*Only display for DIA Prem Broadband*/}
													{generalDetailData?.circuit === "14" ?
														<div className="col-12 col-sm-4 col-md-3">
															<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={() => getDiaPremBroadbandBandwidths()}>
																Get Bandwidths
																<svg className="ico light-ico ico-arrow">
																	<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
																</svg>
															</button>
														</div> : null
													}
												</div>
											</> : null
										}
										<div className={`row ${style.rowGrp} `}>
											{/*Only display for DIA Prem Broadband*/}
											{generalDetailData?.circuit === "14" && Object.entries(bandwidthOptions)?.length > 0 ?
												<div className="col-12 col-sm-4 col-md-4">
													<label className={style.lableTextS}>Bandwidth<span className={style.mandatory}>*</span></label>
													<img data-tip data-for="bwInfo" className="tipped cornertip-right ml-2 mb-1" src="../../../images/svg/ico-info-filled-dark-off.svg" width="16" height="16" alt="" />
													<ReactTooltip id="bwInfo" delayHide={100} effect='solid'>
														{`Inventory data - ${expectedBandwidth}`}
													</ReactTooltip>
													<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw" value={generalDetailData.bw} onChange={generalDetailDataHandler} disabled={generalDetailData.product && generalDetailData.circuit ? "" : "disabled"}>
														{bandwidthOptions.map((obj) => {
															return <option value={obj.id}>{obj.name}</option>
														})}
													</select>
													{generalDetailErrorObj.bwError ?
														<div className="invalid-feedback d-block">
															{generalDetailErrorObj.bwError}
														</div> : null
													}
												</div> : null
											}
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.lableTextS}>Service Location Child Account ID<span className={style.mandatory}>*</span></label>
												<input type="text" className={style.inputFld} id="gaid" value={generalDetailData.gaid} onChange={generalDetailDataHandler}></input>
												{generalDetailErrorObj.gaidError ?
													<div className="invalid-feedback d-block">
														{generalDetailErrorObj.gaidError}
													</div> : null
												}
											</div>
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.labelSelectRequiredFld}>Current MRC<span className={style.mandatory}>*</span></label>
												<input type="text" className={style.inputFld} id="currentMrc" value={generalDetailData.currentMrc} onChange={generalDetailDataHandler}></input>
												{generalDetailErrorObj.currentMrcError ?
													<div className="invalid-feedback d-block">
														{generalDetailErrorObj.currentMrcError}
													</div> : null
												}
											</div>
											{generalDetailData?.product?.length > 0 && generalDetailData.circuit !== "14" ?
												<div className="col-12 col-sm-4 col-md-4">
													<label className={style.lableTextS}>Renewal Term<span className={style.mandatory}>*</span></label>
													<Term renewalFlag={true} product={productCode} renewalDetailData={generalDetailData} renewalTermHandler={(term, value) => renewalTermHandler(term, value)} />
													{generalDetailErrorObj.termError ?
														<div className="invalid-feedback d-block">
															{generalDetailErrorObj.initialTermError}
														</div> : null
													}
												</div> : null
											}
										</div>
										<div className={`row ${style.rowGrp} `}>
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.labelSelectRequiredFld}>Current Term<span className={style.mandatory}>*</span></label>
												<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="termFromSource" value={generalDetailData.termFromSource} onChange={generalDetailDataHandler}>
													<option></option>
													<option value="12">12 months</option>
													<option value="24">24 months</option>
													<option value="36">36 months</option>
													<option value="48">48 months</option>
													<option value="60">60 months</option>
												</select>
												{generalDetailErrorObj.termFromSource ?
													<div className="invalid-feedback d-block">
														{generalDetailErrorObj.termFromSource}
													</div> : null
												}
											</div>
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.lableTextS}>End Term Date<span className={style.mandatory}>*</span></label>
												<div></div>
												<input type="date" className={style.renewalDateFld} id="endTerm" value={generalDetailData.endTerm} onChange={generalDetailDataHandler}></input>
												{generalDetailErrorObj.endTermError ?
													<div className="invalid-feedback d-block">
														{generalDetailErrorObj.endTermError}
													</div> : null
												}
											</div>
											{selectedCircuit[0]?.value === "manual" ?
												<div className="col-12 col-sm-4 col-md-4">
													<label className={style.labelSelectRequiredFld}>Billing System<span className={style.mandatory}>*</span></label>
													<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="billingSystem" value={generalDetailData.billingSystem} onChange={generalDetailDataHandler}>
														<option value=""></option>
														<option value="RC">Revchain</option>
														<option value="CABS">CABS</option>
													</select>
													{generalDetailErrorObj.billingSystemError ?
														<div className="invalid-feedback d-block">
															{generalDetailErrorObj.billingSystemError}
														</div> : null
													}
												</div> : null
											}
										</div>
										{generalDetailData?.product === "15" ?
											<>
												<div className={style.dividers}></div>
												<div className={`sub-hdr alt ${style.subHeader} `}>
													<div className="nib">
														<div className="the-notch">
															<div className="notch-fg">
																<svg>
																	<path
																		d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																</svg>
															</div>
														</div>
													</div>
													<h2 className={style.sectionTitle}>Product Configuration</h2>

												</div>
												<div className={`row ${style.rowGrp} `}>
													<div className="col-12 col-sm-4 col-md-4">
														<label className={style.labelSelectRequiredFld}>Fiber Crosss Connect<span className={style.mandatory}>*</span></label>
														<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="crossConnect" value={configurationData.crossConnect} onChange={configurationHandler}>
															<option></option>
															<option value="0">No</option>
															<option value="1">Cross Connect</option>
														</select>
														{configurationErrorObj.crossConnectError ?
															<div className="invalid-feedback d-block">
																{generalDetailErrorObj.crossConnectError}
															</div> : null
														}
													</div>
													{Number(configurationData?.crossConnect) === 0 ?
														<>
															<div className="col-12 col-sm-4 col-md-4">
																<label className={style.labelSelectRequiredFld}>Rack Space<span className={style.mandatory}>*</span></label>
																<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="rackSpace" value={configurationData.rackSpace} onChange={configurationHandler}>
																	{rackSpaceOptions.map((option) => {
																		return <option value={option.id}>{option.label}</option>
																	})}
																</select>
																{configurationErrorObj.rackSpaceError ?
																	<div className="invalid-feedback d-block">
																		{configurationErrorObj.rackSpaceError}
																	</div> : null
																}
															</div>
															<div className="col-12 col-sm-4 col-md-4">
																<label className={style.labelSelectRequiredFld}>Breaker Amperance<span className={style.mandatory}>*</span></label>
																<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="breakerAmp" value={configurationData?.breakerAmp} onChange={configurationHandler}>
																	{breakerAmpOptions.map((option) => {
																		return <option value={option.id}>{option.label}</option>
																	})}
																</select>
																{configurationErrorObj.breakerAmpError ?
																	<div className="invalid-feedback d-block">
																		{configurationErrorObj.breakerAmpError}
																	</div> : null
																}
															</div>
														</> : null
													}
												</div>
												{Number(configurationData?.crossConnect) === 0 ?
													<>
														<div className={`row ${style.rowGrp} `}>
															<div className="col-12 col-sm-4 col-md-4">
																	<label className={style.labelSelectRequiredFld}>Primary Power<span className={style.mandatory}>*</span></label>
																	<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="primaryPower" value={configurationData.primaryPower} onChange={configurationHandler}>
																		{primaryPowerOptions.map((option) => {
																			return <option value={option.id}>{option.label}</option>
																		})}
																	</select>
																	{configurationErrorObj.primaryPowerError ?
																		<div className="invalid-feedback d-block">
																			{configurationErrorObj.primaryPowerError}
																		</div> : null
																	}
															</div>
															<div className="col-12 col-sm-4 col-md-4">
																<input type="checkbox" id="secondaryPower" name="secondaryPower" value={configurationData.secondaryPower} onChange={configurationHandler} />
																<label htmlFor="secondaryPower" className={` ${style.checkboxText} `}>Secondary Power</label>
															</div>
														</div>
													</> : null
												}

											</> : null
										}

										<div className={style.dividers}></div>

										<div className={`sub-hdr alt ${style.subHeader} `}>
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg">
														<svg>
															<path
																d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
														</svg>
													</div>
												</div>
											</div>
											<h2 className={style.sectionTitle}>Contact</h2>
										</div>

										<div className={`row ${style.rowGrp} `}>
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.labelSelectRequiredFld}>Name<span className={style.mandatory}>*</span></label>
												{newContactFlag ?
													<>
														<input type="text" id="newContact" className={style.inputFld} onChange={newContactHandler} value={contactObj.newContact}></input>
														{contactErrorObj.newContactError ?
															<div className="invalid-feedback d-block">
																{contactErrorObj.newContactError}
															</div> : null
														}
													</>
													:
													<>
														<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="contact" onChange={contactHandler} value={contactObj.contact}>
															{contactOptions.map((obj) => {
																return <option value={obj.id}>{obj.name}</option>
															})}
														</select>
														{contactErrorObj.contactError ?
															<div className="invalid-feedback d-block">
																{contactErrorObj.contactError}
															</div> : null
														}
													</>
												}

											</div>

											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.lableTextS}>Phone<span className={style.mandatory}>*</span></label>
												<input type="text" className={style.inputFld} id="phone" maxLength="10" onChange={contactHandler} value={contactObj.phone}></input>
												{contactErrorObj.phoneError ?
													<div className="invalid-feedback d-block">
														{contactErrorObj.phoneError}
													</div> : null
												}
											</div>
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.lableTextS}>Email<span className={style.mandatory}>*</span></label>
												<input type="text" className={style.inputFld} id="email" onChange={contactHandler} value={contactObj.email}></input>
												{contactErrorObj.emailError ?
													<div className="invalid-feedback d-block">
														{contactErrorObj.emailError}
													</div> : null
												}
											</div>
										</div>

										<div className={style.dividers}></div>

										<div className={`sub-hdr alt ${style.subHeader} `}>
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg">
														<svg>
															<path
																d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
														</svg>
													</div>
												</div>
											</div>
											<h2 className={style.sectionTitle}>Location A</h2>
										</div>

										<div className={`row ${style.rowGrp} `}>
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.lableTextS}>Address <span className={style.mandatory}>*</span></label>
												<input type="text" className={style.inputFld} id="a_add" onChange={(event) => addressInputHandler(event, "A")} value={addressAObj.a_add} />
												{addressErrorObj.a_addError ?
													<div className="invalid-feedback d-block">
														{addressErrorObj.a_addError}
													</div> : null
												}
											</div>
										</div>
										<div className={`row ${style.rowGrp} `}>
											<div className="col-12 col-sm-4 col-md-4">
												<label className={style.lableTextS}>Address Line 2</label>
												<input type="text" className={style.inputFld} id="a_add_2" onChange={(event) => addressInputHandler(event, "A")} value={addressAObj.a_add_2} />
											</div>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className="col-12 col-sm-4 col-md-2">
												<label className={style.lableTextS}>City <span className={style.mandatory}>*</span></label>
												<input type="text" className={style.inputFld} id="a_city" onChange={(event) => addressInputHandler(event, "A")} value={addressAObj.a_city} />
												{addressErrorObj.a_cityError ?
													<div className="invalid-feedback d-block">
														{addressErrorObj.a_cityError}
													</div> : null
												}
											</div>
											<div className="col-12 col-sm-4 col-md-2">
												<label className={style.labelSelectRequiredFld}>State <span className={style.mandatory}>*</span></label>
												<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="a_state" onChange={(event) => addressInputHandler(event, "A")} value={addressAObj.a_state}>
													{stateList.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}
												</select>
												{addressErrorObj.a_stateError ?
													<div className="invalid-feedback d-block">
														{addressErrorObj.a_stateError}
													</div> : null
												}
											</div>

											<div className="col-12 col-sm-4 col-md-2">
												<label className={style.lableTextS}>ZIP <span className={style.mandatory}>*</span></label>
												<input type="text" className={style.inputFld} id="a_zip" onChange={(event) => addressInputHandler(event, "A")} value={addressAObj.a_zip} />
												{addressErrorObj.a_zipError ?
													<div className="invalid-feedback d-block">
														{addressErrorObj.a_zipError}
													</div> : null
												}
											</div>
										</div>
										<div ref={addressRef} />
										{/*Location Z Only needed for Wave, E-Line, E-Access and Managed Spectrum*/}
										{generalDetailData?.product === "1" || generalDetailData?.product === "19" || generalDetailData?.product === "20" || generalDetailData?.product === "35" ?
											<>
												<div className={style.dividers}></div>

												<div className={`sub-hdr alt ${style.subHeader} `}>
													<div className="nib">
														<div className="the-notch">
															<div className="notch-fg">
																<svg>
																	<path
																		d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
																</svg>
															</div>
														</div>
													</div>
													<h2 className={style.sectionTitle}>Location Z</h2>
												</div>

												<div className={`row ${style.rowGrp} `}>
													<div className="col-12 col-sm-4 col-md-4">
														<label className={style.lableTextS}>Address <span className={style.mandatory}>*</span></label>
														<input type="text" className={style.inputFld} id="z_add" onChange={(event) => addressInputHandler(event, "Z")} value={addressZObj.z_add} />
														{addressErrorObj.z_addError ?
															<div className="invalid-feedback d-block">
																{addressErrorObj.z_addError}
															</div> : null
														}
													</div>
												</div>
												<div className={`row ${style.rowGrp} `}>
													<div className="col-12 col-sm-4 col-md-4">
														<label className={style.lableTextS}>Address Line 2</label>
														<input type="text" className={style.inputFld} id="z_add_2" onChange={(event) => addressInputHandler(event, "Z")} value={addressZObj.z_add_2} />
													</div>

												</div>

												<div className={`row ${style.rowGrp}`}>
													<div className="col-12 col-sm-4 col-md-2">
														<label className={style.lableTextS}>City <span className={style.mandatory}>*</span></label>
														<input type="text" className={style.inputFld} id="z_city" onChange={(event) => addressInputHandler(event, "Z")} value={addressZObj.z_city} />
														{addressErrorObj.z_cityError ?
															<div className="invalid-feedback d-block">
																{addressErrorObj.z_cityError}
															</div> : null
														}
													</div>


													<div className="col-12 col-sm-4 col-md-2">
														<label className={style.labelSelectRequiredFld}>State <span className={style.mandatory}>*</span></label>
														<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="z_state" onChange={(event) => addressInputHandler(event, "Z")} value={addressZObj.z_state}>
															{stateList.map((obj) => {
																return <option value={obj.id}>{obj.name}</option>
															})}
														</select>
														{addressErrorObj.z_stateError ?
															<div className="invalid-feedback d-block">
																{addressErrorObj.z_stateError}
															</div> : null
														}
													</div>

													<div className="col-12 col-sm-4 col-md-2">
														<label className={style.lableTextS}>ZIP <span className={style.mandatory}>*</span></label>
														<input type="text" className={style.inputFld} id="z_zip" onChange={(event) => addressInputHandler(event, "Z")} value={addressZObj.z_zip} />
														{addressErrorObj.z_zipError ?
															<div className="invalid-feedback d-block">
																{addressErrorObj.z_zipError}
															</div> : null
														}
													</div>
												</div>
											</> : null
										}
										<div className={style.dividers}></div>
										<div className={`sub-hdr alt ${style.subHeader} `}>
											<div className="nib">
												<div className="the-notch">
													<div className="notch-fg">
														<svg>
															<path
																d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
														</svg>
													</div>
												</div>
											</div>
											<h2 className={style.sectionTitle}>Comments</h2>
										</div>
										<div className={`row ${style.rowGrp}`}>
											<div className="col-12">
												<textarea id='comments' className={style.renewalTextArea} value={generalDetailData.comments} onChange={generalDetailDataHandler} />
											</div>
										</div>
									</> : null
								}
								{circuitValidated ?
									<>
										<div className={style.dividers}></div>
										<div className={`row ${style.rowGrp}`}>
											<div className="col-12 mt-3">
												{requiredMsg ?
													<p className="text-danger text-center mb-2">{requiredMsg}</p> : null
												}
												{renewalErrorMsg ?
													<p className="text-danger text-center mb-2">{renewalErrorMsg}</p> : null
												}
												<div className="text-center">
													<button className={` ico-button light-button  ${style.nextBtn} `} type="button" onClick={() => clearRenewalForm()}>
														Clear
														<svg className="ico-x">
															<path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
														</svg>
													</button>
													{circuitValidated ?
														<button className={` ico-button lhs light-button ${style.nextBtn} ml-3 `} type="button" onClick={() => renewalSubmit()}>
															Submit
															<svg className="ico light-ico ico-arrow">
																<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
															</svg>
														</button> : null
													}

												</div>
											</div>
										</div>
									</> : null
								}

							</div>
						</div>
					</div>
				</div>
			</div >
			{popupMsg ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md`}>
							<div className="modal-content">
								<div className="modal-body text-center">
									{popupMsg}
								</div>
								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => setPopupMsg("")}>Ok</button>
								</div>
							</div>
						</div>
					</div>
				</div> : null
			}
		</>

	)
}

export default Renewals;
