import { useEffect } from 'react';
import '../../utils/CustomBotpress';

const Chatbot = () => {
	useEffect(() => {
		const employeeName = localStorage.getItem("employeeName").replace(/\+/g, ' ');
      	window.botpressWebChat.init({
        	botId: '36205362-3409-48d9-9038-822f8d183f63',
        	hostUrl: 'https://cdn.botpress.cloud/webchat/v1',
        	messagingUrl: 'https://messaging.botpress.cloud',
        	clientId: '36205362-3409-48d9-9038-822f8d183f63',
        	botName: 'iConnect Digital Assistant',
			stylesheet: 'https://webchat-styler-css.botpress.app/prod/code/bf021ac1-4aca-41ef-9a8e-e492a058e9bc/v21354/style.css',
			userData: {
				name: employeeName
			}
      	})
  }, [])

  return <div id="webchat" />;
}

export default Chatbot
