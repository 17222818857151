import axios from "./axios/customAxios";

const getAllCircuitDemoIds = async () => {
    return await axios.get(window.location.origin + '/assets/json/akamaiCircuitListDemo.json');
}

const getAllCircuitIds = async () => {
	return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getAllCircuitIds.php`);
}

const getIconNetworkJson = async () => {
	return await axios.get(window.location.origin + '/assets/json/iconNetwork.geojson');
}

const getLongHaulNetworkJson = async () => {
    return await axios.get(window.location.origin + '/assets/json/longhaulNetwork.geojson');
}

const getRegionalNetworkJson = async () => {
    return await axios.get(window.location.origin + '/assets/json/regionalNetwork.geojson');
}

const getLeafletDemoJson = async () => {
    return await axios.get(window.location.origin + '/assets/json/akamaiLeafletDemo.json');
}

const getLeafletJsonData = async (commsId, designIds) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getLeafletJson.php?comms_id=${commsId}&designIds=${JSON.stringify(designIds)}`);
}

const getAdditionalCircuitData = async (designIds) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getAdditionalCircuitInfo.php?designId=${JSON.stringify(designIds)}`);
}

const getFullCircuitPath = async (designId) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getFullCircuitPath.php?designId=${designId}`);
}


const NetworkViewerHelper = {
    getAllCircuitDemoIds,
    getAllCircuitIds,
    getIconNetworkJson,
    getLongHaulNetworkJson,
    getRegionalNetworkJson,
    getLeafletDemoJson,
    getLeafletJsonData,
    getAdditionalCircuitData,
    getFullCircuitPath
}


export default NetworkViewerHelper;
