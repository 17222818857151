import {Logger} from "./Logger";
import {LoggerFunction} from "./LoggerFunction";
import {LogLevel} from "./LogLevel";

const DISABLED: LoggerFunction = (message?: any, ...optionalParams: any[]) => {};
const eid = localStorage.getItem("eid");

export class ConsoleLogger implements Logger {
    readonly info: LoggerFunction;
    readonly warn: LoggerFunction;
    readonly error: LoggerFunction;

    constructor(options?: {level?: LogLevel}) {

        const level = options?.level;
        this.error = console.error.bind(console);
        this.warn = console.warn.bind(console);
        this.info = console.log.bind(console);

        //Carlos, Michelle, and Robbie should always be able to see the logger.info in all environments
        if(eid === "e0187647" || eid === "e0155924" || eid === "e0173131") {
            return;
        }

        switch (level) {
            case "OFF":
                this.error = DISABLED;
                this.warn = DISABLED;
                this.info = DISABLED;
                break;
            case "WARN":
                this.info = DISABLED;
                break;
            case "ERROR":
                this.warn = DISABLED;
                this.info = DISABLED;
                break;
        }
    }
}
