import style from "./QuoteCreate.module.css";
import { useEffect, useMemo, useState } from "react";
import HomeData from '../../services/HomeServices';
import Loader from "../shared/Loader";
import { connect, useSelector } from "react-redux";
import {
	setLocZAddress,
	setLocZCity,
	setLocZFloorNum,
	setLocZState,
	setLocZStruct,
	setLocZStructNum,
	setLocZUnit,
	setLocZUnitNum,
	setLocZValidationFlag,
	setLocZZip,
	setLocZOverride,
	setLocZLatitude,
	setLocZLongitude
} from '../../redux/actions/quoteLocZActions';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CustomPopRules from "./CustomPopRules";
import { setQuoteAddressZ } from "../../redux/actions/quoteActions";

const QuoteLocZ = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const [hasError, setHasError] = useState(null);
	const [avloader, setAvLoader] = useState(false) // AV loader
	const [stateList, setStateList] = useState([]); // build states list
	const [addErrorMessage, setAddErrorMessage] = useState("");
	const [stateErrorMessage, setStateErrorMessage] = useState("");
	const [cityErrorMessage, setCityErrorMessage] = useState("");
	const [zipErrorMessage, setZipErrorMessage] = useState("");
	const [latErrorMessage, setLatErrorMessage] = useState("");
	const [longErrorMessage, setLongErrorMessage] = useState("");
	const [structNumErrorMessage, setStructNumErrorMessage] = useState("");
	const [floorNumErrorMessage, setFloorNumErrorMessage] = useState("");
	const [unitNumErrorMessage, setUnitNumErrorMessage] = useState("");
	const [ld2Val, setld2Val] = useState("");
	const [addressLoaded, setAddressLoaded] = useState(false);
	const [validAddress, setValidAddress] = useState("");
	const [validationErrorMessage, setValidationError] = useState("");
	const [ilecErrorFlag, setILECErrorFlag] = useState(false);
	const [wavePopCheckFlag, setWavePopCheckFlag] = useState(false);
	const [nobid, setNobid] = useState(false);
	const [waveMultiPopList, setWaveMultiPopList] = useState([]);
	const selectedQuoteValues = useSelector(state => state.quote.quoteValues);
	const selectedLocZValues = useSelector(state => state.quoteLocZ.locZ);

	const google = window.google = window.google ? window.google : {};

	const fillInAddress = (ac) => {
		var place = ac.getPlace();
		props.updateLocZAddress(place.formatted_address);
		for (var x = 0; x < place.address_components.length; x++) {
			switch (place.address_components[x].types[0]) {
				case 'locality':
					props.updateLocZCity(place.address_components[x].short_name);
					break;
				case 'administrative_area_level_1':
					props.updateLocZState(place.address_components[x].short_name);
					break;
				case 'postal_code':
					props.updateLocZZip(place.address_components[x].short_name);
					break;
				default:
					break;
			}
		}
		logger.info(place);
		return place;
	}

	const initAutocomplete = () => {
		const autocomplete = new google.maps.places.Autocomplete((document.getElementById('z_add')), { types: ['geocode'] });
		google.maps.event.addListener(autocomplete, 'place_changed', function () {
			fillInAddress(autocomplete, "");
		});
	}

	const addressHandler = (event) => {
		switch (event.target.id) {
			case 'z_add':
				setAddErrorMessage("");
				props.updateLocZAddress(event.target.value);
				break;
			case 'z_ld1':
				props.updateLocZStruct(event.target.value);
				break;
			case 'z_lv1':
				setStructNumErrorMessage("");
				props.updateLocZStructNum(event.target.value);
				break;
			case 'z_ld2':
				setld2Val(event.target.value);
				break;
			case 'z_lv2':
				setFloorNumErrorMessage("");
				props.updateLocZFloorNum(event.target.value);
				break;
			case 'z_ld3':
				props.updateLocZUnit(event.target.value);
				break;
			case 'z_lv3':
				setUnitNumErrorMessage("");
				props.updateLocZUnitNum(event.target.value);
				break;
			case 'z_city':
				setCityErrorMessage("");
				props.updateLocZCity(event.target.value)
				break;
			case 'z_state':
				setStateErrorMessage("");
				props.updateLocZState(event.target.value);
				break;
			case 'z_zip':
				setZipErrorMessage("");
				props.updateLocZZip(event.target.value);
				break;
			case 'z_lat':
				props.updateLocZLatitude(event.target.value);
				setLatErrorMessage("");
				break;
			case 'z_long':
				props.updateLocZLongitude(event.target.value);
				setLongErrorMessage("")
				break;
			default:
				break;
		}
	}


	useEffect(() => {
		initAutocomplete();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(() => {
		HomeData.getStateList().then(resp => {
			setStateList(resp.data);
		}).catch((error) => {
			logger.error("getStates ERROR: " + error)
			setHasError(error);
		})
	}, [logger]);

	// This is called when clicking to validate Address
	const validateAddress = () => {
		setAvLoader(true);
		setAddressLoaded(false);

		if (!selectedLocZValues.z_add) {
			setAddErrorMessage('Address is required');
		}
		if (selectedLocZValues.z_structNum && selectedLocZValues.z_structNum.length > 10) {
			setStructNumErrorMessage('Only 10 chars allowed')
		}
		if (selectedLocZValues.z_floorNum && selectedLocZValues.z_floorNum.length > 10) {
			setFloorNumErrorMessage('Only 10 chars allowed')
		}
		if (selectedLocZValues.z_unitNum && selectedLocZValues.z_unitNum.length > 10) {
			setUnitNumErrorMessage('Only 10 chars allowed')
		}
		if (!selectedLocZValues.z_city) {
			setCityErrorMessage('City is required');
		}
		if (!selectedLocZValues.z_state) {
			setStateErrorMessage('State is required');
		}
		if (!selectedLocZValues.z_zip) {
			setZipErrorMessage('Zip is required')
		}
		if (selectedLocZValues.z_override) {
			if (!selectedLocZValues.z_lat) {
				setLatErrorMessage("Latitude is required");
			}
			if (!selectedLocZValues.z_long) {
				setLongErrorMessage("Longitude is required");
			}
		}

		let addressData = {
			'address': selectedLocZValues.z_add,
			'ld1': selectedLocZValues.z_struct,
			'lv1': selectedLocZValues.z_structNum,
			'ld2': ld2Val,
			'lv2': selectedLocZValues.z_floorNum,
			'ld3': selectedLocZValues.z_unit,
			'lv4': selectedLocZValues.z_unitNum,
			'city': selectedLocZValues.z_city,
			'state': selectedLocZValues.z_state,
			'zip': selectedLocZValues.z_zip,
			'override': selectedLocZValues.z_override,
			'lat': selectedLocZValues.z_lat,
			'lon': selectedLocZValues.z_long
		};
		//build something to send to AV
		HomeData.validateAddressResult(addressData, selectedQuoteValues.product).then(resp => {

			logger.info('Response: ' + resp.data);
			setAddressLoaded(true);
			setAvLoader(false);
			if (resp.data.av_result) {
				if (resp.data?.nobid) {
					setValidAddress(false);
					props.updateLocZValidAddress(false);
					setValidationError(resp.data.av_message);
					setNobid(true);

				} else {
					setValidAddress(true);
					props.updateLocZValidAddress(true);
				}
				//ADO-663156 Wave Pop Query for existing pop locations
				if (selectedQuoteValues.product === 1 && resp.data?.pop_return_list?.length > 0) {
					if (resp.data.pop_return_list[0].autoselect === true) {
						props.updateWavePopAddressZ({ value: resp.data.pop_return_list[0].id, label: resp.data.pop_return_list[0].address });
					} else {
						setWavePopCheckFlag(true);
						setWaveMultiPopList(resp.data.pop_return_list);
					}
				}
			} else {
				if (resp.data.ilec) {
					setValidAddress(false);
					setILECErrorFlag(true);
					setValidationError(resp.data.av_message);
					props.updateLocZValidAddress(false);
				} else {
					setILECErrorFlag(false);
					setValidAddress(false);
					props.updateLocZValidAddress(false);
				}
			}
		}).catch(error => {
			logger.error('validateAddressResult Error: ' + error);
			setHasError(error);
		})
	};


	return (

		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{avloader ? <Loader /> : null}
			{!hasError && (<>
				{selectedQuoteValues.product === 19 || selectedQuoteValues.product === 1 ?

					null :
					<>
						<div className={style.dividers}></div>
						<div className={` sub-hdr alt1 ${style.subHeader} `}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
								</div>
							</div>
							<h2 className={style.sectionTitle}>Location Z</h2>
						</div>
					</>
				}
				{isEmployee || (validAddress === false && !nobid) ?
				<div className={`row ${style.rowGrp} `}>

					<div className="col-12 col-sm-4 col-md-4">
						<input type="checkbox" id="override" name="override" onChange={() => props.updateLocZOverride(!selectedLocZValues.z_override)} checked={selectedLocZValues.z_override ? "checked" : ""} />
						<label htmlFor="override" className={` ${style.checkboxText} `}>New Construction / Override</label>
					</div>
				</div>
				: null}

				{selectedLocZValues.z_override ?
					<div className={`row ${style.rowGrp} `}>

						<div className="col-12 col-sm-4 col-md-2">
							<label className={style.lableTextS}>Latitude <span className={style.mandatory}>*</span></label>
							<input type="text" className={style.inputFld} id='z_lat' onChange={addressHandler} value={selectedLocZValues.z_lat} />
							{latErrorMessage ?
								<div class="invalid-feedback d-block">
									{latErrorMessage}
								</div> : null}
						</div>
						<div className="col-12 col-sm-4 col-md-2">
							<label className={style.lableTextS}>Longitude <span className={style.mandatory}>*</span></label>
							<input type="text" className={style.inputFld} id='z_long' onChange={addressHandler} value={selectedLocZValues.z_long} />
							{longErrorMessage ?
								<div class="invalid-feedback d-block">
									{longErrorMessage}
								</div> : null}
						</div>
					</div> : null}
				<div className={`row ${style.rowGrp} `}>
					<div className="col-12 col-sm-4 col-md-4">
						<label className={style.lableTextS}>Address <span className={style.mandatory}>*</span></label>
						<input type="text" className={style.inputFld} id="z_add" onChange={addressHandler} value={selectedLocZValues.z_add} />

						{addErrorMessage ?
							<div class="invalid-feedback d-block">
								{addErrorMessage}
							</div> : null}
					</div>
				</div>


				<div className={`row ${style.rowGrp} ${style.alignFlexEnd}`}>
					<div className="col-6 col-sm-4 col-md-2">
						<label className={style.labelSelectRequiredFld}>Structure</label>
						<select className={` form-control  ${style.selectFld} `} id="z_ld1" onChange={addressHandler} value={selectedLocZValues.z_struct}>
							<option value="" disabled>-</option>
							<option value="BLDG">BLDG</option>
							<option value="WNG">WNG</option>
							<option value="PIER">PIER</option>

						</select>
					</div>
					<div className="col-6 col-sm-4 col-md-1">
						<input type="text" className={style.inputFld} id="z_lv1" onChange={addressHandler} value={selectedLocZValues.z_structNum} />
						{structNumErrorMessage ?
							<div class="invalid-feedback d-block">
								{structNumErrorMessage}
							</div> : null}
					</div>

					<div className="col-6 col-sm-4 col-md-2">
						<label className={style.labelSelectRequiredFld}>Floor</label>
						<select defaultValue={'FL'} className={` form-control  ${style.selectFld} `} >
							<option value="FL">FL</option>
						</select>
					</div>

					<div className="col-6 col-sm-4 col-md-1">
						<input type="text" className={style.inputFld} id='z_lv2' onChange={addressHandler} value={selectedLocZValues.z_floorNum} />
						{floorNumErrorMessage ?
							<div class="invalid-feedback d-block">
								{floorNumErrorMessage}
							</div> : null}
					</div>

					<div className="col-12 col-sm-4 col-md-2">
						<label className={style.labelSelectRequiredFld}>Unit</label>
						<select className={` form-control  ${style.selectFld} `} id='z_ld3' onChange={addressHandler} value={selectedLocZValues.z_unit}>
							<option value="" disabled>-</option>
							<option value="APT">APT</option>
							<option value="RM">RM</option>
							<option value="LOT">LOT</option>
							<option value="SLIP">SLIP</option>
							<option value="UNIT">UNIT</option>
							<option value="SUIT">SUITE</option>
						</select>
					</div>

					<div className="col-6 col-sm-4 col-md-1">
						<input type="text" className={style.inputFld} id='z_lv3' onChange={addressHandler} value={selectedLocZValues.z_unitNum} />
						{unitNumErrorMessage ?
							<div class="invalid-feedback d-block">
								{unitNumErrorMessage}
							</div> : null}
					</div>

				</div>

				<div className={`row ${style.rowGrp}`}>
					<div className="col-12 col-sm-4 col-md-2">
						<label className={style.lableTextS}>City <span className={style.mandatory}>*</span></label>
						<input type="text" className={style.inputFld} id='z_city' onChange={addressHandler} value={selectedLocZValues.z_city} />
						{cityErrorMessage ?
							<div class="invalid-feedback d-block">
								{cityErrorMessage}
							</div> : null}
					</div>


					<div className="col-12 col-sm-4 col-md-2">
						<label className={style.labelSelectRequiredFld}>State <span className={style.mandatory}>*</span></label>
						<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='z_state' onChange={addressHandler} value={selectedLocZValues.z_state}>
							{stateList.map((obj) => {
								return <option value={obj.id}>{obj.name}</option>
							})}
						</select>
						{stateErrorMessage ?
							<div class="invalid-feedback d-block">
								{stateErrorMessage}
							</div> : null}


					</div>

					<div className="col-12 col-sm-4 col-md-2">
						<label className={style.lableTextS}>ZIP <span className={style.mandatory}>*</span></label>
						<input type="text" className={style.inputFld} id='z_zip' onChange={addressHandler} value={selectedLocZValues.z_zip} />
						{zipErrorMessage ?
							<div class="invalid-feedback d-block">
								{zipErrorMessage}
							</div> : null}
					</div>


					<div className="col-6 col-sm-4 col-md-1">
						<button type="button" className="btn-style btn-theme" onClick={validateAddress}>Validate</button>
						{addressLoaded ?
							<>
								{validAddress ?
									<div class="valid-feedback d-block">
										Validated
									</div> :
									<>
										{ilecErrorFlag ?
											<div class="invalid-feedback d-block">
												<a href="https://customers.windstreamwholesale.com/">{validationErrorMessage}</a>
											</div> :
											<>
												{!nobid ?
													<div class="invalid-feedback d-block">
														Invalid
													</div> : null
												}
											</>
										}
									</>
								}
							</> : null}
						{nobid ?
							<div class="invalid-feedback d-block">
								{validationErrorMessage}
							</div> : null
						}
					</div>
					{wavePopCheckFlag ?
						<CustomPopRules pop_list={waveMultiPopList} type={"locZ"} /> : null
					}

				</div></>)}
		</>);
}

const mapDispatchToProps = {
	updateLocZAddress: setLocZAddress,
	updateLocZStruct: setLocZStruct,
	updateLocZStructNum: setLocZStructNum,
	updateLocZFloorNum: setLocZFloorNum,
	updateLocZUnit: setLocZUnit,
	updateLocZUnitNum: setLocZUnitNum,
	updateLocZCity: setLocZCity,
	updateLocZState: setLocZState,
	updateLocZZip: setLocZZip,
	updateLocZOverride: setLocZOverride,
	updateLocZLatitude: setLocZLatitude,
	updateLocZLongitude: setLocZLongitude,
	updateLocZValidAddress: setLocZValidationFlag,
	updateWavePopAddressZ: setQuoteAddressZ
}

export default connect(null, mapDispatchToProps)(QuoteLocZ);
