import React, {useEffect, useState} from 'react';
import Navs from '../circuit/Includes/Navs';
import FullCircuitPath from './FullCircuitpath';
import style from './Summary.module.css';
import SummaryContent from './SummaryContent';
import SummaryTickets from './SummaryTickets';
import SummaryMaintenance from './SummaryMaintenance';
import SummaryData from '../../services/CircuitSummaryService';
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import Loader from "../shared/Loader";
import {useParams} from 'react-router-dom';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import Fiberpath from './Fiberpath';
import CompanyDropdown from '../shared/CompanyDropdown';
import CoherentProbes from './CoherentProbes';
import CoherentProbeHelper from '../../services/CoherentProbeService';
import { useSelector } from "react-redux";


const Summary = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = React.useState(null);
	const [navLink, setNavLink] = useState('Summary');
	const [CircuitData, setCircuitDetails] = useState([]);
	const [hideLightLevelLanesFlag, setHideLightLevelLanesFlag] = useState(false);
	const [loadFlag, setLoadedFlag] = useState(false);
	const [loader, setLoader] = useState(false);
	const [locA, setLocA] = useState([]);
	const [locZ, setLocZ] = useState([]);

	const [locA_prev_t_data, setLocA_prev_t_Data] = useState([])
	const [locA_prev_r_data, setLocA_prev_r_Data] = useState([])
	const [locA_t_data, setLocA_t_Data] = useState([]);
	const [locA_r_data, setLocA_r_Data] = useState([]);

	const [locZ_prev_t_data, setLocZ_prev_t_Data] = useState([])
	const [locZ_prev_r_data, setLocZ_prev_r_Data] = useState([])
	const [locZ_t_data, setLocZ_t_Data] = useState([]);
	const [locZ_r_data, setLocZ_r_Data] = useState([]);
	const { circuitID, designID } = useParams();
	const [niceviewFlag, setNiceViewFlag] = useState(false);
	const [defaultView, setDefaultViewFlag] = useState(false);

	const [kmzToken, setKmzToken] = useState("");
	const [kmzLink, setKmzLink] = useState("");
	const [kmzLoader, setKmzLoader] = useState(true);
	const [fetchKmzLinkFlag, setFetchKmzFlag] = useState(false);

	const [coherentProbeLoader, setCoherentProbeLoader] = useState(true);
	const [coherentProbeData, setCoherentProbeData] = useState([]);

	const localURL = "iconnect-local.windstreamwholesale.com";
	const devURL = "iconnect-dev.windstreamwholesale.com";
	const uatURL = "iconnect-uat.windstreamwholesale.com";

	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const user = localStorage.getItem("user");


	useEffect(() => {
		getCircuitData(circuitID);
	}, [])

	//Go get the kmz link in the background once you have circuit data
	useEffect(() => {
		// ADO-947161 Fetch the fiber path link if loadFlag is true, designID is non-zero, and circuitID contains specific substrings or the product is WAVE.
		if(loadFlag && Number(designID) !== 0 && (/WGXA|LXFS|KJUS|KGUS|KRUS|KMUS/.test(circuitID) || /WAVE/.test(CircuitData?.product.toUpperCase()))){
			logger.info("Fetching kmz link");
			fetchKmzLink(designID);
		}
	},[loadFlag])

	//Fetch the kmz token once you have a kmz link
	useEffect(() => {
		if (fetchKmzLinkFlag){
			fetchKmzToken(kmzLink,designID);
		}
	}, [fetchKmzLinkFlag])

	useEffect(() => {
		if (circuitID.includes("WG")) {
			if(user === "Wholesale_Demo" || Number(selectedInternalMpid) === 8683){
				setCoherentProbeLoader(true);
				CoherentProbeHelper.getCoherentProbeDemo().then(response => {
					logger.info("COHERENT PROBE DEMO: " + JSON.stringify(response.data));
					setCoherentProbeData(response.data);
					setCoherentProbeLoader(false);
				}).catch(error => {
					logger.info(`COHERENT PROBE DEMO ERROR: ${error}`);
					setCoherentProbeLoader(false);
				})
			} else {
				setCoherentProbeLoader(true);
				CoherentProbeHelper.getCoherentProbeData(circuitID).then(response => {
					logger.info(`COHERENT PROBE: ${JSON.stringify(response.data)}`);
					setCoherentProbeData(response?.data);
					setCoherentProbeLoader(false);
				}).catch(error => {
					logger.info(`COHERENT PROBE ERROR: ${error}`);
					setCoherentProbeLoader(false);
				})
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [circuitID])

	const handleNavClick = (e) => {
		setNavLink(e);
	}


	const getCircuitData = (circuitID) => {
		setLoader(true);
		SummaryData.getCircuitDetails(circuitID).then(resp => {
			logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
			setLoader(false);
			if (resp.data.length !== 0) {
				setCircuitDetails(removeLineBreaks(resp.data[0]));
				setLoader(true);
				if(circuitID.includes("WG")){
					logger.info("Circuit is a WG circuit AKA MANAGED SPECTURM CIRCUIT so it doesn't have light levels");
					setNiceViewFlag(false);
					setDefaultViewFlag(true);
					setLoadedFlag(true);
					setLoader(false);
				} else {
					SummaryData.getLightLevelDetails(resp.data[0].cid).then(resp => {
						logger.info("LIGHT LEVEL RESPONSE: " + JSON.stringify(resp.data));
						setLocA(resp.data.A);
						setLocZ(resp.data.Z);
						if (resp.data.light_data === 0) {
							logger.info("NO LIGHT LEVEL DATA HIDE LIGHT LEVEL DISPLAY");
							setNiceViewFlag(false);
							setDefaultViewFlag(true);
							setLoadedFlag(true);
							setLoader(false);
						} else {
							logger.info("Light Level Data Available");
							setDefaultViewFlag(true);
							setNiceViewFlag(true);
							setLocA_prev_t_Data(resp.data?.A?.prev_ll_transmit);
							setLocA_prev_r_Data(resp.data?.A?.prev_ll_receive);
							setLocA_t_Data(resp.data?.A?.ll_transmit);
							setLocA_r_Data(resp.data?.A?.ll_receive);
							setLocZ_prev_t_Data(resp.data?.Z?.prev_ll_transmit);
							setLocZ_prev_r_Data(resp.data?.Z?.prev_ll_receive);
							setLocZ_t_Data(resp.data?.Z?.ll_transmit);
							setLocZ_r_Data(resp.data?.Z?.ll_receive);
							setLoadedFlag(true);
							setLoader(false);
						}
					}).catch((error) => {
						logger.error("getLightLevelDetails ERROR: " + error)
						setHasError(error);
						setLoader(false);
					})
				}
			} else {
				setCircuitDetails(0);
				setLoader(false);
			}
		}).catch((error) => {
			logger.error("getCircuitDetails ERROR: " + error)
			setHasError(error);
			setLoader(false);
		});
	}

	const fetchKmzLink = (designID) => {
		setFetchKmzFlag(false);
		SummaryData.getKmzLink(designID).then(resp => {
			logger.info("KMZ RESPONSE: " + encodeURIComponent(JSON.stringify(resp.data)).replace(/%20/g, " "));
			if (resp.data.status === "SUCCESS") {
				logger.info("KMZ LINK: " + encodeURIComponent(resp.data.url).replace(/%20/g, " "));
				setKmzLink(resp.data.url);
			}
			setFetchKmzFlag(true);
		}).catch(error => {
			logger.error("Error Getting KMZ LINK: "+error);
			setHasError(error);
			setFetchKmzFlag(true);
		})
	}

	const fetchKmzToken = (link, designID) => {
		setKmzLoader(true);
		SummaryData.getKmzToken(link, designID).then(resp => {
			logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
			//Setting a dummy token for all non production environments so the fiber path loads
			if(window.location.hostname === localURL || window.location.hostname === devURL || window.location.hostname === uatURL){
				setKmzToken("123456");
			}
			if(resp.data.result === "SUCCESS"){
				setKmzToken(resp.data.token);
			}
			setKmzLoader(false);
		}).catch(error => {
			logger.error("Error getting KMZ token: "+error);
			setHasError(error);
			setKmzLoader(false);
		})
	}


	const removeLineBreaks = (data) => {
		for (let key in data) {
			if (data[key] !== null) {
				data[key] = data[key].replace(/<br>s*/g, ' ');
				//ADO-671409 Adding flag to hide light level lanes for all 10 GB Wave Circuits
				if (key === "bw") {
					logger.info("Checking the bandwidth of the circuit");
					data[key] = data[key].replace(/[^\d.]/g, '');
					let bandwidth = parseInt(data[key]);
					logger.info("The bandwidth of the circuit is: " + bandwidth);
					if (bandwidth === 10) {
						logger.info("Hiding the light level lanes");
						setHideLightLevelLanesFlag(true);
					}
				}
			}
		}
		return data;
	}





	const loadComponent = () => {
		switch (navLink) {
			case 'Summary':
				return <SummaryContent circuitDetails={CircuitData} locADetails={locA} locZDetails={locZ} />;
			case 'Layer Zero':
				return <CoherentProbes circuitDetails={CircuitData?.cid} coherentProbeData={coherentProbeData} />;
			case 'Tickets':
				return <SummaryTickets circuit_id={CircuitData.cid} />
			case 'SM':
				return <SummaryMaintenance circuit_id={CircuitData.cid} />
			case 'FCP':
				return <FullCircuitPath designId={designID} />;
			case 'FP':
				return <Fiberpath kmzToken={kmzToken}/>
			default:
				return;
		}
	}

	return (

		<>
			{loader ? <Loader /> : null}
			{hasError && (
				<HTTPErrorHandler error={hasError}></HTTPErrorHandler>
			)}
			{!hasError && (CircuitData ?
				<>
					{defaultView ?

						<div className={`midsection ${style.circuitSummary} abs z1`}>
							<div className="rel">
								<div className="wrap">
									<h1 className="page-title mb-0">{isEmployee ? "Employee Circuit Information" : "Circuit Information"}</h1>
									{isEmployee ? <CompanyDropdown area={"Circuit Information"}/> : null}
									<div className="crumb">
										<a href="/Network/CircuitHealth">Circuit Health</a>
										<span></span>
										<a href="/Network/Summary">Circuit Inventory</a>
									</div>

									<h1 className="page-title alt-hdr">{CircuitData.cid}</h1>


									<div className="section">
										{niceviewFlag ?
											<div className={hideLightLevelLanesFlag ? `${style.hidelightLevels} light-levels` : "light-levels"}>
												<div className="w96 cf centered">
													<div className="ll-loc colFL w40 c f22">
														{CircuitData.aloc}
													</div>
													<div className="ll-gap colFL w20 c">
													</div>
													<div className="ll-loc colFL w40 c f22">
														{CircuitData.zloc}
													</div>
												</div>
												<div className="w96 cf centered">
													<div className="ll-time colFL w20 c">
														LAST <span className={style.responsiveText}> READING</span>
													</div>
													<div className="ll-time colFL w20 c">
														CURRENT <span className={style.responsiveText}>READING</span>
													</div>
													<div className='ll-gap colFL w20 c'>
														<div className={style.res100}>
															<div className={style.leftPos}>
																<svg className={style.leftBr}><path d="M0,97v2H2a3.84,3.84,0,0,0,4-4V53l4-4L6,45V4C6,.11,2,0,2,0H0V2H1S4,2,4,6V46l3,3L4,52V94a2.93,2.93,0,0,1-3,3Z"></path></svg>
																{locA?.clli ?
																	<>
																		<span className={style.labelLL}>CLLI</span>
																		<span className={style.labelLV}>{locA?.clli}</span>
																	</>
																	: null}
																{locA?.cfa ?
																	<>
																		<span className={style.labelLL}>CFA</span>
																		<span className={style.labelLV}>{locA?.cfa1}</span>
																	</>
																	: null}
																{locA?.cfa2 ?
																	<>
																		<span className={style.labelLL}>CFA</span>
																		<span className={style.labelLV}>{locA?.cfa2}</span>
																	</> : null}
																{locA?.vendor ?
																	<>
																		<span className={style.labelLL}>Vendor</span>
																		<span className={style.labelLV}>{locA?.vendor}</span>
																	</> : null}
															</div>
															<div className={style.rightPos}>
																<svg className={style.rightBr}><path d="M10,97v2H8a3.84,3.84,0,0,1-4-4V53L0,49l4-4V4C4,.11,8,0,8,0h2V2H9S6,2,6,6V46L3,49l3,3V94a2.93,2.93,0,0,0,3,3Z"></path></svg>
																{locZ?.clli ?
																	<>
																		<span className={style.labelLL}>CLLI</span>
																		<span className={style.labelLV}>{locZ?.clli}</span>
																	</> : null}
																{locZ?.cfa1 ?
																	<>
																		<span className={style.labelLL}>CFA</span>
																		<span className={style.labelLV}>{locZ?.cfa1}</span>
																	</> : null}
																{locZ?.cfa2 ?
																	<>
																		<span className={style.labelLL}>CFA</span>
																		<span className={style.labelLV}>{locZ?.cfa2}</span>
																	</> : null}
																{locZ?.vendor ?
																	<>
																		<span className={style.labelLL}>Vendor</span>
																		<span className={style.labelLV}>{locZ?.vendor}</span>
																	</> : null}
															</div>
														</div>
													</div>
													<div className="ll-time colFL w20 c">
														LAST <span className={style.responsiveText}>READING</span>
													</div>
													<div className="ll-time colFL w20 c">
														CURRENT <span className={style.responsiveText}>READING</span>
													</div>
												</div>
												<div className={hideLightLevelLanesFlag ? `${style.hidellCore} ll-core rel w96 centered` : "ll-core rel w96 centered"}>
													<div className="w100 abs z1">
														<div className="w100 cf">
															<div className={hideLightLevelLanesFlag ? `${style.hidellPanel} ll-panel colFL w20 c` : "ll-panel colFL w20 c"}>
																<div className="ll-timestamp">
																	<span className="f14">DATE &amp; TIME</span>
																	<div><strong className="f18">{locA?.prev_ll_date}</strong></div>
																</div>

																<div className="ll-totals tx-totals w100 cf">
																	<div className="ll-totals-dbm fr mr5 f36 r ok">
																		<span>{locA_prev_t_data?.LaneTotalTx?.toFixed(2)} dBm</span>
																	</div>
																	<div className="fr w45 mr5 r">
																		<span className="f22">TRANSMIT</span>
																		<div className="f14">TOTAL POWER</div>
																	</div>
																</div>

																<div className="ll-totals rx-totals w100 cf">
																	<div className="ll-totals-dbm fr mr5 f36 r ok">
																		<span> {locA_prev_r_data?.LaneTotalRx?.toFixed(2)} dBm</span>
																	</div>
																	<div className="fr w45 mr5 r">
																		<span className="f22">RECEIVE</span>
																		<div className="f14">TOTAL POWER</div>
																	</div>
																</div>
															</div>
															<div className={hideLightLevelLanesFlag ? `${style.hidellPanel} ll-panel colFL w20 c alt` : "ll-panel colFL w20 c alt"}>
																<div className="ll-timestamp">
																	<span className="f14">DATE &amp; TIME</span>
																	<div><strong className="f18">{locA?.ll_date}</strong></div>
																</div>

																<div className="ll-totals tx-totals w100 cf">
																	<div className="ll-totals-dbm fr mr5 f36 r ok">
																		<span>{locA_t_data?.LaneTotalTx?.toFixed(2)} dBm</span>
																	</div>
																	<div className="fr w45 mr5 r">
																		<span className="f22">TRANSMIT</span>
																		<div className="f14">TOTAL POWER</div>
																	</div>
																</div>

																<div className="ll-totals rx-totals w100 cf">
																	<div className="ll-totals-dbm fr mr5 f36 r ok">
																		<span>{locA_r_data?.LaneTotalRx?.toFixed(2)} dBm</span>
																	</div>
																	<div className="fr w45 mr5 r">
																		<span className="f22">RECEIVE</span>
																		<div className="f14">TOTAL POWER</div>
																	</div>
																</div>

															</div>
															<div className="ll-gap colFL w20 c">
															</div>
															<div className={hideLightLevelLanesFlag ? `${style.hidellPanel} ll-panel colFL w20 c` : "ll-panel colFL w20 c"}>

																<div className="ll-timestamp">
																	<span className="f14">DATE &amp; TIME</span>
																	<div><strong className="f18">{locZ?.prev_ll_date}</strong></div>
																</div>

																<div className="ll-totals tx-totals w100 cf">
																	<div className="ll-totals-dbm fr mr5 f36 r ok">
																		<span> {locZ_prev_t_data?.LaneTotalTx?.toFixed(2)} dBm</span>
																	</div>
																	<div className="fr w45 mr5 r">
																		<span className="f22">TRANSMIT</span>
																		<div className="f14">TOTAL POWER</div>
																	</div>
																</div>
																<div className="ll-totals rx-totals w100 cf">
																	<div className="ll-totals-dbm fr mr5 f36 r ok">
																		<span>{locZ_prev_r_data?.LaneTotalRx?.toFixed(2)} dBm</span>
																	</div>
																	<div className="fr w45 mr5 r">
																		<span className="f22">RECEIVE</span>
																		<div className="f14">TOTAL POWER</div>
																	</div>
																</div>

															</div>
															<div className={hideLightLevelLanesFlag ? `${style.hidellPanel} ll-panel colFL w20 c alt` : "ll-panel colFL w20 c alt"}>

																<div className="ll-timestamp">
																	<span className="f14">DATE &amp; TIME</span>
																	<div><strong className="f18">{locZ?.ll_date}</strong></div>
																</div>

																<div className="ll-totals tx-totals w100 cf">
																	<div className="ll-totals-dbm fr mr5 f36 r ok">
																		<span>{locZ_t_data?.LaneTotalTx?.toFixed(2)} dBm</span>
																	</div>
																	<div className="fr w45 mr5 r">
																		<span className="f22">TRANSMIT</span>
																		<div className="f14">TOTAL POWER</div>
																	</div>
																</div>
																<div className="ll-totals rx-totals w100 cf">
																	<div className="ll-totals-dbm fr mr5 f36 r ok">
																		<span>{locZ_r_data?.LaneTotalRx?.toFixed(2)} dBm</span>
																	</div>
																	<div className="fr w45 mr5 r">
																		<span className="f22">RECEIVE</span>
																		<div className="f14">TOTAL POWER</div>
																	</div>
																</div>

															</div>
														</div>

													</div>
													{!hideLightLevelLanesFlag ?
														<>
															<div className="ll-bracket-tx abs z2">
																<span>Tx</span>
																<svg><path d="M10,97v2H8a3.84,3.84,0,0,1-4-4V53L0,49l4-4V4C4,.11,8,0,8,0h2V2H9S6,2,6,6V46L3,49l3,3V94a2.93,2.93,0,0,0,3,3Z"></path></svg>
															</div>
															<div className="ll-bracket-rx abs z2">
																<span>Rx</span>
																<svg><path d="M0,97v2H2a3.84,3.84,0,0,0,4-4V53l4-4L6,45V4C6,.11,2,0,2,0H0V2H1S4,2,4,6V46l3,3L4,52V94a2.93,2.93,0,0,1-3,3Z"></path></svg>
															</div>
															<div className="ll-lanes w100 abs z2">
																<div className="ll-tx">
																	<div className="ll-lane">
																		<div className="ll-le-l"></div>
																		<div className="ll-le-r"></div>
																		<div className="ll-lane-highlight"></div>
																		<div className="ll-lane-reading w100 cf">
																			<div className="colFL w20">
																				{locA_prev_t_data?.Lane1Tx ?
																					<span>{locA_prev_t_data.Lane1Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locA_t_data?.Lane1Tx  ?
																					<span>{locA_t_data.Lane1Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="ll-gap colFL w20 c">
																				<span>Lane 1</span>
																			</div>
																			<div className="colFL w20">
																				{locZ_prev_t_data?.Lane1Tx ?
																					<span>{locZ_prev_t_data.Lane1Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locZ_t_data?.Lane1Tx ?
																					<span>{locZ_t_data.Lane1Tx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																		</div>
																	</div>
																	<div className="ll-lane">
																		<div className="ll-le-l"></div>
																		<div className="ll-le-r"></div>
																		<div className="ll-lane-highlight"></div>
																		<div className="ll-lane-reading w100 cf">
																			<div className="colFL w20">
																				{locA_prev_t_data?.Lane2Tx ?
																					<span>{locA_prev_t_data.Lane2Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locA_t_data?.Lane2Tx ?
																					<span>{locA_t_data.Lane2Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="ll-gap colFL w20 c">
																				<span>Lane 2</span>
																			</div>
																			<div className="colFL w20">
																				{locZ_prev_t_data?.Lane2Tx ?
																					<span>{locZ_prev_t_data.Lane2Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locZ_t_data?.Lane2Tx ?
																					<span>{locZ_t_data.Lane2Tx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																		</div>

																	</div>
																	<div className="ll-lane">
																		<div className="ll-le-l"></div>
																		<div className="ll-le-r"></div>
																		<div className="ll-lane-highlight"></div>
																		<div className="ll-lane-reading w100 cf">
																			<div className="colFL w20">
																				{locA_prev_t_data?.Lane3Tx ?
																					<span>{locA_prev_t_data.Lane3Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locA_t_data?.Lane3Tx ?
																					<span>{locA_t_data.Lane3Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="ll-gap colFL w20 c">
																				<span>Lane 3</span>
																			</div>
																			<div className="colFL w20">
																				{locZ_prev_t_data?.Lane3Tx ?
																					<span>{locZ_prev_t_data.Lane3Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locZ_t_data?.Lane3Tx ?
																					<span>{locZ_t_data.Lane3Tx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																		</div>

																	</div>
																	<div className="ll-lane">
																		<div className="ll-le-l"></div>
																		<div className="ll-le-r"></div>
																		<div className="ll-lane-highlight"></div>
																		<div className="ll-lane-reading w100 cf">
																			<div className="colFL w20">
																				{locA_prev_t_data?.Lane4Tx ?
																					<span>{locA_prev_t_data.Lane4Tx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locA_t_data?.Lane4Tx ?
																					<span>{locA_t_data.Lane4Tx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="ll-gap colFL w20 c">
																				<span>Lane 4</span>
																			</div>
																			<div className="colFL w20">
																				{locZ_prev_t_data?.Lane4Tx ?
																					<span>{locZ_prev_t_data.Lane4Tx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locZ_t_data?.Lane4Tx ?
																					<span>{locZ_t_data.Lane4Tx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																		</div>

																	</div>

																</div>
																<div className="ll-rx">
																	<div className="ll-lane">
																		<div className="ll-le-l"></div>
																		<div className="ll-le-r"></div>
																		<div className="ll-lane-highlight"></div>
																		<div className="ll-lane-reading w100 cf">
																			<div className="colFL w20">
																				{locA_prev_r_data?.Lane1Rx ?
																					<span>{locA_prev_r_data.Lane1Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locA_r_data?.Lane1Rx ?
																					<span>{locA_r_data.Lane1Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="ll-gap colFL w20 c">
																				<span>Lane 1</span>
																			</div>
																			<div className="colFL w20">
																				{locZ_prev_r_data?.Lane1Rx ?
																					<span>{locZ_prev_r_data.Lane1Rx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locZ_r_data?.Lane1Rx ?
																					<span>{locZ_r_data.Lane1Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																		</div>
																	</div>
																	<div className="ll-lane">
																		<div className="ll-le-l"></div>
																		<div className="ll-le-r"></div>
																		<div className="ll-lane-highlight"></div>
																		<div className="ll-lane-reading w100 cf">
																			<div className="colFL w20">
																				{locA_prev_r_data?.Lane2Rx ?
																					<span>{locA_prev_r_data.Lane2Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locA_r_data?.Lane2Rx  ?
																					<span>{locA_r_data.Lane2Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="ll-gap colFL w20 c">
																				<span>Lane 2</span>
																			</div>
																			<div className="colFL w20">
																				{locZ_prev_r_data?.Lane2Rx ?
																					<span>{locZ_prev_r_data.Lane2Rx} dBm</span> : <span>unavailable</span>
																				}

																			</div>
																			<div className="colFL w20">
																				{locZ_r_data?.Lane2Rx ?
																					<span>{locZ_r_data.Lane2Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																		</div>

																	</div>
																	<div className="ll-lane">
																		<div className="ll-le-l"></div>
																		<div className="ll-le-r"></div>
																		<div className="ll-lane-highlight"></div>
																		<div className="ll-lane-reading w100 cf">
																			<div className="colFL w20">
																				{locA_prev_r_data?.Lane3Rx ?
																					<span>{locA_prev_r_data.Lane3Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locA_r_data?.Lane3Rx ?
																					<span>{locA_r_data.Lane3Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="ll-gap colFL w20 c">
																				<span>Lane 3</span>
																			</div>
																			<div className="colFL w20">
																				{locZ_prev_r_data?.Lane3Rx ?
																					<span>{locZ_prev_r_data.Lane3Rx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locZ_r_data?.Lane3Rx ?
																					<span>{locZ_r_data.Lane3Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																		</div>

																	</div>
																	<div className="ll-lane">
																		<div className="ll-le-l"></div>
																		<div className="ll-le-r"></div>
																		<div className="ll-lane-highlight"></div>
																		<div className="ll-lane-reading w100 cf">
																			<div className="colFL w20">
																				{locA_prev_r_data?.Lane4Rx ?
																					<span>{locA_prev_r_data.Lane4Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="colFL w20">
																				{locA_r_data?.Lane4Rx ?
																					<span>{locA_r_data.Lane4Rx} dBm</span> : <span> unavailable</span>
																				}
																			</div>
																			<div className="ll-gap colFL w20 c">
																				<span>Lane 4</span>
																			</div>
																			<div className="colFL w20">
																				{locZ_prev_r_data?.Lane4Rx ?
																					<span>{locZ_prev_r_data.Lane4Rx} dBm</span> : <span>unavailable</span>
																				}

																			</div>
																			<div className="colFL w20">
																				{locZ_r_data?.Lane4Rx ?
																					<span>{locZ_r_data.Lane4Rx} dBm</span> : <span>unavailable</span>
																				}
																			</div>
																		</div>

																	</div>

																</div>

															</div></> : null}

												</div>

											</div>

											: null}

										<div className="tab-nav cf">
											<Navs handleNav={handleNavClick} kmzLoader={kmzLoader} circuit={circuitID} designId={designID} product={CircuitData?.product} coherentProbeLoader={coherentProbeLoader} coherentProbeData={coherentProbeData} />
										</div>
										{loadFlag ?
											<div className={` content ${style.contentCS}`}>
												{loadComponent()}
											</div> : null}
									</div>
								</div>
							</div>
						</div> :
						<div className="midsection qm-upload abs z1">
							<div className="wrap">
								<h1 className="page-title mb-0 text-center">Circuit Information</h1>
								<div className={`text-center ${style.noDataWrap} `}>
									<h1 className="m-0">No Data Available</h1>
								</div>
							</div>
						</div>}
				</>
				: <div className={`text-center ${style.noDataWrap} `}>
					<div>
						<h1 className="m-0">No Data Available</h1>
					</div>
				</div>)}
		</>

	)
}

export default Summary;
