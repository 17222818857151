import React, { useState, useEffect, useMemo } from 'react';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import HomeData from '../../services/HomeServices';
import { alertWindowHome } from '../../redux';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MySlider.css';

export const AlertWindow = () => {
	// Logger setup
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL }), []);
	const today = new Date().toLocaleString('en-us', { month: 'long', year: 'numeric' });
	const [messages, setMessages] = useState([]);
	const dispatch = useDispatch();

	// Local Storage Variables
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn') === 'true';
	const isCustomer = localStorage.getItem('isLoggedIn') === 'true';

	const dismissAction = () => {
		dispatch(alertWindowHome(false));
	};

	const objectToArray = (obj) => {
		const uniqueMessages = [];
		const messageMap = new Map();

		Object.keys(obj).forEach(key => {
			const message = { id: key, ...obj[key] };
			if (!messageMap.has(message.message)) {
				messageMap.set(message.message, true);
				uniqueMessages.push(message);
			}
		});

		return uniqueMessages;
	};

	useEffect(() => {
		HomeData.getNotificationDisplay().then((response) => {
			logger.info(`Getting getNotificationDisplay for Home Page`);
			logger.info(`Response data: ${JSON.stringify(response.data)}`);
			setMessages(objectToArray(response.data));
		}).catch((error) => {
			logger.error(`getNotificationDisplay ERROR: ${error}`);
		});
	}, [logger, isEmployee, isCustomer]);

	const sliderSettings = {
		dots: true,
		infinite: false,
		speed: 500, // The speed of the transition between slides remains at 0.5 seconds
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 10000, // Now each slide will stay on screen for 10 seconds
		arrows: true,
		vertical: false, // Ensures horizontal scroll
	};

	return (
		<div className="callout">
			<div className="callout-msg">
				<span>{today}</span>
				<h2>Welcome to iconnect</h2>
				{messages.length > 0 ? (
					<Slider {...sliderSettings} className="my-slider">
						{messages.map((message) => (
							<div key={message.id}>
								{message.type === 'Alert' ? (
									<div className="text-danger" style={{ fontSize: '16px' }}>
										<strong>
											ALERT: {message.title}
											<br />
											{message.message}
										</strong>
									</div>
								) : (
									<p>
										<div style={{ fontSize: '16px' }}>
											<strong>
												{message.title ? message.title : ''}
												<br />
												<div dangerouslySetInnerHTML={{ __html: message.message }}></div>
											</strong>
										</div>
									</p>
								)}
							</div>
						))}
					</Slider>
				) : (
					<p>
						<div style={{ fontSize: '16px' }}>
							<strong>
								Your new Windstream Wholesale customer portal has arrived!
								<br />
								<br />
								iconnect offers multi-layer network data and self-service tools, providing unprecedented visibility into both wavelength and fiber performance. Reveal more and manage confidently with network intelligence as an extension of your network!
							</strong>
						</div>
					</p>
				)}
			</div>
			<div className="callout-rhs">
				<div className="callout-actions">
					<div className="dismiss" onClick={dismissAction}>
						<svg className="ico-link ico-add ico-close">
							<path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z" />
						</svg>
					</div>
					<div className="cbg">
						<svg viewBox="0 0 127.8 336.48">
							<path d="M205.88,114A54.18,54.18,0,0,1,153.4,97.78c-17.91-19.68-20-51.47.91-74H103.2V360.24H231V100.38A55.12,55.12,0,0,1,205.88,114Z" transform="translate(-103.2 -23.76)" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};